import './ds-pdf-viewer.scss';
import { autoinject, bindable } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';

@autoinject()
export class DsPdfViewer {
    @bindable pdfContent;
    @bindable dialog;
    sizeChanged;
    width;

    constructor(private eventAggregator: EventAggregator) { }

    activate(model) {
        this.pdfContent = model.pdfContent;
    }

    attached() {
        const windowWidth = window.innerWidth;
        const pdfViewer = document.getElementById('pdf-viewer');

        if (windowWidth < 579) {
            pdfViewer.setAttribute('height', '465');
        } else {
            pdfViewer.setAttribute('height', '600');
        }
        this.handleEventSubscriptions();
    }

    close() {
        this.handleClosing();
        this.dialog.close();
    }

    handleClosing() {
        this.pdfContent = null;
    }

    open() {
        this.dialog.open = !this.dialog.open;
    }

    handleEventSubscriptions() {
        this.sizeChanged = this.eventAggregator.subscribe('size-changed', async payload => {
            this.width = payload.width;
            const pdfViewer = document.getElementById('pdf-viewer');
            if (this.width < 579) {
                pdfViewer.setAttribute('height', '465');
            } else {
                pdfViewer.setAttribute('height', '600');
            }
        });
    }
}
