import { autoinject } from 'aurelia-framework';
import { ApiService } from './api-service';

@autoinject()
export class InsuranceService {
    constructor(private api: ApiService) { }

    insurances;

    async getAllInsurances() {
        if (this.insurances) return this.insurances;
        this.insurances = await this.api.doGet('Insurance');
        return this.insurances;
    }

    async getById(id) {
        return await this.api.doGet('Insurance/' + id);
    }
}
