import { EventAggregator } from 'aurelia-event-aggregator';
import './about-us.scss';
import { autoinject } from 'aurelia-framework';
import { PageContentAreaService } from 'services/page-content-area-service';
import { TestimonialService } from 'services/testimonial-service';
import { WebsiteService } from 'services/website-service';
import { Helper } from 'resources/extensions/helper';

@autoinject()
export class AboutUs {
    testimonials;
    count = 0;
    tracker = 0;
    prev;
    next;
    query;
    transferNumber;
    numberCards;

    constructor(
        private pageContentAreaService: PageContentAreaService,
        private testimonialService: TestimonialService,
        private websiteService: WebsiteService,
        private helper: Helper,
        private eventAggregator: EventAggregator
    ) { }

    async activate() {
        const pages = await this.websiteService.getPagesByWebsiteShortcode();
        await this.pageContentAreaService.getByPageId(pages.find(x => x.name === 'About Us')?.id);
        this.testimonials = await this.testimonialService.getFeaturedTestimonials();
    }

    attached() {
        this.prev = document.getElementById('prev');
        this.next = document.getElementById('next');
        this.numberCards = this.testimonials.length;
        this.helper.resizeByNavbar(this, '#about-us');
        this.query = window.matchMedia('(max-width: 576px)');
        this.query.onchange = () => {
            if (this.query.matches) {
                this.numberCards = this.testimonials.length - 1;
                this.transferNumber = 294;
            } else {
                this.numberCards = this.testimonials.length - 3;
                this.transferNumber = 425;
            }
        };
        this.query.onchange();
    }

    detached() {
        this.helper.disposeAllSubscribers(this);
    }

    traslateCard() {
        const cards = document.querySelectorAll<HTMLElement>('.glider');
        cards.forEach(element => element.style.transform = `translateX(${this.count}px)`);
    }

    moveCardsLeft() {
        this.count = this.count + this.transferNumber;
        this.tracker--;
        if (this.tracker === 0) {
            this.prev.setAttribute('disabled', '');
        } else {
            this.prev.removeAttribute('disabled');
        }
        if (this.tracker === 5) {
            this.next.setAttribute('disabled', '');
        } else {
            this.next.removeAttribute('disabled');
        }
        this.traslateCard();
    }

    moveCardsRight() {
        this.count = this.count - this.transferNumber;
        this.tracker++;
        if (this.tracker <= 0) {
            this.prev.setAttribute('disabled', '');
        } else {
            this.prev.removeAttribute('disabled');
        }
        if (this.tracker === this.numberCards) {
            this.next.setAttribute('disabled', '');
        } else {
            this.next.removeAttribute('disabled');
        }
        this.traslateCard();
    }
}
