import { baseUrl } from 'environment';

export class SeoStep {
    run(navigationInstruction, next) {
        const canonicalLink = document.querySelector('link[rel=canonical]');
        const ogUrl = document.querySelector('meta[property="og:url"]');
        const ogType = document.querySelector('meta[property="og:type"]');
        const baseUrlWithoutSlash = baseUrl().slice(0, -1);
        canonicalLink.setAttribute('href', `${baseUrlWithoutSlash}${navigationInstruction.fragment}`);
        ogUrl.setAttribute('content', `${baseUrlWithoutSlash}${navigationInstruction.fragment}`);
        ogType.setAttribute('content', `${navigationInstruction.config.name === 'blog-post' ? 'article' : 'website'}`);
        return next();
    }
}

