import './ds-status-tag.scss';
import { bindable } from 'aurelia-framework';

export class DsStatusTag {
    @bindable status: string;
    @bindable tagStyling: string;
    @bindable isVerificationTag: string;
    @bindable tagIcon: string;
    @bindable tagIconStyling: string;
    @bindable tagIconOutlined: string;
    @bindable isOrderTag = false;
}
