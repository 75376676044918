import { autoinject } from 'aurelia-framework';
import { Helper } from './helper';
import { postcodeValidator, postcodeValidatorExistsForCountry } from 'postcode-validator';

@autoinject()
export default class PaymentFunctionality {
    constructor(public helper: Helper) {
        this.postcodeValidator = postcodeValidator;
        this.postcodeValidatorExistsForCountry = postcodeValidatorExistsForCountry;
    }

    postcodeValidator;
    postcodeValidatorExistsForCountry;
    paymentProcessing;
    summaryButtonState;
    billing;

    checkBilling() {
        return this.validateError(this.validBilling(), 'Please enter a valid billing address to proceed with your payment.');
    }

    validateError(condition, msg) {
        return this.helper.validateCondition(condition, msg, () => this.setError());
    }

    setError() {
        this.paymentProcessing = false;
        this.summaryButtonState = 'valid';
    }

    validBilling() {
        const isValid = (!this.postcodeValidatorExistsForCountry(this.billing.country)
            || this.postcodeValidator(this.billing.zip, this.billing.country) === true)
            && this.helper.hasAllProperties(this.billing, ['city', 'country', 'street'], true);

        const type = isValid ? 'Green' : 'Error';
        const opposite = { 'Green': 'Error', 'Error': 'Green' };

        this[`showBilling${type}CheckMark`] = true;
        const selectors = document.querySelectorAll('.google-selector');
        selectors.forEach((el) => {
            const dropdown = el.querySelector('span.mdc-select__dropdown-icon') as HTMLElement;
            dropdown.style.display = 'none';
        });

        this[`showBilling${opposite[type]}CheckMark`] = false;
        return isValid;
    }
}
