import './ds-pseudo-table.scss';
import { autoinject, bindable } from 'aurelia-framework';

@autoinject()
export class DsPseudoTable {
    @bindable ticket;
    @bindable order;
    @bindable index;
    parent;

    bind(bindingContext, overrideContext) {
        this.parent = overrideContext.parentOverrideContext.bindingContext;
    }

    switchToDetails(index) {
        this.parent.switchToDetails(index);
    }
}
