import { autoinject } from 'aurelia-framework';
import { ApiService } from './api-service';
import { Helper } from 'resources/extensions/helper';

@autoinject()
export class ProductCategoryService {
    path = 'ProductCategory';
    navItems;
    productCategories;
    productCategoriesByWebsite;

    constructor(private api: ApiService, private helper: Helper) {}

    async getByWebsite() {
        if (!this.productCategoriesByWebsite) {
            this.productCategoriesByWebsite = await this.api.doGet(this.path + '/GetByWebsite?websiteShortCode=DS');
        }
        return this.productCategoriesByWebsite;
    }

    async getAll() {
        if (this.productCategories) return this.productCategories;
        this.productCategories = await this.api.doGet(this.path);
        return this.productCategories;
    }

    async getAllForNav(websiteShortCode) {
        return this.helper.handlePendingRequest(this, async() => {
            if (!this.navItems) {
                this.navItems = await this.api.doGet(this.path + `/ForNav?websiteShortCode=${websiteShortCode}`);
            }
            this.buildExtraSell();
            return this.navItems.filter(c => c.name !== 'sellextra' && c.name !== 'WOWTLKPW Power Leveling');
        });
    }


    async getNavCategory(category, websiteShortCode) {
        if (category) {
            if (!this.navItems) {
                this.navItems = await this.api.doGet(this.path + `/ForNav?websiteShortCode=${websiteShortCode}`);
            }
            this.buildExtraSell();
            return this.navItems.find(x => x.name?.toLowerCase() === category?.toLowerCase());
        } else {
            throw Error('Category given was null');
        }
    }

    async getIdByName(name) {
        return await this.api.doGet(this.path + '/GetIdByName/' + name);
    }

    buildExtraSell() {
        if (this.navItems && !this.navItems.some(x => x.name === 'sellextra')) {
            const sellList = {
                id: 250,
                name: 'sellextra',
                gameForNav: [
                    {
                        id: 251,
                        name: 'Currency',
                        shortName: 'currency',
                        slug: ['currency'],
                        productCategoryName: 'currency'
                    },
                    {
                        id: 252,
                        name: 'Items',
                        shortName: 'items',
                        slug: ['items'],
                        productCategoryName: 'items'
                    },
                    {
                        id: 253,
                        name: 'Accounts',
                        shortName: 'accounts',
                        slug: ['accounts'],
                        productCategoryName: 'accounts'
                    }
                ]
            };
            const auxNavItems = JSON.parse(JSON.stringify(this.navItems));
            const currencySellGames = auxNavItems.find(x => x.name === 'Sell Currency')?.gameForNav
                .map(e => {
                    e.slug = e.slug.map(s => `currency/${s}`);
                    e.productCategoryName = 'CURRENCY';
                    return e;
                }) || [];
            const itemSellGames = auxNavItems.find(x => x.name === 'Sell Item')?.gameForNav
                .map(e => {
                    e.slug = e.slug.map(s => `items/${s}`);
                    e.productCategoryName = 'ITEMS';
                    return e;
                }) || [];
            const accountSellGames = auxNavItems.find(x => x.name === 'Sell Account')?.gameForNav
                .map(e => {
                    e.slug = e.slug.map(s => `accounts/${s}`);
                    e.productCategoryName = 'ACCOUNTS';
                    return e;
                }) || [];
            sellList.gameForNav = [...currencySellGames, ...itemSellGames, ...accountSellGames];
            this.navItems.push(sellList);
        }
    }
}
