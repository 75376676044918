import { autoinject } from 'aurelia-framework';
import { ApiService } from './api-service';
import { DateOnlyValueConverter } from 'resources/value-converters/date-only';

@autoinject()
export class SubscriptionService {
    constructor(
        private api: ApiService,
        private dateOnlyValueConverter: DateOnlyValueConverter
    ) {}

    getActiveSubscription = (user, checkWithCancel) => user?.subscriptions?.find(x => {
        const isAfterRenewalDate = this.dateOnlyValueConverter.toView(x.renewalDate) > this.dateOnlyValueConverter.toView(new Date());
        const isSubscribed = x.isSubscribed;

        return checkWithCancel
            ? isAfterRenewalDate && isSubscribed
            : isAfterRenewalDate || isSubscribed;
    });

    hasSubscription = (user, checkWithCancel) => Boolean(this.getActiveSubscription(user, checkWithCancel));
}
