import './sitemap.scss';
import { autoinject } from 'aurelia-dependency-injection';
import { ProductCategoryService } from 'services/product-category-service';
import { WebsiteService } from 'services/website-service';
import { apiEndpoint } from 'environment';

@autoinject()
export class Sitemap {
    constructor(private productCategoryService: ProductCategoryService, private websiteService: WebsiteService) { }

    productCategories;
    pageRoutes;
    environment = apiEndpoint();
    pages = [];
    search = '';

    async activate() {
        this.productCategories = await this.productCategoryService.getAllForNav('DS');
        this.pageRoutes = await this.websiteService.getPagesByWebsiteShortcode();

        for (const category of this.productCategories) {
            const categoryPages = {
                name: category.name,
                url: this.environment + 'Image/chicks-icons/bag.svg',
                pages: []
            };
            if (!['Sell', 'Swap', 'Skins', 'Unique Name'].some(x => category.name.includes(x))) {
                for (const game of category.gameForNav) {
                    const page = {
                        name: game.name,
                        slug: (this.pageRoutes.find(x => x.name.toLowerCase() === category.name.toLowerCase())?.routeName ?? category.name.toLowerCase()) + '/' + game.slug[0]
                    };
                    if (category.name.includes('Services') && game.parentGameId) {
                        categoryPages.pages.push(page);
                    }
                    if (!category.name.includes('Services')) {
                        categoryPages.pages.push(page);
                    }
                }
                this.pages.push(categoryPages);
            }
        }

        const divicaSalesPages = {
            name: 'Divica Sales',
            url: this.environment + 'Image/chicks-icons/divica.svg',
            pages: [
                { name: 'About Us', slug: this.pageRoutes.find(x => x.name.toLowerCase() === 'about us')?.routeName ?? '/about-us' },
                { name: 'Blog', slug: this.pageRoutes.find(x => x.name.toLowerCase() === 'blog')?.routeName ?? '/blog' },
                { name: 'Bug Bounty', slug: this.pageRoutes.find(x => x.name.toLowerCase() === 'bug-bounty')?.routeName ?? 'bug-bounty' }
            ]
        };
        this.pages.push(divicaSalesPages);

        const supportPages = {
            name: 'Support',
            url: this.environment + 'Image/chicks-icons/support.svg',
            pages: [
                { name: 'Contact us', slug: this.pageRoutes.find(x => x.name.toLowerCase() === 'contact us')?.routeName ?? 'contact' },
                { name: 'FAQ', slug: this.pageRoutes.find(x => x.name.toLowerCase() === 'faq')?.routeName ?? '/faq' },
            ]
        };
        this.pages.push(supportPages);

        const legalPages = {
            name: 'Legal',
            url: this.environment + 'Image/chicks-icons/legal.svg',
            pages: [
                { name: 'Privacy Policy', slug: this.pageRoutes.find(x => x.name.toLowerCase() === 'privacy policy')?.routeName ?? 'privacy-policy' },
                { name: 'Terms of Service', slug: this.pageRoutes.find(x => x.name.toLowerCase() === 'terms of service')?.routeName ?? 'terms-of-service' },
                { name: 'Copyright Policy', slug: this.pageRoutes.find(x => x.name.toLowerCase() === 'copyright policy')?.routeName ?? 'copyright-policy' }
            ]
        };
        this.pages.push(legalPages);
    }

    handleSearchChange(event) {
        if (!event?.target?.value) return;
        this.search = event.target.value;
        for (const category of this.pages) {
            for (const game of category.pages) {
                game.show = game.name.toLowerCase().includes(this.search.toLocaleLowerCase());
            }
        }
    }

    clearSearch() {
        this.search = null;
    }
}
