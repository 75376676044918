import { autoinject } from 'aurelia-framework';
import { ApiService } from './api-service';
import { SupportTicket } from './models/support-ticket/support-ticket';

@autoinject()
export class SupportTicketService {
    path = 'SupportTicket';

    constructor(private api: ApiService) {}

    async createTicket(supportTicket: SupportTicket) {
        return this.api.doPost(this.path, supportTicket);
    }

    async getTicketsByCustomerId(customerId: number): Promise<SupportTicket[]> {
        return this.api.doGet(`${this.path}/GetByCustomerId/${customerId}`);
    }
}
