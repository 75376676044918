import './ds-information-card.scss';
import { autoinject, bindable } from 'aurelia-framework';

@autoinject()
export class DsInformationCard {
    @bindable title;
    @bindable icon;
    @bindable text;
    @bindable key;
    @bindable alt;
}
