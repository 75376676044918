import './ds-breadcrumbs.scss';
import { autoinject, bindable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { WebsiteService } from 'services/website-service';

@autoinject()
export class DsBreadcrumbs {
    @bindable breadcrumbs = [];
    @bindable class;
    homePageRoute;

    constructor(
        private websiteService: WebsiteService,
        private router: Router
    ) { }

    async attached() {
        const pages = await this.websiteService.getPagesByWebsiteShortcode();
        this.homePageRoute = pages.find(x => x.name === 'Home')?.routeName ?? '';
        setTimeout(() => this.handleBreadCrumbStyling(), 1000);
    }

    handleBreadCrumbStyling() {
        const anchorElements = Array.from(document.getElementsByClassName('crumb'));
        anchorElements.forEach((anchorElement) => {
            const anchorWidth = (anchorElement as HTMLElement).offsetWidth;
            if (anchorWidth > 0) {
                const wrapperWidth = anchorWidth + (anchorWidth * 0.01);
                const parentNode = anchorElement.parentNode as HTMLElement;
                parentNode.style.width = `${wrapperWidth}px`;
            }
        });
    }
}
