import './footer.scss';
import { observable } from 'aurelia-framework';
import { PageContentAreaService } from 'services/page-content-area-service';
import { autoinject } from 'aurelia-dependency-injection';
import { WebsiteService } from 'services/website-service';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { SessionService } from 'services/session-service';
import { Helper } from 'resources/extensions/helper';

@autoinject()
export class Footer {
    footerContainer;
    currentYear;
    detailsSelectors;
    aboutUsRoute;
    blogRoute;
    contactUsRoute;
    faqRoute;
    sitemapRoute;
    privacyPolicyRoute;
    termsOfServiceRoute;
    copyrightPolicyRoute;
    viewingAsAdmin: boolean;
    adminViewSubscriber : Subscription;
    refreshTokenSubscriber : Subscription;
    sizeChangedSubscriber : Subscription;
    sizeNavigationChangedSubscriber : Subscription;
    trustPilotStarRating;
    amountOfStars;
    halfStar;
    semisesquiStar;
    divicasalesSection = [];
    supportSection = [];
    legalSection = [];
    bugBountyRoute;
    firstTimeLoad;
    @observable width;
    user;

    constructor(
        private pageContentAreaService: PageContentAreaService,
        private websiteService: WebsiteService,
        private sessionService: SessionService,
        private eventAggregator: EventAggregator,
        private helper: Helper,
    ) {}

    created() {
        this.currentYear = new Date().getFullYear();
    }

    async attached() {
        this.width = this.helper.getWidth();
        const pages = await this.websiteService.getPagesByWebsiteShortcode();

        this.divicasalesSection = [];
        this.supportSection = [];
        this.legalSection = [];

        this.aboutUsRoute = pages.find(x => x.name === 'About Us' && x.routeName) ?? { name: 'About Us', routeName: 'about-us' };
        this.blogRoute = pages.find(x => x.name === 'Blog' && x.routeName) ?? { name: 'Blog', routeName: 'blog' };
        this.bugBountyRoute = pages.find(x => x.name === 'Bug Bounty' && x.routeName) ?? { name: 'Bug Bounty', routeName: 'bug-bounty' };
        this.contactUsRoute = pages.find(x => x.name === 'Contact Us' && x.routeName) ?? { name: 'Contact Us', routeName: 'contact' };
        this.sitemapRoute = pages.find(x => x.name === 'Sitemap' && x.routeName) ?? { name: 'Sitemap', routeName: 'sitemap' };
        this.faqRoute = pages.find(x => x.name === 'Faq' && x.routeName) ?? { name: 'FAQ', routeName: 'faq' };
        this.termsOfServiceRoute = pages.find(x => x.name === 'Terms Of Service' && x.routeName) ?? { name: 'Terms Of Service', routeName: 'terms-of-service' };
        this.privacyPolicyRoute = pages.find(x => x.name === 'Privacy Policy' && x.routeName) ?? { name: 'Privacy Policy', routeName: 'privacy-policy' };
        this.copyrightPolicyRoute = pages.find(x => x.name === 'Copyright Policy' && x.routeName) ?? { name: 'Copyright Policy', routeName: 'copyright-policy' };
        this.divicasalesSection.push(this.aboutUsRoute, this.blogRoute, this.bugBountyRoute);
        this.supportSection.push(this.contactUsRoute, this.faqRoute, this.sitemapRoute);
        this.legalSection.push(this.privacyPolicyRoute, this.termsOfServiceRoute, this.copyrightPolicyRoute);
        const pageContentArea = await this.pageContentAreaService.getByPageId(pages.find(x => x.name === 'Home')?.id);
        this.helper.getTrustPilotStarRatingVariables(this, pageContentArea);

        try {
            this.user = await this.sessionService.getProfile();
            this.viewingAsAdmin = await this.sessionService.getAdminView() && await this.sessionService.checkRolesForPanelAccess();
            this.handleEventSubscriptions();
            this.handleMargin();
        } catch (e) {
            console.log(e);
        }
    }

    detached() {
        this.helper.disposeAllSubscribers(this);
    }

    handleEventSubscriptions() {
        this.adminViewSubscriber = this.eventAggregator.subscribe('admin-view-updated', payload => {
            this.viewingAsAdmin = payload.bool;
        });
        this.sizeChangedSubscriber = this.eventAggregator.subscribe('size-changed', payload => this.width = payload.width);
        this.sizeNavigationChangedSubscriber = this.eventAggregator.subscribe('size-changed-navigation-pages', payload => this.widthChanged(payload.target));
    }

    widthChanged(sticky) {
        this.helper.debounce(this, 'sizeChanging', 'sizeChangedTimeout', this.firstTimeLoad ? 1000 : 150, () => this.handleMargin(sticky));
    }

    handleMargin(sticky = undefined) {
        this.firstTimeLoad = false;
        if (!this.footerContainer) return;
        if (this.width > 1200) {
            this.footerContainer.style.marginBottom = '';
            return;
        }
        sticky ??= document.querySelector('#navigation-pages');
        this.footerContainer.style.marginBottom = sticky ? `${sticky.offsetHeight}px` : '';
    }
}
