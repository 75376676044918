import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { ResponsiveList } from './models/responsiveList';

//Screen sizes
const WIDESCREEN = 1200;
const DESKTOP = 992;
const TABLET = 768;
const PHONE = 576;

@autoinject()
export class InterComStylingService {
    constructor(private router: Router) {}

    handleStyles (width : number): void {
        const intercom = document.getElementById('intercom-style');

        /**
         * Intercom styling depending of the screen size
         */
        if ((this.router.currentInstruction.config.route === 'cart' && width < WIDESCREEN) ||
           (this.router.currentInstruction.config.name === 'customer-portal' && width < WIDESCREEN)) {
            if (!intercom) {
                const intercomStyle = document.createElement('style');
                intercomStyle.setAttribute('id', 'intercom-style');
                const bottomSize : ResponsiveList = {};
                if (this.router.currentInstruction.config.route === 'cart') {
                    bottomSize.widescreen = '70';
                } else if (this.router.currentInstruction.config.title === 'Product') {
                    bottomSize.widescreen = '75';
                } else if (this.router.currentInstruction.config.name === 'customer-portal') {
                    bottomSize.widescreen = '94';
                    bottomSize.desktop = '80';
                }
                intercomStyle.innerHTML = `.intercom-launcher-frame, .intercom-app > div:nth-child(2) { bottom: ${bottomSize.widescreen}px !important; } .intercom-lightweight-app-launcher { bottom: ${bottomSize.widescreen}px !important; } .intercom-messenger-frame { bottom: ${Number(bottomSize.widescreen) * 2}px !important; }
                
                ${bottomSize.desktop ? `@media (max-width: ${DESKTOP}px) {
                    .intercom-launcher-frame, .intercom-app > div:nth-child(2) { bottom: ${bottomSize.desktop}px !important; } .intercom-lightweight-app-launcher { bottom: ${bottomSize.desktop}px !important; } .intercom-messenger-frame { bottom: ${Number(bottomSize.desktop) * 2}px !important; }
                }` : ''}

                ${bottomSize.tablet ? `@media (max-width: ${TABLET}px) {
                    .intercom-launcher-frame, .intercom-app > div:nth-child(2) { bottom: ${bottomSize.tablet}px !important; } .intercom-lightweight-app-launcher { bottom: ${bottomSize.tablet}px !important; } .intercom-messenger-frame { bottom: ${Number(bottomSize.tablet) * 2}px !important; }
                }` : ''}

                ${bottomSize.phone ? `@media (max-width: ${PHONE}px) {
                    .intercom-launcher-frame, .intercom-app > div:nth-child(2) { bottom: ${bottomSize.phone}px !important; } .intercom-lightweight-app-launcher { bottom: ${bottomSize.phone}px !important; } .intercom-messenger-frame { bottom: ${Number(bottomSize.phone) * 2}px !important; }
                }` : ''}
                `;
                document.head.append(intercomStyle);
            }
        }
        else {
            if (intercom) {
                intercom.remove();
            }
        }
    }
}
