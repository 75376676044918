import './ds-posts-cards.scss';
import { bindable } from 'aurelia-framework';
import { getAWSBucketEndpoint, apiEndpoint } from 'environment';

export class DsPostsCards {
    constructor() {
        this.baseAwsEndpoint = getAWSBucketEndpoint('blogs');
    }

    @bindable blogs;
    @bindable blogRoute;
    baseAwsEndpoint: string;
    enviroment = apiEndpoint();
    parent;

    bind(bindingContext) {
        this.parent = bindingContext;
    }

    blogsChanged() {
        this.blogs?.forEach(blog => {
            blog.content = blog.content?.replace(/(<([^>]+)>)/gi, '');
        });
    }

    async tagClickHandler(tag) {
        await this.parent.tagClickHandler({
            name: tag,
            slug: tag.toLowerCase().replace(' ', '-'),
            activate: true
        });
    }

}
