import sanitizeHtml from 'sanitize-html';

export class SanitizeHtmlValueConverter {
    toView(value) {
        return value ? sanitizeHtml(value, {
            allowedTags: [ 'div', 'p', 'img', 'a', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'section', 'li', 'ol', 'ul',
                'br', 'i', 'span', 'strong', 'table', 'tbody', 'td', 'tfoot', 'th', 'thead', 'tr' ],
            allowedAttributes: {
                a: [ 'href', 'name', 'target', 'class'],
                // We don't currently allow img itself by default, but
                // these attributes would make sense if we did.
                img: [ 'src', 'alt', 'class' ]
            },
            selfClosing: [ 'img' ],
            allowedSchemes: ['http', 'https', 'ftp', 'mailto', 'data']
        }) : '';
    }
}
