import moment from 'moment';

export class DateFormatterValueConverter {
    toView(value, type = 'calendar', format?: string) {
        if (!value) {
            return;
        }

        if (!format) {
            format = 'MMMM Do YYYY, h:mmA';
        }


        if (type === 'calendar') {
            return moment(value).calendar();
        } else if (type === 'format') {
            return moment(value).format(format);
        } else {
            return moment.utc(value).local().format(format);
        }
    }
}
