import './ds-join-banner.scss';
import { autoinject, bindable } from 'aurelia-framework';
import { Router } from 'aurelia-router';

@autoinject()
export class DsJoinBanner {
    constructor(private router: Router) {}

    @bindable selectedGame;
    characterImg;
    characterImgCaption;

    navigateToRegistration() {
        this.router.navigate('/sign-up');
    }

    selectedGameChanged() {
        switch (this.selectedGame.shortName) {
            case 'NW':
                this.characterImg = '/currency/character-nw.png';
                this.characterImgCaption = 'Druid character';
                break;
            default:
                this.characterImg = '/currency/character-6.png';
                this.characterImgCaption = 'Join Us Banner';
                break;
        }
    }
}
