import { autoinject } from 'aurelia-dependency-injection';
import { ApiService } from './api-service';
import { EventAggregator } from 'aurelia-event-aggregator';
import { websiteShortCode } from 'environment';
import { ToastService } from './toast-service';
import { StartVerificationRequest } from './models/veriff/startVerificationRequest';
import { StartVerificationResponse } from './models/veriff/startVerificationResponse';
import { CamelCaseValueConverter } from 'resources/value-converters/camel-case';
import { Helper } from 'resources/extensions/helper';
import moment from 'moment';
import { TotalSpentByUser } from './models/user';
import { SiteSettingService } from './site-setting-service';

@autoinject()
export class CustomerService {
    path = 'User';
    idPath = this.path + '/';

    constructor(
        private api: ApiService,
        private eventAggregator: EventAggregator,
        private toastService: ToastService,
        private camelCaseValueConverter: CamelCaseValueConverter,
        private helper: Helper,
        private siteSettingService: SiteSettingService) { }

    async register(data) {
        data.websiteShortCode = websiteShortCode();
        data.fingerprint = await this.siteSettingService.getDeviceInfoForUser();
        const registerResponse = await this.api.doPost(this.path, data);
        if (registerResponse) {
            if (registerResponse.alreadyRegistered) {
                await this.toastService.showToast('Welcome back!', `Account was created on ${moment.utc(registerResponse.createdDate).local().format('MM/DD/YYYY')} on ${registerResponse.website?.name ?? 'Chicks Gold'}. Please sign in instead with the same credentials.`, 'info');
                return true;
            }
        }
        this.helper.handleGtagEvent('sign_up', null, null, null, null, data?.signInOption);
        this.helper.handleFacebookPixelEvent('CompleteRegistration');
        return registerResponse;
    }

    async updateInformation(data) {
        data.websiteShortCode = websiteShortCode();
        return await this.api.doPut(this.path + '/updateInformation', data);
    }

    async deleteProfile(password) {
        return await this.api.doDelete(this.path + '/deleteProfile', password);
    }

    async requestEmailConfirmation() {
        return await this.api.doGet(this.path + '/RequestEmailConfirmation?websiteShortCode=' + websiteShortCode());
    }

    async sendContactEmail(from, message, topic, name, attachments = null) {
        const data = {
            from: from,
            message: message,
            topic: topic,
            name: name,
            websiteShortCode: websiteShortCode(),
            attachments: attachments ? JSON.stringify({ files: attachments }) : null
        };

        return await this.api.doPost('Contact', data);
    }

    async checkIfUserDeleted() {
        return await this.api.doGet(this.path + '/checkIfUserDeleted');
    }

    async setPaymentMethod(paymentMethodId: number) {
        const data = { paymentMethodId: paymentMethodId };
        return await this.api.doPut(this.path + '/updateInformation', data);
    }

    async requestTwoFactorAuthentication() {
        return await this.api.doGet(this.path + '/requestTwoFactorAuthentication');
    }

    async confirmTwoFactorActivation(token) {
        return await this.api.doPost(this.path + '/confirmTwoFactorActivation', { token: token });
    }

    async verifyTwoFactor(token) {
        return await this.api.doPost(this.path + '/VerifyTwoFactor', { token: token });
    }

    async checkIfTwoFactorEnabled() {
        return await this.api.doGet(this.path + '/checkIfTwoFactorEnabled');
    }

    async removeTwoFactorAuthentication(token) {
        return await this.api.doPost(this.path + '/removeTwoFactorAuthentication', { token: token });
    }

    async getPaysafeVaultProfile() {
        return await this.api.doGet(this.path + '/PaysafeVaultProfile');
    }

    async getPaysafeVaultLastUsedCard() {
        return await this.api.doGet(this.path + '/PaysafeVaultLastUsedCard?websiteShortCode=' + websiteShortCode());
    }

    async deletePaysafeCard(cardId) {
        return await this.api.doDelete(this.path + '/DeleteSavedCard?paysafeCardId=' + cardId);
    }

    async updatePaysafeCard(firstName, lastName, cardId, expiryDate) {
        const data = {
            expiryMonth: parseInt(expiryDate.substring(0, 2)),
            expiryYear: parseInt('20' + expiryDate.substring(expiryDate.length - 2)),
            holderName: `${firstName} ${lastName}`
        };
        return await this.api.doPut(this.path + `/UpdatePaysafeCardExpiry?cardId=${cardId}`, data);
    }

    async requestPasswordReset(data) {
        data.websiteShortCode = websiteShortCode();
        return await this.api.doPost('requestResetPassword', data);
    }

    async updatePassword(currentPassword, newPassword) {
        const data = { currentPassword: currentPassword, newPassword: newPassword };
        const response = await this.api.doPost(this.path + '/updatePassword', data);
        if (response === null) throw new Error('Failed to update password');
        return response;
    }

    async resetPasswordWithToken(email, newPassword, token) {
        const data = { newPassword, email, accessToken: token };
        return await this.api.doPost('ResetPassword', data);
    }

    async verifyResetPasswordToken(token, email) {
        const data = { token, email };
        return await this.api.doGet('VerifyPasswordResetToken', data);
    }

    async registerPhone(countryCode, phone, countryFlag) {
        const data = { phoneNumber: phone, countryCode: countryCode, phoneCountryFlag: countryFlag };
        return await this.api.doPost(this.path + '/RegisterPhoneNumber', data);
    }

    async requestCall(countryCode, phone, phoneCountryFlag) {
        return await this.api.doGet(this.path + '/RequestCallVerification?countryCode=' + countryCode + '&phoneNumber=' + phone + '&phoneCountryFlag=' + phoneCountryFlag);
    }

    async verificationCategoryCriteriaMet(verificationCategoryName) {
        return await this.api.doGet(`${this.path}/${verificationCategoryName}/GetUserVerificationCategoryCriteriaMet`);
    }

    async verifyPhone(token, usingTwilio, verificationId, idToken) {
        const data = { token: token, usingTwilio: usingTwilio, verificationId: verificationId, idToken: idToken };
        return await this.api.doPost(this.path + '/VerifyPhoneNumber', data);
    }

    async confirmEmail(email, token) {
        const data = { email: email, token: token };
        return await this.api.doPost(this.path + '/ConfirmEmail', data);
    }

    async getCheckoutVaultProfile() {
        let result = await this.api.doGet(this.path + '/CheckoutVaultProfile');
        if (result) {
            result = this.camelCaseValueConverter.toView(result);
        }
        return result;
    }

    async getCheckoutVaultLastUsedCard() {
        let result = await this.api.doGet(this.path + '/CheckoutVaultLastUsedCard');
        if (result) {
            result = this.camelCaseValueConverter.toView(result);
        }
        return result;
    }

    async deleteCheckoutCard(instrumentId) {
        return await this.api.doDelete(this.path + '/DeleteSavedCard?checkoutInstrumentId=' + instrumentId);
    }

    async updateCheckoutCard(firstName, lastName, card, expiryDate) {
        const data = {
            id: card.id,
            firstName: firstName,
            lastName: lastName,
            expirationMonth: expiryDate.substring(0, 2),
            expirationYear: expiryDate.substring(expiryDate.length - 2),
            address: card.accountHolder.billingAddress.addressLine1,
            city: card.accountHolder.billingAddress.city,
            country: card.accountHolder.billingAddress.country,
            state: card.accountHolder.billingAddress.state,
            zip: card.accountHolder.billingAddress.zip
        };
        return await this.api.doPut(this.path + '/UpdateCheckoutCardExpiry', data);
    }

    async requestIpAuthorization(data) {
        data.websiteShortCode = websiteShortCode();
        return await this.api.doPost('RequestIpAuthorization', data);
    }

    async authorizeIp(data) {
        data.websiteShortCode = websiteShortCode();
        return await this.api.doPost('AuthorizeIp', data);
    }

    async storeUserCartProducts(cartItem, userId, loggingIn, updateCart) {
        cartItem = Array.isArray(cartItem) ? cartItem[0] : cartItem;
        cartItem.userCartId = cartItem.userCartId ? cartItem.userCartId : 0;
        cartItem.quantity = parseFloat(cartItem.quantity.toString().replaceAll(',', ''));
        cartItem.id = cartItem.id ?? cartItem.productId;
        const data = {
            userId: userId,
            loggingIn: loggingIn ? loggingIn : false,
            updateCart: updateCart ? updateCart : false,
            product: cartItem
        };
        return await this.api.doPost(`${this.path}/StoreUserCartProducts`, data);
    }

    async destroyUserCartProducts(id = 0, orderId = 0) {
        return await this.api.doDelete(`${this.path}/${id}/DestroyUserCartProducts/${orderId}`);
    }

    async getUserCartProducts() {
        return await this.api.doGet(`${this.path}/GetUserCartProducts`);
    }

    async getDocumentDecision(sessionId: string): Promise<StartVerificationResponse> {
        return await this.api.doGet(`${this.path}/${sessionId}/GetDocumentDecision`);
    }

    async processPaysafe3DS(data) {
        return await this.api.doPost(`${this.path}/ProcessPaysafe3DS`, data);
    }

    async startVerification(userId: number, verification: StartVerificationRequest): Promise<StartVerificationResponse> {
        return await this.api.doPost(`${this.path}/${userId}/StartVeriffSession`, { verification });
    }

    async getVeriffUserData(id) {
        return await this.api.doGet(`${this.path}/${id}/GetVeriffUserData`);
    }

    async unverifyPhone() {
        return await this.api.doPost(`${this.path}/UnverifyPhone`, {});
    }

    async checkEmail(email: string): Promise<boolean> {
        return await this.api.doGet(`${this.path}/CheckEmail/${email?.trim()}`);
    }

    async getDiscordProfile(tokenType: string, accessToken: string) {
        return await this.api.doGet(`${this.path}/GetDiscordProfile/${tokenType}/${accessToken}`);
    }

    async getTotalSpentByUser(id: number): Promise<TotalSpentByUser> {
        return await this.api.doGet(`${this.path}/TotalSpentByUser/${id}`);
    }

    async doPut(user) {
        return await this.api.doPut(this.path, user);
    }

    async validateApplePaySession(applePayUrl) {
        return await this.api.doPost(`${this.path}/ApplePaySession`, applePayUrl);
    }
}
