import { EventAggregator } from 'aurelia-event-aggregator';
import './customer-portal.scss';
import { PLATFORM, autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { WebsiteService } from 'services/website-service';
import { Helper } from 'resources/extensions/helper';

@autoinject()
export class CustomerPortal {
    constructor(
        private router: Router,
        private websiteService: WebsiteService,
        private helper: Helper,
        private eventAggregator: EventAggregator
    ) { }

    pages;
    customerPortalRoute;

    async activate() {
        this.pages = await this.websiteService.getPagesByWebsiteShortcode();
    }

    async attached() {
        this.helper.resizeByNavbar(this, '#customer-app');
        this.eventAggregator.publish('observe-element', ({ selector: '#navigation-pages' }));
        this.customerPortalRoute = this.pages.find(x => x.name === 'Customer Portal')?.routeName ?? 'customer-portal';
    }

    detached() {
        this.helper.disposeAllSubscribers(this);
    }

    configureRouter(config, router) {
        config.options.pushState = true;
        this.router = router;
        config.map([
            {
                route: ['', 'profile'],
                name: 'profile',
                moduleId: PLATFORM.moduleName('pages/customer-portal/profile/profile'),
                title: 'Profile',
                settings: { auth: true }
            },
            {
                route: 'security',
                name: 'security',
                moduleId: PLATFORM.moduleName('pages/customer-portal/security/security'),
                title: 'Security',
                settings: { auth: true }
            },
            {
                route: 'verification',
                name: 'verification',
                moduleId: PLATFORM.moduleName('pages/customer-portal/verification/verification'),
                title: 'Verification',
                settings: { auth: true }
            },
            {
                route: 'support',
                name: 'support',
                moduleId: PLATFORM.moduleName('pages/customer-portal/support/support'),
                title: 'Support',
                settings: { auth: true }
            },
            {
                route: 'orders',
                name: 'orders',
                moduleId: PLATFORM.moduleName('pages/customer-portal/orders/orders'),
                title: 'Orders',
                settings: { auth: true }
            },
            {
                route: 'order-details/:id',
                name: 'order-details',
                moduleId: PLATFORM.moduleName('pages/customer-portal/orders/order-details/order-details'),
                title: 'Order Details',
                settings: { auth: true }
            }
        ]);

        config.mapUnknownRoutes(() => {
            this.router.navigateToRoute('404');
        });
    }
}
