export class TemplateCheckSeparatorValueConverter {
    toView(template) {
        if (!template) {
            return;
        }

        setTimeout(() => {
            const templateElements = template.children.filter(x => x.querySelector('aurelia-hide'));
            if (templateElements.length) {
                const lastElement = templateElements.pop();
                lastElement.classList.add('template-border-bottom-line-override');
            }
        }, 500);
    }
}
