import { autoinject } from 'aurelia-framework';
import { ApiService } from './api-service';

@autoinject()
export class BlogTagService {
    path = 'BlogTag';
    blogTags;

    constructor(private api: ApiService) {}

    async getAll() {
        if (this.blogTags) return this.blogTags;
        this.blogTags = await this.api.doGet(`${this.path}`);
        return this.blogTags;
    }
}
