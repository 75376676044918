import { autoinject } from 'aurelia-framework';
import { SessionService } from 'services/session-service';
import { CurrencyFormatValueConverter } from './currency-formatter';

@autoinject()
export class CalculateConvertedPriceValueConverter {
    constructor(private sessionService: SessionService, private currencyFormatValueConverter: CurrencyFormatValueConverter) { }

    async toView(value) {
        if (!value) return;
        const currency = await this.sessionService.getCurrency();
        let price;
        if (value.salePrice && value.salePrice > 0) {
            price = value.priceModification ? value.salePrice + (value.salePrice * (value.priceModification.percentage / 100)) : value.salePrice;
        } else {
            price = value.priceModification ? value.price + (value.price * (value.priceModification.percentage / 100)) : value.price;
        }
        price = await this.currencyFormatValueConverter.getConvertedPrice(price, null, null, currency);
        return { currency: currency, price: price };
    }
}
