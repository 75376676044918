import { autoinject } from 'aurelia-dependency-injection';
import { ApiService } from './api-service';
import { websiteShortCode } from 'environment';

@autoinject()
export class ImageService {
    path = 'Image';

    constructor(private api: ApiService) {}

    async postClientDocument(data, verificationId, documentTypeId) {
        let uploadPath = `${this.path}?websiteShortCode=${websiteShortCode()}`;
        verificationId ? uploadPath += `&verificationCategoryId=${verificationId}` : undefined;
        documentTypeId ? uploadPath += `&documentTypeId=${documentTypeId}` : undefined;
        return await this.api._fileUpload(uploadPath, data);
    }

    async postAvatar(data) {
        const uploadPath = `${this.path}/Avatar`;
        return await this.api._fileUpload(uploadPath, data);
    }

    buildFormData(files) {
        const formData = new FormData();
        for (let i = 0; i < files.length; i++) {
            formData.append('file', files[i], `${websiteShortCode()}-${files[i].name}`);
        }
        return formData;
    }
}
