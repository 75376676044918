import { autoinject } from 'aurelia-framework';
import { WebsiteService } from 'services/website-service';

@autoinject()
export class SiteDown {
    homePageRoute;
    constructor(private websiteService: WebsiteService) {}

    async activate() {
        this.homePageRoute = await this.websiteService.getRouteOrDefault('Home', '');
    }
}
