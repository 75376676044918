import './support.scss';
import { autoinject, bindable, observable } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { SupportTicketService } from 'services/support-ticket-service';
import { SupportTicket } from 'services/models/support-ticket/support-ticket';
import { SignalrService } from 'services/signalr-service';
import { SessionService } from 'services/session-service';
import { FaqSectionService } from 'services/faq-section-service';

@autoinject()
export class Support {
    //This array will be empty once the backend is ready.
    tickets = [];

    @bindable currentState = 'list';
    @bindable titleEdit = false;
    @bindable title = '';
    @bindable issue;
    @bindable selectOptions = [
        {
            selection: 'all',
            active: true
        },
        {
            selection: 'active',
            active: false
        },
        {
            selection: 'closed',
            active: false
        },
        {
            selection: 'draft',
            active: false
        }
    ];

    @bindable fileList = [];
    @observable page = 1;
    count = 1;
    perPage;
    sizeChanged;
    width;
    startingIndex;
    endingIndex;
    filteredTickets = [];
    isAttached = false;

    clickedOption = [{
        selection: 'all',
        active: true
    }];

    isSavingDraft = false;
    sort = [
        {
            class: 'ticket-no',
            state: false,
            key: 'ticketKey',
            type: 'string'
        },
        {
            class: 'title',
            state: false,
            key: 'title',
            type: 'string'
        },
        {
            class: 'message',
            state: false,
            key: 'description',
            type: 'string'
        },
        {
            class: 'date',
            state: false,
            key: 'date',
            type: 'string'
        },
        {
            class: 'recent-activity',
            state: false,
            key: 'updateDate',
            type: 'string'
        },
        {
            class: 'status',
            state: false,
            key: 'status',
            type: 'string'
        }
    ];

    user;
    connection;
    ticketId;
    susbcribed: boolean;
    pageLoading: boolean;
    sectionsFaq = [];
    messages;
    ticketInformation;

    constructor(private eventAggregator: EventAggregator, private sessionService: SessionService, private supportTicketService: SupportTicketService, private signalRService: SignalrService, private faqSectionService: FaqSectionService) { }

    async activate() {
        this.sectionsFaq = await this.faqSectionService.getByWebsite();
        const windowWidth = window.innerWidth;
        if (windowWidth < 579) {
            this.perPage = 6;
        } else {
            this.perPage = 8;
        }
    }

    async attached() {
        this.pageLoading = true;
        this.user = await this.sessionService.getProfile();
        this.messages = [
            {
                isSender: true,
                avatarImagePath: this.user.avatarImagePath,
                date: '24-01-2022  5:00 pm',
                message: 'Can I change the Meet Up Location?'
            },
            {
                isSender: false,
                avatarImagePath: 'chick_12',
                date: '24-01-2022  5:00 pm',
                message: 'Hello Dear Anna, Thanks for Contacting our ChicksX Support. I will give you answer in few seconds. I will give you answer in few seconds.'
            },
            {
                isSender: true,
                avatarImagePath: this.user.avatarImagePath,
                date: '24-01-2022  5:00 pm',
                message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lacinia at quis risus sed vulputate odio ut.'
            },
            {
                isSender: false,
                avatarImagePath: 'chick_12',
                date: '24-01-2022  5:00 pm',
                message: 'Mauris a diam maecenas sed enim ut sem. Morbi tristique senectus et netus et.'
            },
            {
                isSender: true,
                avatarImagePath: this.user.avatarImagePath,
                date: '24-01-2022  5:00 pm',
                message: 'Lacinia at quis risus sed vulputate odio ut.'
            },
            {
                isSender: false,
                avatarImagePath: 'chick_12',
                date: '24-01-2022  5:00 pm',
                message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
            }
        ];
        // Leaving this line commented for now, testing visuals while the backend gets done.
        // this.tickets = await this.supportTicketService.getTicketsByCustomerId(this.user.id);
        this.filteredTickets = this.tickets;
        this.handleEventSubscriptions();
        this.isAttached = true;
        await this.pageChanged();
        this.pageLoading = false;
    }

    detached() {
        this.sizeChanged?.dispose();
    }

    handleEventSubscriptions() {
        this.sizeChanged = this.eventAggregator.subscribe('size-changed', async payload => {
            this.width = payload.width;
            if (this.width < 579) {
                this.perPage = 6;
            } else {
                this.perPage = 8;
            }
            await this.pageChanged();
        });
    }

    async setActiveOption(clickedOption) {
        if (clickedOption.some(option => option.selection === 'all')) {
            this.filteredTickets = this.tickets;
        } else if (clickedOption.some(option => option.selection === 'active')) {
            this.filteredTickets = this.tickets
                .filter(ticket => clickedOption.some(option => option.selection === ticket.status || ticket.status === 'pending'));
        } else {
            this.filteredTickets = this.tickets.filter(ticket => clickedOption.some(option => option.selection === ticket.status));
        }

        await this.pageChanged();
    }

    switchToCreate() {
        this.currentState = 'create';
    }

    switchToDetails(index) {
        this.ticketInformation = this.filteredTickets[index];
        this.title = this.ticketInformation.title;
        this.issue = this.ticketInformation.description;
        this.currentState = 'detail';
        this.showIntercomMessage();
    }

    handleBack() {
        if (this.currentState === 'create' || this.currentState === 'detail') {
            this.currentState = 'home';
            this.titleEdit = false;
        }
    }

    async showIntercomMessage() {
        if (window.Intercom) {
            if (!this.issue) {
                await window.Intercom('showNewMessage', 'Hello, I need help');
            } else {
                if (this.title !== 'Choose a Title' && this.title) {
                    window.Intercom('showNewMessage', `${this.title}: ${this.issue}`);
                } else {
                    await window.Intercom('showNewMessage', this.issue);
                }
            }
        }
    }

    async pageChanged() {
        if (this.isAttached) {
            this.count = Math.ceil(this.filteredTickets.length / this.perPage);

            if (this.page > this.count && this.count > 0) {
                this.page = this.count;
            }

            this.startingIndex = (this.page - 1) * this.perPage;
            this.endingIndex = this.startingIndex + this.perPage;
        }
    }

    sortBy(index) {
        const column = this.sort[index];

        this.filteredTickets.sort((a, b) => {
            const item = [a, b];
            if (this.sort[index].state) { item.reverse(); }
            if (column.type === 'string') {
                if (item[0][column.key] > item[1][column.key]) {
                    return 1;
                }
                if (item[0][column.key] < item[1][column.key]) {
                    return -1;
                }
                return 0;
            } else if (column.type === 'number') {
                return item[0][column.key] - item[1][column.key];
            }
        });

        this.sort[index].state = !this.sort[index].state;

        const sortAsc = document.getElementById(`${column.class}-up`);
        const sortDesc = document.getElementById(`${column.class}-down`);
        if (this.sort[index].state) {
            sortAsc.style.color = 'rgba(67, 0, 169, 1)';
            sortDesc.style.color = 'rgba(0, 0, 0, 1)';
        } else {
            sortAsc.style.color = 'rgba(0, 0, 0, 1)';
            sortDesc.style.color = 'rgba(67, 0, 169, 1)';
        }
    }

    async sendUsMessage() {
        const ticket: SupportTicket = {
            customerId: this.user.id,
            description: this.issue,
            title: this.title,
            status: 'P',
            orderId: null
        };

        // TODO: place the signalR calls in the right place.
        if (!this.susbcribed)
        {
            this.susbcribed = true;
            this.ticketId = (await this.supportTicketService.createTicket(ticket)).id;

            this.connection = await this.signalRService.getSignalRConnection();

            this.connection.invoke('SubscribeSupportTicketChat', this.ticketId);

            this.connection.on('ReceiveMessage', () => {
                //Implementation required
            });

        }
        this.connection.invoke('SendMessageSupportChat', {
            userId: ticket.customerId,
            message: ticket.description,
            supportTicketId: this.ticketId
        });
    }
}
