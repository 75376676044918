import './ds-coupon-input.scss';
import { autoinject, bindable } from 'aurelia-framework';
import { CouponService } from 'services/coupon-service';
import { ClearationTimeoutValueConverter } from 'resources/value-converters/clearation-timeout';

@autoinject()
export class DsCouponInput {
    @bindable text;
    @bindable totalPrice;
    @bindable coupon;
    @bindable validCoupon;
    @bindable cart;
    @bindable couponDiscount;
    id;
    state;
    width;
    couponStopWatch;
    couponStopWatch2;
    couponStopWatch3;
    miniSpinnerStopWatch;
    showMiniSpinnerCoupon;
    couponFocusInStopWatch;
    parent;
    timeouts;
    triggeredCouponNotification;

    constructor(
        private couponService: CouponService,
        private clearationTimeoutValueConverter: ClearationTimeoutValueConverter) {
        this.couponService = couponService;
        this.clearationTimeoutValueConverter = clearationTimeoutValueConverter;
    }

    bind(bindingContext) {
        this.parent = bindingContext;
    }

    created() {
        this.id = 'menu-' + Date.now().toString(36) + Math.random().toString(36).substring(2);
    }

    attached() {
        this.width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    }

    getElementStateClass(state) {
        switch (state) {
            case 'error':
                return 'ds-input--error';
            case 'warning':
                return 'ds-input--warning';
            case 'success':
                return 'ds-input--success';
        }
    }

    scrollScreenToSummary() {
        if (this.width > 425) return;
        const dsOrderSummaryCard = document.getElementById('ds-order-summary-card');
        dsOrderSummaryCard.scrollIntoView();
    }

    clearCoupon() {
        this.coupon = null;
        this.state = null;
        this.killTimeouts();
    }

    killTimeouts() {
        this.timeouts = [this.couponStopWatch, this.couponStopWatch2, this.couponStopWatch3, this.miniSpinnerStopWatch, this.couponFocusInStopWatch];
        this.clearationTimeoutValueConverter.toView(this.timeouts);
    }

    async couponChanged() {
        this.state = null;
        this.validCoupon = null;
        this.showMiniSpinnerCoupon = this.triggeredCouponNotification = false;
        this.killTimeouts();
        if (!this.coupon) return;
        this.state = 'close';
        try {
            this.miniSpinnerStopWatch = setTimeout(() => {
                this.showMiniSpinnerCoupon = true;
            }, 1000);
            this.couponStopWatch = setTimeout(async() => {
                await this.handleCouponValidation();
                this.triggeredCouponNotification = true;
            }, 2000);
        } catch (e) {
            console.log(e);
        }
    }

    couponUpdatedOnFocusIn() {
        this.showMiniSpinnerCoupon = false;
        this.triggeredCouponNotification = false;
        this.state = 'close';
        this.validCoupon = null;
        this.killTimeouts();
        this.couponFocusInStopWatch = setTimeout(() => {
            this.couponChanged();
        });
    }

    async checkCouponValidation() {
        this.killTimeouts();
        if (!this.coupon) return;
        if (!this.triggeredCouponNotification) {
            this.showMiniSpinnerCoupon = true;
            this.state = null;
        }
        if (this.triggeredCouponNotification) return;
        this.couponStopWatch3 = setTimeout(async() => {
            await this.handleCouponValidation();
        });
    }

    getTotalPrice = () => this.totalPrice + this.couponDiscount;

    handleCouponValidation = async() => {
        const categories = this.cart.map(p => p.productCategoryId);
        const games = this.cart.map(p => p.gameId);
        const parentGames = this.cart.map(p => p.game?.parentGameId ?? p.parentGameId);
        const products = this.cart.map(p => p.id);
        const services = this.cart.map(p => p.productId);
        const productsAndServices = products.concat(services).filter(e => e !== undefined);
        const validatedCoupon = await this.couponService.validateCoupon(this.coupon.trim(), this.getTotalPrice(), categories, games, parentGames, productsAndServices);
        this.validCoupon = validatedCoupon ?? null;
        this.state = validatedCoupon ? 'success' : 'error';
        this.showMiniSpinnerCoupon = false;
    };
}
