import './ds-veriff.scss';
import { autoinject, bindable } from 'aurelia-framework';
import { Person, StartVerificationRequest } from 'services/models/veriff/startVerificationRequest';
import { StartVerificationResponse } from 'services/models/veriff/startVerificationResponse';
import { CustomerService } from 'services/customer-service';
import { User } from 'services/models/user';
import { SignalrService } from 'services/signalr-service';
import { createVeriffFrame } from '@veriff/incontext-sdk';
import { EventAggregator } from 'aurelia-event-aggregator';
import { ToastService } from 'services/toast-service';
import { HubConnection } from '@microsoft/signalr';

@autoinject()
export class DsVeriff {
    constructor(
        private customerService: CustomerService,
        private signalRService: SignalrService,
        private eventAggregator: EventAggregator,
        private toastService: ToastService) { }

    bind(bindingContext) {
        this.parent = bindingContext;
    }

    @bindable user: User;
    @bindable hiddenButton: boolean;
    private connection: HubConnection;
    openedVeriffDialog: boolean;
    parent;
    loading: boolean;
    triggeredNotification: boolean;

    async startVerification() {
        if (!this.loading || this.hiddenButton) {
            this.loading = true;
            this.triggeredNotification = false;
            try {
                this.connection = await this.signalRService.getSignalRConnection();
                const verification = new StartVerificationRequest();
                verification.person = new Person();
                verification.person.firstName = this.user.firstName;
                verification.person.lastName = this.user.lastName;

                const startResponse = await this.customerService.startVerification(this.user.id, verification);
                let veriffFrame;

                if (startResponse.status === 'success') {
                    this.openedVeriffDialog = true;
                    this.eventAggregator.publish('opened-veriff-dialog', { openedVeriffDialog: this.openedVeriffDialog });
                    veriffFrame = createVeriffFrame({
                        url: startResponse.verification.url,
                        onEvent: async(msg) => {
                            if (msg) {
                                switch (msg) {
                                    case 'CANCELED': {
                                        this.parent.summaryButtonState = 'active';
                                        break;
                                    }
                                    case 'FINISHED': {
                                        const response = await this.customerService.getDocumentDecision(startResponse.verification.id);
                                        this.handleVeriffResult(response, veriffFrame);
                                        break;
                                    }
                                }
                            }
                        }
                    });
                } else {
                    this.toastService.showToast('Error', 'There has been an error processing your request, please try again in a few minutes', 'error');
                }

                this.connection.on('VeriffResolution', (veriffResolution: StartVerificationResponse) => {
                    this.user.idVerified = veriffResolution.verification?.status === 'approved' ? true : false;
                    this.eventAggregator.publish('veriff-verification', { idVerified: this.user.idVerified });
                    if (this.user.idVerified && !this.triggeredNotification) {
                        this.triggeredNotification = true;
                        this.toastService.showToast('Success!', 'Your ID has been verified successfully', 'success');
                    } else if (!this.triggeredNotification) {
                        this.triggeredNotification = true;
                        if (veriffResolution.verification?.reason?.toLowerCase()?.includes('velocity')) {
                            this.toastService.showToast('Error', 'You have already ID verified on another account. Please log into that account to continue. Or email support@divicasales.com', 'error');
                        } else {
                            this.toastService.showToast('Error', 'There has been a problem with your ID Verification, please get in contact at support@divicasales.com', 'error');
                        }
                    }
                    if (veriffFrame) veriffFrame.close();
                });

                this.connection.invoke('SubscribeVeriffResolution', this.user.id);
            } catch (e) {
                console.log(e);
            } finally {
                this.loading = false;
            }
        }
    }

    handleVeriffResult(documentDecision, veriffFrame) {
        this.user.idVerified = documentDecision.verification?.status === 'approved' ? true : false;
        this.eventAggregator.publish('veriff-verification', { idVerified: this.user.idVerified });
        if (this.user.idVerified && !this.triggeredNotification) {
            this.triggeredNotification = true;
            this.toastService.showToast('Success!', 'Your ID has been verified successfully', 'success');
        } else if (!this.triggeredNotification) {
            this.triggeredNotification = true;
            if (documentDecision.verification?.reason?.toLowerCase()?.includes('velocity')) {
                this.toastService.showToast('Error', 'You have already ID verified on another account. Please log into that account to continue. Or email support@divicasales.com', 'error');
            } else {
                this.toastService.showToast('Error', 'There has been a problem with your ID Verification, please get in contact at support@divicasales.com', 'error');
            }
        }
        if (veriffFrame) veriffFrame.close();
    }
}
