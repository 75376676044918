import { inject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { EventAggregator } from 'aurelia-event-aggregator';
import { TemplateCheckSeparatorValueConverter } from 'resources/value-converters/template-check-separator';

@inject(Router, EventAggregator, TemplateCheckSeparatorValueConverter)
export class SellGameContent {
    constructor(router, eventAggregator, templateCheckSeparatorValueConverter) {
        this.router = router;
        this.eventAggregator = eventAggregator;
        this.templateCheckSeparatorValueConverter = templateCheckSeparatorValueConverter;
    }

    router;
    eventAggregator;
    templateCheckSeparatorValueConverter;
    templateSell;
    routeChangeSuccessSubscription;
    adminViewSubscription;
    pageContentAreaSubscription;

    key;
    viewingAsAdmin;

    activate(params, routeConfig, navigationInstruction) {
        this.key = navigationInstruction.config.settings.keyName;
    }

    attached() {
        this.handleEventSubscriptions();
        this.templateCheckSeparatorValueConverter.toView(this.templateSell);
    }

    detached() {
        this.routeChangeSuccessSubscription?.dispose();
        this.adminViewSubscription?.dispose();
        this.pageContentAreaSubscription?.dispose();
    }

    handleEventSubscriptions() {
        this.routeChangeSuccessSubscription = this.eventAggregator.subscribe('router:navigation:success', _event => {
            this.key = this.router.currentInstruction.config.settings.keyName;
        });

        this.adminViewSubscription = this.eventAggregator.subscribe('admin-view-updated', payload => {
            this.viewingAsAdmin = payload.bool;
        });
    }
}
