export class User {
    firstName: string;
    lastName: string;
    email: string;
    id: number;
    idVerified: boolean;
    phoneNumberConfirmed: boolean;
    emailConfirmed: boolean;
    optedInForEmails: boolean;
    closedSubscriptionBanner: boolean;
    addressVerified: boolean;
    avatarImagePath: string;
    twoFactorEnabled?: boolean;
    phoneNumber: string;
    liveChatHash: string;
    phoneCountryCode?: number;
    phoneVerified?: boolean;
    phoneCountryFlag: string;
    paymentMethodId?: number;
    gameId?: number;
    roles: string[];
    blueSnapVaultedShopperId?: number;
    blueSnapUsChicksGoldVaultedShopperId?: number;
    blueSnapUsDivicaSalesVaultedShopperId?: number;
    discordId: string;
    address: string;
    city: string;
    country: string;
    zip: string;
    state: string;
    closedChicksXBanner: boolean;
    closedTempPasswordBanner: boolean;
    isDeleted: boolean;
    balance: number;
    refreshToken;
    balanceEnabled: boolean;
    notUsingTemporaryPassword: boolean;
    userBlacklist;
    isSuperAdmin: boolean;
    departmentId?: number;
    subscriptions;
    discordTag: string;
    sardineSessionKey: string;
}

export class TotalSpentByUser {
    totalSpent: number;
    total1DaySpent: number;
    total7DaySpent: number;
    total14DaySpent: number;
    total30DaySpent: number;
    totalChargebackAmount: number;
}
