import './ds-item-category-banner.scss';
import { bindable } from 'aurelia-framework';

export class DsItemCategoryBanner {
    @bindable leftTitle;
    @bindable leftText;
    @bindable centerTitle;
    @bindable centerText;
    @bindable rightTitle;
    @bindable rightText;
}
