import './security.scss';
import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { CustomerService } from 'services/customer-service';
import { SessionService } from 'services/session-service';
import { EventAggregator } from 'aurelia-event-aggregator';
import { validateTrigger, ValidationController, ValidationRules } from 'aurelia-validation';
import { ValidationRenderer } from 'resources/validation-renderer';
import { ToastService } from 'services/toast-service';
import { ClearationTimeoutValueConverter } from 'resources/value-converters/clearation-timeout';
import qrcode from 'qrcode-generator';

@autoinject()
export class Security {
    constructor(private router: Router, private customerService: CustomerService, private sessionService: SessionService, private eventAggregator: EventAggregator, private validator: ValidationController, private toastService: ToastService, private clearationTimeoutValueConverter: ClearationTimeoutValueConverter) {
        this.validator = validator;
        this.validator.addRenderer(new ValidationRenderer());
        this.validator.validateTrigger = validateTrigger.manual;
        this.clearationTimeoutValueConverter = clearationTimeoutValueConverter;
    }

    hasTwoFactorEnabled;
    currentPassword;
    newPassword;
    confirmNewPassword;
    token;
    qrCode;
    validateConfirmNewPassword;
    showGreenCheckMarkCurrentPassword;
    showErrorCheckMarkCurrentPassword;
    showGreenCheckMarkNewPassword;
    showErrorCheckMarkNewPassword;
    showGreenCheckMarkConfirmNewPassword;
    showErrorCheckMarkConfirmNewPassword;
    newPasswordValidatorProperty;
    confirmNewPasswordValidatorProperty;
    newPasswordStopWatch;
    newPasswordStopWatch2;
    confirmNewPasswordStopWatch;
    confirmNewPasswordStopWatch2;
    pageLoading;

    user;
    userSubscriber;
    currentPasswordStopWatch;
    currentPasswordStopWatch2;
    confirmingToken;
    disablingToken;
    qrPlaceholder;
    showGreenCheckMarkToken;
    showErrorCheckMarkToken;

    timeouts;
    stopWatch1;
    stopWatch2;
    loading;
    currentPasswordValidated;

    confirmNewPasswordFocusInStopWatch;
    miniSpinnerConfirmNewPasswordStopwatch;
    showMiniSpinnerConfirmNewPassword;
    firedFunction;
    successFunction;
    toastConfirmNewPasswordSent;
    showMiniSpinnerCurrentPassword;
    toastCurrentPasswordSent;
    currentPasswordFocusInStopWatch;
    firedUpdatePasswordError;
    miniSpinnerCurrentPasswordStopwatch;
    showMiniSpinnerNewPassword;

    toastNewPasswordSent;
    showMiniSpinnerToken;
    toastTokenSent;
    firedTokenError;
    isRequesting;
    activating2FA;
    miniSpinnerNewPasswordStopwatch;
    newPasswordFocusInStopWatch;
    miniSpinnerTokenStopwatch;
    tokenStopWatch;
    tokenStopWatch2;
    tokenFocusInStopWatch;

    async activate() {
        this.user = await this.sessionService.refreshProfile();
        if (!this.user) {
            this.router.navigateToRoute('home');
            return;
        }
    }

    async attached() {
        this.pageLoading = true;
        this.hasTwoFactorEnabled = await this.customerService.checkIfTwoFactorEnabled();
        this.validateConfirmNewPassword = true;
        this.user = this.user ?? await this.sessionService.refreshProfile();
        if (this.user) {
            this.user.emailInReview = await this.sessionService.getEmailInReview();
            this.user.idVerificationInReview = await this.sessionService.getIdVerificationInReview(this.user.id);
            this.user.addressVerificationInReview = await this.sessionService.getAddressVerificationInReview(this.user.id);
            this.user.selfieVerificationInReview = await this.sessionService.getSelfieVerificationInReview(this.user.id);
        }

        this.handleEventSubscriptions();

        this.pageLoading = false;
    }

    detached() {
        this.userSubscriber?.dispose();
    }

    handleEventSubscriptions() {
        this.userSubscriber = this.eventAggregator.subscribe('user-updated', payload => {
            this.user = payload.user;
        });
    }

    async currentPasswordUpdatedOnKeyPress(ev) {
        this.showGreenCheckMarkCurrentPassword = this.showErrorCheckMarkCurrentPassword = this.showMiniSpinnerCurrentPassword = this.toastCurrentPasswordSent = false;
        this.timeouts = [this.miniSpinnerCurrentPasswordStopwatch, this.currentPasswordStopWatch, this.currentPasswordStopWatch2, this.currentPasswordFocusInStopWatch];
        this.clearationTimeoutValueConverter.toView(this.timeouts);
        await this.validator.reset();
        if (ev?.key === 'Enter') {
            this.checkCurrentPasswordValidation();
            return;
        } else {
            if (this.currentPassword !== undefined) {
                this.miniSpinnerCurrentPasswordStopwatch = setTimeout(() => {
                    this.showMiniSpinnerCurrentPassword = true;
                }, 1000);
                this.currentPasswordStopWatch = setTimeout(async() => {
                    ValidationRules.ensure('currentPassword').required().minLength(8).maxLength(100).on(this);
                    const rules = await this.validator.validate();
                    if (rules.valid) {
                        if (this.showGreenCheckMarkNewPassword && this.showGreenCheckMarkConfirmNewPassword) {
                            this.firedFunction = true;
                            await this.updatePassword();
                        } else if (rules.valid && (this.successFunction || !this.newPassword || !this.confirmNewPassword || this.showErrorCheckMarkNewPassword || this.showErrorCheckMarkConfirmNewPassword)) {
                            this.showMiniSpinnerCurrentPassword = this.showErrorCheckMarkCurrentPassword = false;
                            this.showGreenCheckMarkCurrentPassword = true;
                        }
                        if (this.successFunction) {
                            this.showGreenCheckMarkCurrentPassword = true;
                        } else {
                            this.showMiniSpinnerCurrentPassword = false;
                        }
                    } else {
                        await this.validator.reset();
                        this.currentPasswordStopWatch2 = setTimeout(async() => {
                            ValidationRules.ensure('currentPassword').required().minLength(8).maxLength(100).on(this);
                            const rules2 = await this.validator.validate();
                            this.showErrorCheckMarkCurrentPassword = !rules2.valid;
                            this.showMiniSpinnerCurrentPassword = false;
                            this.toastCurrentPasswordSent = true;
                            this.toastService.showToast('Error', 'Please enter a valid current password.', 'error');
                        }, 2000);
                    }
                }, 2000);
            }
        }
    }

    currentPasswordOnFocusIn() {
        this.showGreenCheckMarkCurrentPassword = this.showErrorCheckMarkCurrentPassword = this.showMiniSpinnerCurrentPassword = false;
        this.firedFunction = false;
        this.firedUpdatePasswordError = false;
        this.toastCurrentPasswordSent = false;
        this.validator.reset();

        this.currentPasswordFocusInStopWatch = setTimeout(() => {
            if (this.currentPassword !== undefined) {
                this.currentPasswordUpdatedOnKeyPress({ key: 'Enter' });
            }
        });
    }

    async checkCurrentPasswordValidation() {
        this.showMiniSpinnerCurrentPassword = false;
        this.timeouts = [this.miniSpinnerCurrentPasswordStopwatch, this.currentPasswordStopWatch, this.currentPasswordStopWatch2, this.currentPasswordFocusInStopWatch];
        this.clearationTimeoutValueConverter.toView(this.timeouts);
        ValidationRules.ensure('currentPassword').required().minLength(6).maxLength(100).on(this);
        const rules = await this.validator.validate();
        if (rules.valid && this.showGreenCheckMarkNewPassword && this.showGreenCheckMarkConfirmNewPassword && !this.firedFunction) {
            await this.updatePassword();
        }

        if (rules.valid && (this.successFunction || !this.newPassword || !this.confirmNewPassword || this.showErrorCheckMarkNewPassword || this.showErrorCheckMarkConfirmNewPassword)) {
            this.showMiniSpinnerCurrentPassword = this.showErrorCheckMarkCurrentPassword = false;
            this.showGreenCheckMarkCurrentPassword = true;
        } else if (!this.successFunction) {
            this.showErrorCheckMarkCurrentPassword = true;
            if (!this.toastCurrentPasswordSent && !this.firedUpdatePasswordError) {
                this.toastService.showToast('Error', 'Please enter a valid current password.', 'error');
            }
        }
    }

    async newPasswordUpdatedOnKeyPress(ev) {
        this.showMiniSpinnerNewPassword = this.toastNewPasswordSent = this.firedFunction = this.successFunction = false;
        ValidationRules.ensure('newPassword').matches(/[a-z]/).minLength(8).maxLength(100).matches(/[0-9]/).matches(/[A-Z]/).matches(/^\W_/).on(this);
        this.newPasswordValidatorProperty = await this.validator.validate();
        let counterValidationsNewPassword = 0;
        if (ev?.key === 'Enter') {
            this.checkNewPasswordValidation();
            return;
        } else {
            if (this.newPassword) {
                for (const validations of this.newPasswordValidatorProperty.results) {
                    if (validations.valid && validations.rule.messageKey !== 'maxLength') {
                        counterValidationsNewPassword++;
                    }
                }
            }
            if (counterValidationsNewPassword < 3) {
                this.showGreenCheckMarkNewPassword = false;
                this.showErrorCheckMarkNewPassword = false;
            }
            this.timeouts = [this.newPasswordStopWatch, this.newPasswordStopWatch2, this.miniSpinnerNewPasswordStopwatch, this.newPasswordFocusInStopWatch];
            this.clearationTimeoutValueConverter.toView(this.timeouts);
            this.newPasswordFocusInStopWatch = setTimeout(() => {
                this.showMiniSpinnerNewPassword = true;
            }, 1000);
            this.newPasswordStopWatch = setTimeout(async() => {
                let counterValidationsNewPasswordTwo = 0;
                if (this.newPassword) {
                    for (const validations of this.newPasswordValidatorProperty.results) {
                        if (validations.valid && validations.rule.messageKey !== 'maxLength') {
                            counterValidationsNewPasswordTwo++;
                        }
                    }
                    if (!this.newPasswordValidatorProperty.results.find(x => x.rule.messageKey === 'maxLength').valid) {
                        counterValidationsNewPasswordTwo = 0;
                    }
                }
                if (counterValidationsNewPasswordTwo >= 3 && this.newPasswordValidatorProperty.results.find(x => x.rule.messageKey === 'minLength' && x.valid)) {
                    this.showErrorCheckMarkNewPassword = false;
                    if (this.showGreenCheckMarkCurrentPassword && this.showGreenCheckMarkConfirmNewPassword) {
                        this.firedFunction = true;
                        await this.updatePassword();
                    } else if (this.successFunction || !this.currentPassword || !this.confirmNewPassword || this.showErrorCheckMarkCurrentPassword || this.showErrorCheckMarkConfirmNewPassword) {
                        this.showGreenCheckMarkNewPassword = true;
                    } else {
                        this.showMiniSpinnerNewPassword = false;
                    }
                } else {
                    this.newPasswordStopWatch2 = setTimeout(() => {
                        this.showGreenCheckMarkNewPassword = this.showMiniSpinnerNewPassword = false;
                        this.showErrorCheckMarkNewPassword = this.toastNewPasswordSent = true;
                        if (this.newPassword) this.toastService.showToast('Error', 'Please enter a valid new password.', 'error');
                    }, 2000);
                }
            }, 2000);
        }
    }

    newPasswordOnFocusIn() {
        this.showGreenCheckMarkNewPassword = this.showErrorCheckMarkNewPassword = this.showMiniSpinnerNewPassword = false;
        this.firedFunction = false;
        this.toastNewPasswordSent = false;
        this.validator.reset();

        this.newPasswordFocusInStopWatch = setTimeout(() => {
            if (this.newPassword !== undefined) {
                this.newPasswordUpdatedOnKeyPress({ key: 'Enter' });
            }
        });
    }

    async checkNewPasswordValidation() {
        this.showMiniSpinnerNewPassword = false;
        this.timeouts = [this.newPasswordStopWatch, this.newPasswordStopWatch2, this.miniSpinnerNewPasswordStopwatch, this.newPasswordFocusInStopWatch];
        this.clearationTimeoutValueConverter.toView(this.timeouts);
        ValidationRules.ensure('newPassword').matches(/[a-z]/).minLength(8).maxLength(100).matches(/[0-9]/).matches(/[A-Z]/).matches(/^\W_/).on(this);
        this.newPasswordValidatorProperty = await this.validator.validate();
        let counterValidationsNewPassword = 0;
        if (this.newPassword) {
            for (const validations of this.newPasswordValidatorProperty.results) {
                if (validations.valid && validations.rule.messageKey !== 'maxLength') {
                    counterValidationsNewPassword++;
                }
            }
        }

        if (!this.newPasswordValidatorProperty.results.find(x => x.rule.messageKey === 'maxLength').valid) {
            counterValidationsNewPassword = 0;
        }

        if (counterValidationsNewPassword >= 3 && this.newPasswordValidatorProperty.results.find(x => x.rule.messageKey === 'minLength' && x.valid)) {
            this.showErrorCheckMarkNewPassword = false;
            if (this.showGreenCheckMarkCurrentPassword && this.showGreenCheckMarkConfirmNewPassword) {
                this.firedFunction = true;
                await this.updatePassword();
            } else if (this.successFunction || !this.currentPassword || !this.confirmNewPassword || this.showErrorCheckMarkCurrentPassword || this.showErrorCheckMarkConfirmNewPassword) {
                this.showGreenCheckMarkNewPassword = true;
            } else {
                this.showMiniSpinnerNewPassword = false;
            }
        } else {
            this.showGreenCheckMarkNewPassword = false;
            this.showErrorCheckMarkNewPassword = true;
            if (!this.toastNewPasswordSent && !this.firedUpdatePasswordError && this.newPassword) {
                this.toastService.showToast('Error', 'Please enter a valid new password.', 'error');
            }
        }


    }

    async confirmNewPasswordUpdatedOnKeyPress(ev) {
        this.showMiniSpinnerConfirmNewPassword = this.toastConfirmNewPasswordSent = this.firedFunction = this.successFunction = false;
        ValidationRules.ensure('confirmNewPassword').matches(/[a-z]/).minLength(8).maxLength(100).matches(/[0-9]/).matches(/[A-Z]/).matches(/^\W_/).on(this);
        this.confirmNewPasswordValidatorProperty = await this.validator.validate();
        let counterValidationsConfirmNewPassword = 0;
        if (ev?.key === 'Enter') {
            this.checkConfirmNewPasswordValidation();

        } else {
            if (this.confirmNewPassword) {
                for (const validations of this.confirmNewPasswordValidatorProperty.results) {
                    if (validations.valid && validations.rule.messageKey !== 'maxLength') {
                        counterValidationsConfirmNewPassword++;
                    }
                }
            }
            if (counterValidationsConfirmNewPassword < 3) {
                this.showGreenCheckMarkConfirmNewPassword = false;
                this.showErrorCheckMarkConfirmNewPassword = false;
            }
            this.timeouts = [this.confirmNewPasswordStopWatch, this.confirmNewPasswordStopWatch2, this.miniSpinnerConfirmNewPasswordStopwatch, this.confirmNewPasswordFocusInStopWatch];
            this.clearationTimeoutValueConverter.toView(this.timeouts);
            this.confirmNewPasswordFocusInStopWatch = setTimeout(() => {
                this.showMiniSpinnerConfirmNewPassword = true;
            }, 1000);
            this.confirmNewPasswordStopWatch = setTimeout(async() => {
                let counterValidationsConfirmNewPasswordTwo = 0;
                if (this.confirmNewPassword) {
                    for (const validations of this.confirmNewPasswordValidatorProperty.results) {
                        if (validations.valid && validations.rule.messageKey !== 'maxLength') {
                            counterValidationsConfirmNewPasswordTwo++;
                        }
                    }
                    if (!this.confirmNewPasswordValidatorProperty.results.find(x => x.rule.messageKey === 'maxLength').valid) {
                        counterValidationsConfirmNewPasswordTwo = 0;
                    }
                }
                if (counterValidationsConfirmNewPasswordTwo >= 3 && this.confirmNewPasswordValidatorProperty.results.find(x => x.rule.messageKey === 'minLength' && x.valid)) {
                    this.showErrorCheckMarkConfirmNewPassword = false;
                    if (this.showGreenCheckMarkCurrentPassword && this.showGreenCheckMarkNewPassword) {
                        this.firedFunction = true;
                        await this.updatePassword();
                    } else if (this.successFunction || !this.currentPassword || !this.newPassword || this.showErrorCheckMarkCurrentPassword || this.showErrorCheckMarkNewPassword) {
                        this.showGreenCheckMarkConfirmNewPassword = true;
                    } else {
                        this.showMiniSpinnerConfirmNewPassword = false;
                    }
                } else {
                    this.confirmNewPasswordStopWatch2 = setTimeout(() => {
                        this.showGreenCheckMarkConfirmNewPassword = this.showMiniSpinnerConfirmNewPassword = false;
                        this.showErrorCheckMarkConfirmNewPassword = this.toastConfirmNewPasswordSent = true;
                        if (this.confirmNewPassword) this.toastService.showToast('Error', 'Please enter a valid confirm password.', 'error');
                    }, 2000);
                }
            }, 2000);
        }
    }

    confirmNewPasswordOnFocusIn() {
        this.showGreenCheckMarkConfirmNewPassword = this.showErrorCheckMarkConfirmNewPassword = this.showMiniSpinnerConfirmNewPassword = false;
        this.firedFunction = false;
        this.toastConfirmNewPasswordSent = false;
        this.validator.reset();

        this.confirmNewPasswordFocusInStopWatch = setTimeout(() => {
            if (this.confirmNewPassword !== undefined) {
                this.confirmNewPasswordUpdatedOnKeyPress({ key: 'Enter' });
            }
        });
    }

    async checkConfirmNewPasswordValidation() {
        this.showMiniSpinnerConfirmNewPassword = false;
        this.timeouts = [this.confirmNewPasswordStopWatch, this.confirmNewPasswordStopWatch2, this.miniSpinnerConfirmNewPasswordStopwatch, this.confirmNewPasswordFocusInStopWatch];
        this.clearationTimeoutValueConverter.toView(this.timeouts);
        ValidationRules.ensure('confirmNewPassword').matches(/[a-z]/).minLength(8).maxLength(100).matches(/[0-9]/).matches(/[A-Z]/).matches(/^\W_/).on(this);
        this.confirmNewPasswordValidatorProperty = await this.validator.validate();
        let counterValidationsConfirmNewPassword = 0;
        if (this.confirmNewPassword) {
            for (const validations of this.confirmNewPasswordValidatorProperty.results) {
                if (validations.valid && validations.rule.messageKey !== 'maxLength') {
                    counterValidationsConfirmNewPassword++;
                }
            }
        }

        if (!this.confirmNewPasswordValidatorProperty.results.find(x => x.rule.messageKey === 'maxLength').valid) {
            counterValidationsConfirmNewPassword = 0;
        }

        if (counterValidationsConfirmNewPassword >= 3 && this.confirmNewPasswordValidatorProperty.results.find(x => x.rule.messageKey === 'minLength' && x.valid)) {
            this.showErrorCheckMarkConfirmNewPassword = false;
            if (this.showGreenCheckMarkCurrentPassword && this.showGreenCheckMarkNewPassword) {
                this.firedFunction = true;
                await this.updatePassword();
            } else if (this.successFunction || !this.currentPassword || !this.newPassword || this.showErrorCheckMarkCurrentPassword || this.showErrorCheckMarkNewPassword) {
                this.showGreenCheckMarkConfirmNewPassword = true;
            } else {
                this.showMiniSpinnerConfirmNewPassword = false;
            }
        } else {
            this.showGreenCheckMarkConfirmNewPassword = false;
            this.showErrorCheckMarkConfirmNewPassword = true;
            if (!this.toastConfirmNewPasswordSent && !this.firedUpdatePasswordError && this.confirmNewPassword) {
                this.toastService.showToast('Error', 'Please enter a valid confirm password.', 'error');
            }
        }
    }

    async tokenUpdatedOnKeyPress() {
        this.showGreenCheckMarkToken = this.showErrorCheckMarkToken = this.showMiniSpinnerToken = this.toastTokenSent = false;
        this.timeouts = [this.miniSpinnerTokenStopwatch, this.tokenStopWatch, this.tokenStopWatch2, this.tokenFocusInStopWatch];
        this.clearationTimeoutValueConverter.toView(this.timeouts);
        await this.validator.reset();
        if (this.token !== undefined) {
            this.miniSpinnerTokenStopwatch = setTimeout(() => {
                this.showMiniSpinnerToken = true;
            }, 1000);
            this.tokenStopWatch = setTimeout(async() => {
                ValidationRules.ensure('token').required().when(() => this.confirmingToken || this.disablingToken).minLength(6).maxLength(6).on(this);
                const rules = await this.validator.validate();
                if (rules.valid) {
                    this.firedFunction = true;
                    if (this.confirmingToken) {
                        await this.confirmTwoFactorActivation();
                    } else {
                        await this.removeTwoFactorAuthentication();
                    }

                    if (this.successFunction) {
                        this.showGreenCheckMarkToken = true;
                    } else {
                        this.showMiniSpinnerToken = false;
                    }
                } else {
                    await this.validator.reset();
                    this.tokenStopWatch2 = setTimeout(async() => {
                        ValidationRules.ensure('token').required().when(() => this.confirmingToken || this.disablingToken).minLength(6).maxLength(6).on(this);
                        const rules2 = await this.validator.validate();
                        this.showErrorCheckMarkToken = !rules2.valid;
                        this.showMiniSpinnerToken = false;
                        this.toastTokenSent = true;
                        this.toastService.showToast('Error', 'Please enter a valid token.', 'error');
                    }, 2000);
                }
            }, 2000);
        }
    }

    tokenOnFocusIn() {
        this.showGreenCheckMarkToken = this.showErrorCheckMarkToken = this.showMiniSpinnerToken = false;
        this.firedFunction = false;
        this.firedTokenError = false;
        this.toastTokenSent = false;
        this.validator.reset();

        this.tokenFocusInStopWatch = setTimeout(() => {
            if (this.token !== undefined) {
                this.tokenUpdatedOnKeyPress();
            }
        });
    }

    async checkTokenValidation() {
        this.showMiniSpinnerToken = false;
        this.timeouts = [this.miniSpinnerTokenStopwatch, this.tokenStopWatch, this.tokenStopWatch2, this.tokenFocusInStopWatch];
        this.clearationTimeoutValueConverter.toView(this.timeouts);
        ValidationRules.ensure('token').required().when(() => this.confirmingToken || this.disablingToken).minLength(6).maxLength(6).on(this);
        const rules = await this.validator.validate();

        if (rules.valid && !this.firedFunction) {
            if (this.confirmingToken) {
                await this.confirmTwoFactorActivation();
            } else {
                await this.removeTwoFactorAuthentication();
            }
        }

        if (rules.valid && this.successFunction) {
            this.showMiniSpinnerToken = this.showErrorCheckMarkToken = false;
            this.showGreenCheckMarkToken = true;
        } else if (!this.successFunction) {
            this.showErrorCheckMarkToken = true;
            if (!this.toastTokenSent && !this.firedTokenError) {
                this.toastService.showToast('Error', 'Please enter a valid token.', 'error');
            }
        }
    }

    async updatePassword() {
        this.isRequesting = true;
        if (this.newPassword === this.confirmNewPassword) {
            try {
                const response = await this.customerService.updatePassword(this.currentPassword, this.newPassword);
                if (response && response?.success) {
                    this.showMiniSpinnerCurrentPassword = this.showMiniSpinnerNewPassword = this.showMiniSpinnerConfirmNewPassword = this.showErrorCheckMarkCurrentPassword = this.showErrorCheckMarkNewPassword = this.showErrorCheckMarkConfirmNewPassword = false;
                    this.successFunction = true;
                    this.toastService.showToast('Success!', 'Updated password.', 'success');
                    this.isRequesting = false;
                    this.currentPassword = undefined;
                    this.newPassword = undefined;
                    this.confirmNewPassword = undefined;
                    this.showGreenCheckMarkCurrentPassword = this.showGreenCheckMarkNewPassword = this.showGreenCheckMarkConfirmNewPassword = this.showErrorCheckMarkCurrentPassword = this.showErrorCheckMarkNewPassword = this.showErrorCheckMarkConfirmNewPassword = null;
                    this.validator.reset();
                } else {
                    this.showGreenCheckMarkCurrentPassword = this.showMiniSpinnerCurrentPassword = this.showMiniSpinnerNewPassword = this.showMiniSpinnerConfirmNewPassword = false;
                    this.showGreenCheckMarkNewPassword = this.showGreenCheckMarkConfirmNewPassword = this.showErrorCheckMarkCurrentPassword = true;
                    this.successFunction = false;
                    this.firedUpdatePasswordError = true;
                    this.toastService.showToast('Error updating password.', 'Please check that the current password is correct, then try again or contact live chat for assistance.', 'error');
                    this.isRequesting = false;
                }
            } catch (e) {
                console.log(e);
            }
        } else {
            this.showGreenCheckMarkNewPassword = this.showGreenCheckMarkConfirmNewPassword = this.showMiniSpinnerCurrentPassword = this.showMiniSpinnerNewPassword = this.showMiniSpinnerConfirmNewPassword = false;
            this.showErrorCheckMarkNewPassword = this.showErrorCheckMarkConfirmNewPassword = true;
            this.successFunction = false;
            this.toastService.showToast('Error', 'Passwords do not match.', 'error');
            this.isRequesting = false;
        }
    }

    deactivateTwoFactorAuthentication() {
        this.showGreenCheckMarkToken = this.showErrorCheckMarkToken = this.showMiniSpinnerToken = false;
        this.disablingToken = true;
    }

    async sendTwoFactorAuthenticationRequest() {
        try {
            this.activating2FA = true;
            this.showGreenCheckMarkToken = this.showErrorCheckMarkToken = this.showMiniSpinnerToken = false;
            const response = await this.customerService.requestTwoFactorAuthentication();
            this.qrCode = response.authenticatorUri;
            const qr = qrcode(0, 'L');
            qr.addData(this.qrCode);
            qr.make();
            this.qrPlaceholder.innerHTML = qr.createImgTag(2, 2);
            this.confirmingToken = true;
        } catch (e) {
            console.log(e);
            this.toastService.showToast('Error trying to activate 2fa.', 'Error trying to activate 2fa. Contact live chat for assistance.', 'error');
        } finally {
            this.activating2FA = false;
        }
    }

    async confirmTwoFactorActivation() {
        try {
            const response = await this.customerService.confirmTwoFactorActivation(this.token);
            if (response.success === true) {
                this.successFunction = true;
                this.token = undefined;
                this.confirmingToken = false;
                this.hasTwoFactorEnabled = true;
                this.showMiniSpinnerToken = this.showErrorCheckMarkToken = false;
                this.isRequesting = false;
                this.validator.reset();
                this.toastService.showToast('Two-factor authentication is activated.', 'You added an extra layer of security to your account.', 'success');
            } else {
                this.showGreenCheckMarkToken = this.showMiniSpinnerToken = false;
                this.showErrorCheckMarkToken = true;
                this.successFunction = false;
                this.firedTokenError = true;
                this.toastService.showToast('Error confirming token.', 'Please check that the token is correct, then try again or contact live chat for assistance.', 'error');
                this.isRequesting = false;
            }
        } catch (e) {
            this.toastService.showToast('Error trying to activate 2fa.', 'Contact livechat for assistance.', 'error');
        }
    }

    async removeTwoFactorAuthentication() {
        const response = await this.customerService.removeTwoFactorAuthentication(this.token);
        if (response.success === true) {
            this.successFunction = true;
            this.token = undefined;
            this.disablingToken = false;
            this.hasTwoFactorEnabled = false;
            this.showMiniSpinnerToken = this.showErrorCheckMarkToken = false;
            this.isRequesting = false;
            this.validator.reset();
            this.toastService.showToast('Two-factor authentication is disabled.', 'Please enable it to secure your account from unauthorized access.', 'warning');
        } else {
            this.showGreenCheckMarkToken = this.showMiniSpinnerToken = false;
            this.showErrorCheckMarkToken = true;
            this.successFunction = false;
            this.firedTokenError = true;
            this.toastService.showToast('Error Removing 2FA.', 'Please check that the token is correct, then try again or contact live chat for assistance.', 'error');
            this.isRequesting = false;
        }
    }

    async sendConfirmationEmail() {
        try {
            const sent = await this.customerService.requestEmailConfirmation();
            if (sent) {
                this.user.emailInReview = true;
                await this.sessionService.saveEmailInReview(this.user.emailInReview);
                this.eventAggregator.publish('user-updated', { user: this.user });
                this.toastService.showToast('Success!.', 'Confirmation email sent.', 'success');
            }
        } catch (e) {
            console.log(e);
        }
    }
}
