import { MdcExpandable } from '@aurelia-mdc-web/expandable';
import { FrameworkConfiguration } from 'aurelia-framework';
import { PLATFORM } from 'aurelia-pal';

MdcExpandable.prototype.updateContainerHeight = function () {
    if (this.open) {
        this.contentContainer.addEventListener('transitionend', this);
        this.contentContainer.style.height = `${this.content.clientHeight}px`;
    } else {
        this.contentContainer.style.height = `${this.content.clientHeight}px`;
        this.taskQueue.queueTask(() => {
            if (this.contentContainer) {
                this.contentContainer.style.overflow = 'hidden';
                this.contentContainer.style.height = '0';
            }
        });
    }
};

export function configure(config: FrameworkConfiguration): void {
    config.globalResources([
        PLATFORM.moduleName('./elements/footer/footer'),
        PLATFORM.moduleName('./elements/navbar/navbar'),
        PLATFORM.moduleName('./elements/ds-page-content-area/ds-page-content-area'),
        PLATFORM.moduleName('./elements/ds-account-type/ds-account-type'),
        PLATFORM.moduleName('./elements/home-card/home-card'),
        PLATFORM.moduleName('./elements/ds-auth-form/ds-auth-form'),
        PLATFORM.moduleName('./elements/ds-icon/ds-icon'),
        PLATFORM.moduleName('./elements/ds-user-dropdown/ds-user-dropdown'),
        PLATFORM.moduleName('./elements/ds-phone-input/ds-phone-input'),
        PLATFORM.moduleName('./elements/ds-button/ds-button'),
        PLATFORM.moduleName('./elements/ds-customer-portal-navigation/ds-customer-portal-navigation'),
        PLATFORM.moduleName('./elements/ds-progress-bar/ds-progress-bar'),
        PLATFORM.moduleName('./elements/ds-status-tag/ds-status-tag'),
        PLATFORM.moduleName('./elements/ds-dialog/ds-dialog'),
        PLATFORM.moduleName('./elements/ds-banner-top/ds-banner-top'),
        PLATFORM.moduleName('./elements/ds-avatar-dialog/ds-avatar-dialog'),
        PLATFORM.moduleName('./elements/ds-toast/ds-toast'),
        PLATFORM.moduleName('./elements/ds-touch-target/ds-touch-target'),
        PLATFORM.moduleName('./elements/ds-line/ds-line'),
        PLATFORM.moduleName('./elements/ds-validator-progress/ds-validator-progress'),
        PLATFORM.moduleName('./elements/ds-checkbox-select/ds-checkbox-select'),
        PLATFORM.moduleName('./elements/ds-pseudo-table/ds-pseudo-table'),
        PLATFORM.moduleName('./elements/ds-pagination/ds-pagination'),
        PLATFORM.moduleName('./elements/ds-id-selfie-verification-progress/ds-id-selfie-verification-progress'),
        PLATFORM.moduleName('./elements/ds-veriff/ds-veriff'),
        PLATFORM.moduleName('./elements/ds-quad-why-choose-cards/ds-quad-why-choose-cards'),
        PLATFORM.moduleName('./elements/ds-information-card/ds-information-card'),
        PLATFORM.moduleName('./elements/ds-currency-dropdown/ds-currency-dropdown'),
        PLATFORM.moduleName('./elements/ds-faq-category-card/ds-faq-category-card'),
        PLATFORM.moduleName('./elements/ds-text-input/ds-text-input'),
        PLATFORM.moduleName('./elements/ds-faq-dialog/ds-faq-dialog'),
        PLATFORM.moduleName('./elements/ds-faq-question/ds-faq-question'),
        PLATFORM.moduleName('./elements/ds-blog-navigator/ds-blog-navigator'),
        PLATFORM.moduleName('./elements/ds-blog-preview/ds-blog-preview'),
        PLATFORM.moduleName('./elements/ds-custom-title/ds-custom-title'),
        PLATFORM.moduleName('./elements/ds-support-issue-input/ds-support-issue-input'),
        PLATFORM.moduleName('./elements/ds-accordion-menu/ds-accordion-menu'),
        PLATFORM.moduleName('./elements/ds-cart-product/ds-cart-product'),
        PLATFORM.moduleName('./elements/ds-quantity-input/ds-quantity-input'),
        PLATFORM.moduleName('./elements/ds-order-summary-card/ds-order-summary-card'),
        PLATFORM.moduleName('./elements/ds-coupon-input/ds-coupon-input'),
        PLATFORM.moduleName('./elements/ds-payment-method-selector/ds-payment-method-selector'),
        PLATFORM.moduleName('./elements/ds-deep-dropdown/ds-deep-dropdown'),
        PLATFORM.moduleName('./elements/ds-cart-login/ds-cart-login'),
        PLATFORM.moduleName('./elements/ds-game-selector/ds-game-selector'),
        PLATFORM.moduleName('./elements/ds-filter/ds-filter'),
        PLATFORM.moduleName('./elements/ds-number-arrows/ds-number-arrows'),
        PLATFORM.moduleName('./elements/ds-chat-view/ds-chat-view'),
        PLATFORM.moduleName('./elements/ds-chat-message/ds-chat-message'),
        PLATFORM.moduleName('./elements/ds-gallery-dialog/ds-gallery-dialog'),
        PLATFORM.moduleName('./elements/ds-pdf-viewer/ds-pdf-viewer'),
        PLATFORM.moduleName('./elements/google-autocomplete-places-input/google-autocomplete-places-input'),
        PLATFORM.moduleName('./elements/google-pay-checkout-form/google-pay-checkout-form'),
        PLATFORM.moduleName('./elements/paysafe-checkout-form/paysafe-checkout-form'),
        PLATFORM.moduleName('./elements/checkout-form/checkout-form'),
        PLATFORM.moduleName('./elements/ds-cart-disclaimer/ds-cart-disclaimer'),
        PLATFORM.moduleName('./elements/ds-banner/ds-banner'),
        PLATFORM.moduleName('./elements/ds-newsletter-prompt/ds-newsletter-prompt'),
        PLATFORM.moduleName('./elements/manual-checkout-form/manual-checkout-form'),
        PLATFORM.moduleName('./elements/ds-template-paragraph/ds-template-paragraph'),
        PLATFORM.moduleName('./elements/ds-game-banner/ds-game-banner'),
        PLATFORM.moduleName('./elements/ds-item-category-banner/ds-item-category-banner'),
        PLATFORM.moduleName('./elements/ds-page-content-image/ds-page-content-image'),
        PLATFORM.moduleName('./elements/ds-template-content-separator/ds-template-content-separator'),
        PLATFORM.moduleName('./elements/ds-template-paragraph-image/ds-template-paragraph-image'),
        PLATFORM.moduleName('./elements/ds-join-banner/ds-join-banner'),
        PLATFORM.moduleName('./elements/ds-why-choose-card/ds-why-choose-card'),
        PLATFORM.moduleName('./elements/ds-circular-progress/ds-circular-progress'),
        PLATFORM.moduleName('./elements/ds-social-media/ds-social-media'),
        PLATFORM.moduleName('./elements/ds-footer-dropdown/ds-footer-dropdown'),
        PLATFORM.moduleName('./elements/ds-footer-section/ds-footer-section'),
        PLATFORM.moduleName('./elements/blog/ds-posts-cards/ds-posts-cards'),
        PLATFORM.moduleName('./elements/blog/ds-sticky-post/ds-sticky-post'),
        PLATFORM.moduleName('./elements/ds-breadcrumbs/ds-breadcrumbs'),
        PLATFORM.moduleName('./elements/blog/ds-tags/ds-tags'),
        PLATFORM.moduleName('./elements/ds-dynamic-icon/ds-dynamic-icon'),
        PLATFORM.moduleName('./elements/ds-try-again-box/ds-try-again-box'),
        PLATFORM.moduleName('./elements/vgs-form/vgs-form'),
        PLATFORM.moduleName('./elements/apple-pay-checkout-form/apple-pay-checkout-form'),

        //Value converters
        PLATFORM.moduleName('./value-converters/splice-array'),
        PLATFORM.moduleName('./value-converters/text-truncate'),
        PLATFORM.moduleName('./value-converters/file-list-to-array'),
        PLATFORM.moduleName('./value-converters/capitalize-text'),
        PLATFORM.moduleName('./value-converters/clearation-timeout'),
        PLATFORM.moduleName('./value-converters/blob-to-url'),
        PLATFORM.moduleName('./value-converters/entity-status'),
        PLATFORM.moduleName('./value-converters/date-formatter'),
        PLATFORM.moduleName('./value-converters/remove-word'),
        PLATFORM.moduleName('./value-converters/number-formatter'),
        PLATFORM.moduleName('./value-converters/product-total-formatter'),
        PLATFORM.moduleName('./value-converters/currency-formatter'),
        PLATFORM.moduleName('./value-converters/background-formatter'),
        PLATFORM.moduleName('./value-converters/pascal-spacing-formatter'),
        PLATFORM.moduleName('./value-converters/phone-formatter'),
        PLATFORM.moduleName('./value-converters/camel-case'),
        PLATFORM.moduleName('./value-converters/currency-symbol-formatter'),
        PLATFORM.moduleName('./value-converters/sanitize-html'),
        PLATFORM.moduleName('./value-converters/query-params'),
        PLATFORM.moduleName('./value-converters/calculate-converted-price'),
        PLATFORM.moduleName('./value-converters/fullname-formatter'),
        PLATFORM.moduleName('./value-converters/thousand-separator'),
        PLATFORM.moduleName('./value-converters/in'),
        PLATFORM.moduleName('./value-converters/date-only'),
        PLATFORM.moduleName('./value-converters/is-file'),

        // Binding Behaviors
        PLATFORM.moduleName('./binding-behaviors/async-binding'),

        // Custom Attributes
        PLATFORM.moduleName('./custom-attributes/hex-to-filter')
    ]);
}
