import { autoinject } from 'aurelia-framework';
import { ApiService } from './api-service';

@autoinject()
export class VGSDebitCreditCardService {
    path = 'VGSDebitCreditCard';
    userCards;

    constructor(
        private api: ApiService
    ) { }

    async getUserCardData(forceFetch = false) {
        if (this.userCards && !forceFetch) return this.userCards;
        this.userCards = await this.api.doGet(this.path);
        return this.userCards;
    }

    async deleteUserCard(cardId) {
        return await this.api.doDelete(`${this.path}/${cardId}`);
    }

    async updateUserCard(holder, cardId, expDate, billingAddress) {
        const data = {
            id: cardId,
            cardHolder: holder,
            expMonth: expDate.substring(0, 2),
            expYear: expDate.substring(expDate.length - 2),
            address: billingAddress.street,
            city: billingAddress.city,
            country: billingAddress.country,
            state: billingAddress.state,
            zip: billingAddress.zip
        };
        return await this.api.doPut(this.path, data);
    }

    handle3dsFingerprint(methodUrl, threeDSMethodData) {
        if (!methodUrl || !threeDSMethodData) {
            console.error('iframe data missing');
            return;
        }

        const existingIframe = document.getElementById('three-ds-fingerprint');

        if (existingIframe) {
            existingIframe.remove();
        }

        const iframe = document.createElement('iframe');
        iframe.id = 'three-ds-fingerprint';
        iframe.style.display = 'none';

        const iframeContent = `
            <html>
                <body>
                    <form name="sendFingerprint" action="${methodUrl}" method="post">
                        <input name="threeDSMethodData" value="${threeDSMethodData}" />
                    </form>
                    <script type="text/javascript">
                        setTimeout(function() {
                            document.createElement('form').submit.call(document.sendFingerprint);
                        }, 100);
                    </script>
                </body>
            </html>
        `;

        iframe.srcdoc = iframeContent;
        document.body.appendChild(iframe);
    }

    handle3dsChallenge(acsURL, creq) {
        if (!acsURL || !creq) {
            console.error('iframe data missing');
            return;
        }

        const existingIframe = document.getElementById('three-ds-challenge');

        if (existingIframe) {
            existingIframe.remove();
        }

        const iframe = document.createElement('iframe');
        iframe.id = 'three-ds-challenge';

        const iframeContent = `
            <html>
                <body>
                    <form name="sendChallenge" action="${acsURL}" method="post">
                        <input name="creq" value="${creq}" />
                    </form>
                    <script type="text/javascript">
                        setTimeout(function() {
                            document.createElement('form').submit.call(document.sendChallenge);
                        }, 100);
                    </script>
                </body>
            </html>
        `;

        iframe.srcdoc = iframeContent;

        return iframe.outerHTML;
    }

    async get3dsResult(transactionId, updateDate = null) {
        return await this.api.doGet(`${this.path}/ThreeDSResult/${transactionId}${updateDate ? `?updateDate=${updateDate}` : ''}`);
    }

    async handle3dsResult(data) {
        return await this.api.doPost(`${this.path}/ThreeDSResult`, data);
    }
}
