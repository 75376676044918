import './ds-text-input.scss';
import { autoinject, observable, bindable } from 'aurelia-framework';
import { Router } from 'aurelia-router';

@autoinject()
export class DsTextInput {
    @bindable searchValue;
    @bindable selectedIndex: number;
    @bindable optionValue;
    @bindable keyUpFunction;
    @bindable changeSelectHandler;
    @bindable buttonHandler;

    @observable page = 1;
    @observable category;

    selectOptions = [
        'Popular',
        'Latest',
        'Newest',
        'Alphabetical'
    ];

    constructor(
        private router: Router
    ) { }

    async activate(params) {
        if (params.page) this.page = parseInt(params.page);
        if (params.category) this.category = params.category;
    }
}
