import env from '../config/environment.json';

const apiEndpoint = (): string => env.apiEndpoint;

const signalrEndpoint = (): string => env.signalrEndpoint;

const apiPdfEndpoint = (): string => env.apiPdfEndpoint;

const websiteShortCode = (): string => env.websiteShortCode;

const getAWSBucketEndpoint = (endpoint: string): string => env.amazonAwsUrl.replace('dynamic', endpoint);

const debug = (): boolean => env.debug;

const countries = (): { code: string; name: string; }[] => env.countries;

const paysafeApiKey = (): string => env.paysafeKey;

const paysafeMerchantId = (): string => env.paysafeMerchantId;

const paysafeEnvironment = (): string => env.paysafeEnvironment;

const paysafeIgnoreCc = (): string[] => env.paysafeIgnoreCC;

const paysafeUsdAccount = (): number => env.paysafeUSDAccount;

const paysafeCadAccount = (): number => env.paysafeCADAccount;

const checkoutPublicKey = (): string => env.checkoutPublicKey;

const checkoutRiskBaseUrl = (): string => env.checkoutRiskBaseUrl;

const kountUrl = (): string => env.kountUrl;

const baseUrl = (): string => env.baseUrl;

const chicksGroupBaseUrl = (): string => env.chicksGroupBaseUrl;

const adminPanelUrl = (): string => env.adminPanelUrl;

const firebaseConfig = (): { apiKey: string; authDomain: string; projectId: string; storageBucket: string; messagingSenderId: string; appId: string; measurementId: string; } => env.firebaseConfig;

const googlePayMerchantId = (): string => env.googlePayMerchantId;

const chicksGoldBaseUrl = () => env.chicksGoldBaseUrl;

const chicksXBaseUrl = () => env.chicksXBaseUrl;

const accKingsBaseUrl = () => env.accKingsBaseUrl;

const gamerTotalBaseUrl = () => env.gamerTotalBaseUrl;

const googleSignInClientId = () => env.googleSignInClientId;

const discordSignInUrl = () => env.discordSignInUrl;

const discordSignUpUrl = () => env.discordSignUpUrl;

const applePayMerchantId = () => env.applePayMerchantId;

const appleClientId = () => env.appleClientId;

const environmentName = () => env.environment;

const fingerprintApiKey = () => env.fingerprintApiKey;

const fingerprintDomainUrl = () => env.fingerprintDomainUrl;

const vgsInboundRouteId = () => env.vgsInboundRouteId;

const vgsGooglePayInboundRouteId = () => env.vgsGooglePayInboundRouteId;

const vgsApplePayInboundRouteId = () => env.vgsApplePayInboundRouteId;

const vgsVaultId = () => env.vgsVaultId;

const vgsEnv = () => env.vgsEnv;

const vgsOrganizationId = () => env.vgsOrganizationId;

const clientId = () => env.clientId;

const sardineAiClientId = () => env.sardineAi.clientId;

const sardineAiEnvironment = () => env.sardineAi.environment as 'sandbox' | 'production';

const sardineAiApiSubdomain = () => env.sardineAi.apiSubdomain;

const sardineAiPixelSubdomain = () => env.sardineAi.pixelSubdomain;

export default this;

export {
    websiteShortCode,
    apiEndpoint,
    signalrEndpoint,
    apiPdfEndpoint,
    getAWSBucketEndpoint,
    debug,
    countries,
    paysafeApiKey,
    paysafeCadAccount,
    paysafeEnvironment,
    paysafeIgnoreCc,
    paysafeMerchantId,
    paysafeUsdAccount,
    kountUrl,
    baseUrl,
    checkoutPublicKey,
    checkoutRiskBaseUrl,
    firebaseConfig,
    googlePayMerchantId,
    adminPanelUrl,
    chicksGoldBaseUrl,
    chicksXBaseUrl,
    accKingsBaseUrl,
    gamerTotalBaseUrl,
    googleSignInClientId,
    discordSignInUrl,
    discordSignUpUrl,
    applePayMerchantId,
    appleClientId,
    environmentName,
    fingerprintApiKey,
    fingerprintDomainUrl,
    vgsInboundRouteId,
    vgsGooglePayInboundRouteId,
    vgsApplePayInboundRouteId,
    vgsVaultId,
    vgsEnv,
    vgsOrganizationId,
    chicksGroupBaseUrl,
    clientId,
    sardineAiClientId,
    sardineAiEnvironment,
    sardineAiApiSubdomain,
    sardineAiPixelSubdomain
};
