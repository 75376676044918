import './orders.scss';
import { autoinject, bindable, observable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { EventAggregator } from 'aurelia-event-aggregator';
import { SessionService } from 'services/session-service';
import { OrderService } from 'services/order-service';
import { getAWSBucketEndpoint } from 'environment';
import { ProductCategoryService } from 'services/product-category-service';
import { EntityStatusValueConverter } from 'resources/value-converters/entity-status';
import { DateFormatterValueConverter } from 'resources/value-converters/date-formatter';

@autoinject()
export class Orders {
    orders = [];

    sort = [
        {
            class: 'id',
            state: false,
            key: 'ticketKey',
            type: 'string'
        },
        {
            class: 'price',
            state: false,
            key: 'title',
            type: 'string'
        },
        {
            class: 'date',
            state: false,
            key: 'date',
            type: 'string'
        },
        {
            class: 'status',
            state: false,
            key: 'status',
            type: 'string'
        }
    ];

    @observable page = 1;
    count = 1;
    perPage;
    pageLoading;

    @bindable selectOptions = [
        {
            selection: 'all',
            active: true
        },
        {
            selection: 'completed',
            active: false
        },
        {
            selection: 'pending',
            active: false
        },
        {
            selection: 'rejected',
            active: false
        },
        {
            selection: 'refunded',
            active: false
        },
        {
            selection: 'refund-pending',
            active: false
        },
        {
            selection: 'created',
            active: false
        },
    ];

    clickedOptions = ['all'];
    sizeChanged;
    width;
    startingIndex;
    endingIndex;
    filteredOrders = [];
    baseAwsEndpoint = getAWSBucketEndpoint('games');
    user;
    isAttached = false;
    navCategory;
    games = [];
    rowSelector;

    constructor(private router: Router, private eventAggregator: EventAggregator, private sessionService: SessionService, private orderService: OrderService, private productCategoryService: ProductCategoryService, private entityStatusValueConverter: EntityStatusValueConverter, private dateFormatterValueConverter: DateFormatterValueConverter) {}

    async activate() {
        this.user = await this.sessionService.refreshProfile();
        if (!this.user) {
            this.router.navigateToRoute('home');
            return;
        }
        if (this.games.length === 0) {
            this.navCategory = await this.productCategoryService.getNavCategory('currency', 'DS');
            this.games = this.navCategory?.gameForNav;
        }
    }

    async attached() {
        this.pageLoading = true;
        await this.handleFetchingOrders();
        this.handleEventSubscriptions();
        this.isAttached = true;
        await this.pageChanged();
        this.pageLoading = false;
    }

    detached() {
        this.sizeChanged?.dispose();
    }

    async handleFetchingOrders() {
        const windowWidth = window.innerWidth;
        if (windowWidth < 579) {
            this.perPage = 6;
        } else {
            this.perPage = 8;
        }
        this.orders = await this.orderService.getAll();
        this.filteredOrders = this.orders;
    }

    handleEventSubscriptions() {
        this.sizeChanged = this.eventAggregator.subscribe('size-changed', async payload => {
            this.width = payload.width;
            if (this.width < 579) {
                this.perPage = 6;
            } else {
                this.perPage = 8;
            }
            await this.pageChanged();
        });
    }

    async setActiveOption(options) {
        this.clickedOptions = options.map(option => {
            if (option.active) return option.selection;
        });

        if (this.clickedOptions.includes('all')) {
            this.filteredOrders = this.orders;
        } else {
            this.clickedOptions = this.clickedOptions.filter(option => option !== 'all');
            this.filteredOrders = this.orders.filter(order => this.clickedOptions.includes(
                this.entityStatusValueConverter.toView(order.status, order.fulfilled).toLowerCase()
            ));
        }
        await this.pageChanged();
    }

    async openOrderDetail(order) {
        this.router.navigate('order-details/' + order.id);
    }

    async pageChanged() {
        if (this.isAttached) {
            this.count = Math.ceil(this.filteredOrders.length / this.perPage);

            if (this.page > this.count && this.count > 0) {
                this.page = this.count;
            }

            this.startingIndex = (this.page - 1) * this.perPage;
            this.endingIndex = this.startingIndex + this.perPage;
        }
    }

    sortBy(index) {
        const column = this.sort[index];

        this.filteredOrders.sort((a, b) => {
            const item = [a, b];
            if (this.sort[index].state) { item.reverse(); }
            if (column.type === 'string') {
                if (item[0][column.key] > item[1][column.key]) {
                    return 1;
                }
                if (item[0][column.key] < item[1][column.key]) {
                    return -1;
                }
                return 0;
            } else if (column.type === 'number') {
                return item[0][column.key] - item[1][column.key];
            }
        });

        this.sort[index].state = !this.sort[index].state;

        const sortAsc = document.getElementById(`${column.class}-up`);
        const sortDesc = document.getElementById(`${column.class}-down`);
        if (this.sort[index].state) {
            sortAsc.style.color = 'rgba(67, 0, 169, 1)';
            sortDesc.style.color = 'rgba(0, 0, 0, 1)';
        } else {
            sortAsc.style.color = 'rgba(0, 0, 0, 1)';
            sortDesc.style.color = 'rgba(67, 0, 169, 1)';
        }
    }

    getProductImageSource(item): string {
        return item && item.product?.game?.sellIcon ? `${this.baseAwsEndpoint}${item.product.game.sellIcon}` : '';
    }
}
