import { signalrEndpoint } from 'environment';
import { autoinject } from 'aurelia-framework';
import { websiteShortCode } from 'environment';
import { HubConnection, HubConnectionBuilder, LogLevel, HubConnectionState } from '@microsoft/signalr';
import { debug } from 'environment';
import { SessionService } from 'services/session-service';

@autoinject()
export class SignalrService {
    signalRConnection: HubConnection;

    constructor(private sessionService: SessionService) {
    }

    async getSignalRConnection(): Promise <HubConnection> {
        if (!this.isSignalRConnected()) {
            await new Promise(resolve => setTimeout(resolve, 1000));
            await this.getSignalRConnection();
        }
        return this.signalRConnection;
    }

    isSignalRConnected() {
        return this.signalRConnection && this.signalRConnection?.state === 'Connected';
    }

    async setupSignalRConnection(): Promise <HubConnection> {
        return this.signalRConnection = new HubConnectionBuilder()
            .withUrl(`${signalrEndpoint()}signalRHub`, {
                accessTokenFactory: async() => await this.sessionService.getToken(),
                withCredentials: false
            })
            .withAutomaticReconnect({
                // Attempt reconnects for less than 1 minute in random delay of 0 - 10 secs,
                // Otherwise stop reconnect after multiple failed reconnects after 1 minute exhaust.
                nextRetryDelayInMilliseconds: retryContext => {
                    if (retryContext.elapsedMilliseconds < 60000) {
                        return Math.random() * 10000;
                    } else {
                        return null;
                    }
                }
            })
            .configureLogging(debug() ? LogLevel.Information : LogLevel.None)
            .build();
    }

    async start(connection: HubConnection): Promise<void> {
        try {
            await connection.start();
        } catch (err) {
            setTimeout(async () => await this.start(connection), 5000);
        }
    }

    async untrackUserConnection(userId?: number): Promise<void> {
        try {
            if (this.signalRConnection.state === HubConnectionState.Connected) {
                await this.signalRConnection.invoke('UnTrackUserConnection', websiteShortCode(), userId?.toString());
            }
        } catch (e) {
            console.log(e);
        }
    }

    async trackUserConnection(userId?: number): Promise<void> {
        try {
            if (this.signalRConnection.state === HubConnectionState.Connected) {
                await this.signalRConnection.invoke('TrackUserConnection', websiteShortCode(), userId?.toString());
            }
        } catch (e) {
            console.log(e);
        }
    }

}
