export class CapitalizeTextValueConverter {
    toView(value: string, type = 'first') {
        if (!value) {
            return '';
        }
        if (type === 'first') {
            return value.charAt(0).toUpperCase() + value.slice(1);
        } else {
            const sentence = value.split(' ');
            return sentence.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
        }
    }
}
