import { autoinject } from 'aurelia-framework';
import { ApiService } from './api-service';
import { Currency } from './models/purchase-flow/exchange';

@autoinject()
export class CurrencyService {
    path = 'Currency';
    currencyRates = [];
    activeCurrencies: Currency[];
    currencyRatesWithoutSpreadFee = [];

    constructor(private api: ApiService) {}

    async getAllCurrencyRates(websiteShortCode, withSpreadFee = true) {
        try {
            const currencyResponse = await this.api.doGet(`${this.path}?websiteShortCode=${websiteShortCode}&withSpreadFee=${withSpreadFee}`);
            if (currencyResponse) {
                const targetArray = withSpreadFee ? this.currencyRates : this.currencyRatesWithoutSpreadFee;
                targetArray.push(currencyResponse.rates);
            }
        } catch (e) {
            console.log(e);
        }
    }

    async getConvertionRateToUSD(currency, isSold = false) {
        try {
            return await this.api.doGet(`${this.path}/ConvertionRateToUSD/${currency}?websiteShortCode=DS&isSold=${isSold}`);
        } catch (e) {
            console.log(e);
        }
    }

    getStoredCurrencyRates(currency, withSpreadFee = true) {
        try {
            if (currency === 'USD' || !currency) {
                return 1;
            }
            let foundRate;
            const targetArray = withSpreadFee ? this.currencyRates : this.currencyRatesWithoutSpreadFee;
            for (const rate of targetArray) {
                for (const currencyRate in rate) {
                    if (currencyRate === currency.toLowerCase()) {
                        foundRate = rate[currencyRate];
                    }
                }
            }
            if (!foundRate) {
                return null;
            }
            return foundRate;
        } catch (e) {
            console.log(e);
        }
    }

    async getActiveCurrenciesByWebsite() {
        try {
            if (this.activeCurrencies) {
                return this.activeCurrencies;
            }
            this.activeCurrencies = await this.api.doGet(`${this.path}/GetActiveCurrenciesByWebsite?websiteShortCode=DS`);
            return this.activeCurrencies;
        } catch (e) {
            console.log(e);
        }
    }

    async getCurrencyById(id: number) {
        try {
            if (!this.activeCurrencies) {
                await this.getActiveCurrenciesByWebsite();
            }
            return this.activeCurrencies.find(c => c.id === id);
        } catch (e) {
            console.log(e);
        }
    }

    getStoredActiveCurrenciesByWebsite() {
        return this.activeCurrencies;
    }
}
