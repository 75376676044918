import './order-details.scss';
import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { SessionService } from 'services/session-service';
import { OrderService } from 'services/order-service';
import { ToastService } from 'services/toast-service';
import { CurrencyService } from 'services/currency-service';
import { InvoiceService } from 'services/invoice-service';
import { getAWSBucketEndpoint } from 'environment';
import moment from 'moment';
import QRCode from 'qrcode';
import { Order } from 'services/models/order';
import { automaticPaymentMethods } from 'resources/constants';
import { Helper } from 'resources/extensions/helper';

@autoinject()
export class Orders {
    constructor(
        private router: Router,
        private sessionService: SessionService,
        private orderService: OrderService,
        private toastService: ToastService,
        private currencyService: CurrencyService,
        private invoiceService: InvoiceService,
        private helper: Helper) {
        this.baseAwsEndpoint = getAWSBucketEndpoint('games');
        this.baseAwsEndpointPaymentMethod = getAWSBucketEndpoint('payment-methods');
    }

    baseAwsEndpoint: string;
    baseAwsEndpointPaymentMethod: string;
    pageLoading;
    user;
    order: Order;
    currencies;
    orderFetch: Order;
    generatingPdf;
    subtotal = 0;
    insuranceFee = 0;
    cardWidth = 32;
    qrCode;
    isManualOrder = false;
    timerInterval = null;
    automaticPaymentMethods = automaticPaymentMethods();

    async activate(params) {
        [this.currencies, this.orderFetch, this.user ] = await Promise.all([
            this.currencyService.getActiveCurrenciesByWebsite(),
            this.orderService.getById(params.id),
            this.sessionService.refreshProfile()
        ]);

        if (!this.user) {
            this.router.navigateToRoute('home');
            return;
        } else if (!this.orderFetch) {
            this.router.navigateToRoute('404');
            return;
        }

        this.order = this.orderFetch;
    }

    async attached() {
        this.pageLoading = true;
        this.subtotal = this.order.products.map(e => e.totalPrice).reduce((a, b) => a + b);
        this.insuranceFee = this.order.products.map(e => e.insuranceFee).reduce((a, b) => a + b);
        this.pageLoading = false;
    }

    backToOrders() {
        this.router.navigate('orders');
    }

    async showIntercomMessage() {
        if (window.Intercom) {
            window.Intercom('showNewMessage', 'Hello, I need help');
        }
    }

    async resendReceipt() {
        const response = await this.orderService.resendReceipt(this.order.id);
        if (response) {
            this.toastService.showToast('Success!', 'A copy of your receipt has been sent to you.', 'success');
        } else {
            this.toastService.showToast('Error', 'Failed to send copy of receipt. Contact Livechat for assistance.', 'error');
        }
    }

    async handleExportingPdf() {
        if (!this.generatingPdf) {
            this.generatingPdf = true;
            try {
                const result = await this.invoiceService.generatePdfReceipt(this.orderFetch.id);
                if (result) {
                    const linkElement = document.createElement('a');
                    linkElement.href = window.URL.createObjectURL(new Blob([result], { type: 'application/pdf' }));
                    linkElement.download = `ds_order_${this.order.id}_${moment.utc().local().format('MM/DD/YYYY hh:mm a')}.pdf`;
                    linkElement.click();
                    this.toastService.showToast('Success', 'Order PDF successfully downloaded', 'success');
                }
            } catch (e) {
                console.log(e);
                this.toastService.showToast('Error', 'Something went wrong! Please try again.', 'error');
            } finally {
                this.generatingPdf = false;
            }
        }
    }

    getProductImageSource(item): string {
        return item && item.product?.game?.sellIcon ? `${this.baseAwsEndpoint}${item.product.game.sellIcon}` : '';
    }

    async generateQrCode() {
        try {
            this.qrCode = await QRCode.toDataURL(this.order.paymentMethod.qrCodeValue);
        } catch (e) {
            console.error(e);
        }
    }

    getCardImageType() {
        if (this.order.cardType?.toLowerCase() === 'vi' || this.order.cardType?.toLowerCase() === 've' || this.order.cardType?.toLowerCase() === 'vd' || this.order.cardType.toLowerCase() === 'visa') {
            this.cardWidth = 42;
            return '/payment-methods/visa.svg';
        } else if (this.order.cardType?.toLowerCase() === 'mc' || this.order.cardType.toLowerCase() === 'mastercard') {
            return '/payment-methods/mastercard.svg';
        } else if (this.order.cardType?.toLowerCase() === 'am' || this.order.cardType.toLowerCase() === 'amex' || this.order.cardType.toLowerCase() === 'american express') {
            this.cardWidth = 42;
            return '/payment-methods/amex.svg';
        } else if (this.order.cardType?.toLowerCase() === 'dc' || this.order.cardType.toLowerCase() === 'diners' || this.order.cardType.toLowerCase() === 'diners club') {
            return '/payment-methods/diners.svg';
        } else if (this.order.cardType?.toLowerCase() === 'di' || this.order.cardType.toLowerCase() === 'discover') {
            this.cardWidth = 43;
            return '/payment-methods/discover.svg';
        } else if (this.order.cardType?.toLowerCase() === 'maestro') {
            this.cardWidth = 42;
            return '/payment-methods/maestro.svg';
        } else if (this.order.cardType?.toLowerCase() === 'jcb') {
            this.cardWidth = 42;
            return '/payment-methods/jcb.svg';
        } else if (this.order.cardType?.toLowerCase() === 'mada') {
            this.cardWidth = 42;
            return '/payment-methods/mada.svg';
        } else {
            this.cardWidth = 42;
            return '/payment-methods/generic.svg';
        }
    }
}
