export class BackgroundValueConverter {
    toView(value, page) {
        if (!value && !page) {
            return '/backgrounds/runescape2007.png';
        }

        const gamesWithBackgounds = ['OSRS', 'RS3'];

        const checkForBackground = gamesWithBackgounds.some((gameShortName) => gameShortName === value?.shortName);

        if (checkForBackground) {
            let gameBackgroundString = '';
            if (value.shortName?.includes('OSRS')) {
                gameBackgroundString = 'runescape2007';
            } else if (value.shortName?.includes('RS3')) {
                gameBackgroundString = 'runescape3';
            } else {
                gameBackgroundString = value.shortName.toLowerCase();
            }
            return `/images/${gameBackgroundString}.png`;
        }
    }
}
