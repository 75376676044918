export class FullnameValueConverter {
    toView(value, lastName = '') {
        if (!value && !lastName) {
            return '-';
        }

        if (!lastName) {
            lastName = '';
        }

        const fullNameSplit = `${ value } ${ lastName }`.split(' ');
        return fullNameSplit.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
    }
}
