import './ds-chat-message.scss';
import { autoinject, bindable } from 'aurelia-framework';
import { apiEndpoint } from 'environment';
import { MdcDialogService } from '@aurelia-mdc-web/dialog';

@autoinject()
export class DsChatMessage {
    @bindable isSender;
    @bindable imageUrl;
    @bindable date;
    @bindable message;
    @bindable containerClass;
    @bindable files;
    galleryDialog;
    pdfDialog;
    initialIndex;
    imagesList;
    opened = false;

    environment = apiEndpoint();
    imageFiles = [];
    documentsList = [];

    constructor(private dialogService: MdcDialogService) { }

    attached() {
        this.convertFiles();
    }

    convertFiles() {
        if (this.files) {
            for (const file of this.files) {
                if (file['type'].includes('image/')) {
                    this.imageFiles.push(file);
                } else {
                    this.documentsList.push(file);
                }
            }
        }
    }

    openGallery(index) {
        this.opened = true;
        this.dialogService.open({ viewModel: this.galleryDialog, model: { imagesList: this.imageFiles, index: index } });
    }

    viewPdf(index) {
        this.dialogService.open({ viewModel: this.pdfDialog, model: { pdfContent: this.documentsList[index] } });
    }
}
