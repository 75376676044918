// Imports
import ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ from "../../../../node_modules/.pnpm/html-loader@3.1.2_webpack@5.94.0_webpack-cli@4.10.0_/node_modules/html-loader/dist/runtime/getUrl.js";
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/icons/discord.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_1___ = new URL("/static/icons/instagram.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_2___ = new URL("/static/icons/twitter.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_3___ = new URL("/static/icons/facebook.svg", import.meta.url);
// Module
var ___HTML_LOADER_REPLACEMENT_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var ___HTML_LOADER_REPLACEMENT_1___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_1___);
var ___HTML_LOADER_REPLACEMENT_2___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_2___);
var ___HTML_LOADER_REPLACEMENT_3___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_3___);
var code = "<template>\n    <div id=\"networks\" class=\"d-flex flex-row justify-content-between\">\n        <ds-touch-target expanded>\n            <a href=\"https://discord.com/invite/chicksgroup\" target=\"_blank\" rel=\"nofollow noopener noreferrer\">\n                <div class=\"network\">\n                    <img src=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\" alt=\"social-media\" loading=\"lazy\" class=\"discord\">\n                </div>\n            </a>\n        </ds-touch-target>\n        <ds-touch-target expanded>\n            <a href=\"https://www.instagram.com/divicasales/\" target=\"_blank\" rel=\"nofollow noopener noreferrer\">\n                <div class=\"network\">\n                    <img src=\"" + ___HTML_LOADER_REPLACEMENT_1___ + "\" alt=\"social-media\" loading=\"lazy\" class=\"instagram\">\n                </div>\n            </a>\n        </ds-touch-target>\n        <ds-touch-target expanded>\n            <a href=\"https://twitter.com/SalesDivica\" target=\"_blank\" rel=\"nofollow noopener noreferrer\">\n                <div class=\"network\">\n                    <img src=\"" + ___HTML_LOADER_REPLACEMENT_2___ + "\" alt=\"social-media\" loading=\"lazy\" class=\"twitter\">\n                </div>\n            </a>\n        </ds-touch-target>\n        <ds-touch-target expanded>\n            <a href=\"https://www.facebook.com/Chicksgroup\" target=\"_blank\" rel=\"nofollow noopener noreferrer\">\n                <div class=\"network\">\n                    <img src=\"" + ___HTML_LOADER_REPLACEMENT_3___ + "\" alt=\"social-media\" loading=\"lazy\" class=\"facebook\">\n                </div>\n            </a>\n        </ds-touch-target>\n    </div>\n</template>\n";
// Exports
export default code;