import { autoinject } from 'aurelia-framework';
import { ApiService } from './api-service';

@autoinject()
export class CouponService {
    path = 'CouponCode';

    constructor(private api: ApiService) { }

    async validateCoupon(coupon, cartValue, categories, games, parentGames, products) {
        const body = {
            coupon: coupon,
            purchaseAmount: cartValue,
            categories: categories,
            games: games,
            products: products,
            parentGames: parentGames,
            websites: [ 2 ]
        };
        return await this.api.doPost(this.path + '/validate', body);
    }
}
