export class AsyncBindingBehavior {
    bind(binding, _, busymessage) {
        binding.originalupdateTarget = binding.updateTarget;
        binding.updateTarget = (a) => {
            if (typeof a.then === 'function') {
                if (busymessage) binding.originalupdateTarget(busymessage);
                a.then(d => typeof binding.originalupdateTarget === 'function' ? binding.originalupdateTarget(d) : '');
            } else {
                binding.originalupdateTarget(a);
            }
        };
    }

    unbind(binding) {
        binding.updateTarget = binding.originalupdateTarget;
        binding.originalupdateTarget = null;
    }
}
