import './ds-faq-question.scss';
import { bindable } from 'aurelia-framework';

export class DsFaqQuestion {
    @bindable question;
    @bindable functionShowTable;
    private answer: HTMLElement;
    private icon: HTMLElement;
    faqQuestionElement: HTMLElement;

    attached() {
        this.faqQuestionElement = document.getElementById(`ds-faq-question-${this.question.id}`);
    }

    toggle() {
        if (this.answer.classList.contains('answer-close')) {
            this.open();
        } else {
            this.close();
        }
    }

    open() {
        this.answer.classList.remove('answer-close');
        this.answer.classList.add('answer-open');
        this.faqQuestionElement.classList.add('active');
        this.icon.innerText = 'arrow_drop_up';
    }

    close() {
        this.answer.classList.add('answer-close');
        this.answer.classList.remove('answer-open');
        this.faqQuestionElement.classList.remove('active');
        this.icon.innerText = 'arrow_drop_down';
    }
}
