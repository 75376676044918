export class ProductTotalValueConverter {
    toView(total, hasInsurance) {
        if (!total) {
            return '0';
        }

        let price = total;

        if (hasInsurance) {
            price *= 1.25;
        }

        return price;
    }
}
