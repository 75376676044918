import { autoinject } from 'aurelia-framework';
import { SessionService } from 'services/session-service';
import { CurrencyService } from 'services/currency-service';
import numeral from 'numeral';

@autoinject()
export class CurrencySymbolFormatValueConverter {
    constructor(private sessionService: SessionService, private currencyService: CurrencyService) {}

    toView(currentCurrency, amountToConvert) {
        if (!amountToConvert) {
            return this.currencyToSymbol(currentCurrency);
        } else {
            return this.convertAmountToCurrency(currentCurrency, amountToConvert);
        }
    }

    currencyToSymbol(currentCurrency) {
        const activeCurrencies = this.currencyService.getStoredActiveCurrenciesByWebsite();
        return activeCurrencies?.find(x => x.code === currentCurrency)?.symbol || 'CURRENCY-ERROR';
    }

    convertAmountToCurrency(currentCurrency, amountToConvert) {
        const rate = this.currencyService.getStoredCurrencyRates(currentCurrency);
        if (!rate) {
            return 'CURRENCY-ERROR';
        } else {
            const format = '(0,0.00)';
            const value = amountToConvert * rate;
            const returnString = numeral(value).format(format);
            return this.currencyToSymbol(currentCurrency) + returnString;
        }
    }
}
