import './ds-banner.scss';
import { autoinject, bindable } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { SessionService } from 'services/session-service';
import { CustomerService } from 'services/customer-service';
import { ToastService } from 'services/toast-service';

@autoinject()
export class DsBanner {
    constructor(private eventAggregator: EventAggregator,
        private sessionService: SessionService,
        private customerService: CustomerService,
        private toastService: ToastService) {
    }

    @bindable text;
    @bindable state;
    @bindable showBanner;
    @bindable clickFunctionName;
    @bindable url;
    user;
    name;
    routeProcessingSubscription;
    routeChangeSubscription;

    async attached() {
        this.user = await this.sessionService.getProfile();
        this.handleEventSubscriptions();
    }

    detached() {
        this.routeChangeSubscription?.dispose();
        this.routeProcessingSubscription?.dispose();
    }

    handleEventSubscriptions() {
        this.routeProcessingSubscription = this.eventAggregator.subscribe('router:navigation:processing', async() => {
            if (this.clickFunctionName !== 'refresh') return;
            location.reload();
        });

        this.routeChangeSubscription = this.eventAggregator.subscribe('router:navigation:complete', async() => {
            if (this.state === 'warning' || this.state === 'info') return;
            this.showBanner = false;
        });
    }

    async closeBanner() {
        this.showBanner = false;

        if (this.clickFunctionName !== 'resetPassword' && this.name !== 'CxBuySellCrypto') return;
        this.user = this.user ?? await this.sessionService.getProfile();
        this.clickFunctionName === 'resetPassword' ? this.user.closedTempPasswordBanner = true : this.user.closedChicksXBanner = true;
        await this.customerService.updateInformation(this.user);
    }

    async clickFunction() {
        if (this.clickFunctionName) {
            if (this.clickFunctionName === 'resetPassword') {
                this.user = this.user ?? await this.sessionService.getProfile();
                const data = { email: this.user.email };
                const requestPasswordResponse = await this.customerService.requestPasswordReset(data);
                if (!requestPasswordResponse) return;
                this.toastService.showToast('Info', 'You are required to reset your password. An email has been sent to you to do so.', 'info');
            } else if (this.clickFunctionName === 'refresh') {
                location.reload();
            }
        }
        this.closeBanner();
    }
}
