import { FluentRuleCustomizer, FluentRules, ValidationRules } from 'aurelia-validation';

type ValidationObj = {
    addEmailRules: () => ValidationObj;
    addPasswordRules: () => ValidationObj;
    addProperty: (newPropertyName: string) => ValidationObj;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    build: (context: any) => void;
};

export function validationObj(propertyName: string): ValidationObj {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const isFluentRules = (chainLink: FluentRuleCustomizer<unknown, any> | FluentRules<unknown, any>): chainLink is FluentRules<unknown, any> => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return (chainLink as FluentRules<unknown, any>).displayName !== undefined;
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let currentChain: FluentRules<unknown, any> | FluentRuleCustomizer<unknown, any> = ValidationRules.ensure(propertyName);

    /**
     Add email validation rules to the current property in the chain.
     **/
    const addEmailRules = function(): ValidationObj {
        if (isFluentRules(currentChain)) {
            currentChain = currentChain.displayName('Email').required().matches(/^(\s+)?[\w-.+]+@([\w-]+\.)+[\w-]{1,4}(\s+)?$/);
        } else {
            currentChain = currentChain.required().matches(/^(\s+)?[\w-.+]+@([\w-]+\.)+[\w-]{1,4}(\s+)?$/);
        }

        return this;
    };

    /**
     Add password validation rules to the current property in the chain.
     **/
    const addPasswordRules = function(): ValidationObj {
        currentChain = currentChain.matches(/[a-z]/).minLength(8).maxLength(64).matches(/[0-9]/).matches(/[A-Z]/).matches(/_|\W/);
        return this;
    };

    /**
     Adds a new validation property to the validation chain.
    **/
    const addProperty = function(newPropertyName: string): ValidationObj {
        if (!isFluentRules(currentChain)) {
            currentChain = currentChain.ensure(newPropertyName);
        }

        return this;
    };

    /**
     End the validation chain
     **/
    const build = function(context) {
        if (!isFluentRules(currentChain)) {
            currentChain.on(context);
        }
    };

    return { addEmailRules, addPasswordRules, addProperty, build };
}
