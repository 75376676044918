export class QueryParamsValueConverter {
    toView(value: string) {
        const paramArr = value.slice(value.indexOf('?') + 1).split('&');
        const params = {};

        if (paramArr.length === 0) return params;

        paramArr.map(param => {
            const [key, val] = param.split('=');
            params[key] = decodeURIComponent(val);
        });

        return params;
    }
}
