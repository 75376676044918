import './ds-checkbox-select.scss';
import { autoinject, bindable } from 'aurelia-framework';

@autoinject()
export class DsCheckboxSelect {
    @bindable options;
    @bindable type;
    selectedOptions = ['all'];
    selectLabel = 'All';
    parent;
    active = false;

    attached() {
        if (this.selectedOptions.includes('all')) {
            for (const option of this.options) {
                option.active = true;
            }
        }
    }

    bind(bindingContext) {
        this.parent = bindingContext;
    }

    displayContainer() {
        const container = <HTMLElement>document.querySelector('.select-box-container');
        container.style.display = this.active ? 'none' : 'flex';
        this.active = !this.active;
        this.displayDropdown();
    }

    displayDropdown() {
        const dropdown = <HTMLElement>document.querySelector('.select-box-dropdown');
        const arrow = <HTMLElement>document.querySelector('.select-arrow');
        if (this.active) {
            dropdown.style.border = '1px solid black';
            arrow.style.transform = 'rotate(180deg)';
        }
        else {
            dropdown.style.border = 'none';
            arrow.style.transform = 'rotate(0deg)';
        }
    }

    mouseoverDisplay(event) {
        const container = <HTMLElement>document.querySelector('.select-box-container');

        if (event.type === 'mouseenter') {
            container.style.display = 'flex';
            this.active = true;
            if (window.innerWidth < 770) {
                this.displayContainer();
            }
        }

        if (event.type === 'mouseleave') {
            container.style.display = 'none';
            this.active = false;
        }

        this.displayDropdown();
    }

    handleFiltering(clickedOption) {
        clickedOption.active = !clickedOption.active;

        if (clickedOption.selection === 'all') {
            this.options.forEach(option => option.active = clickedOption.active);
        } else {
            const allOptionIndex = this.options.findIndex(option => option.selection === 'all');
            const othersOptions = this.options.filter(option => option.selection !== 'all');
            const numDiffOptions = othersOptions.filter(option => this.options[allOptionIndex].active !== option.active).length;

            if (numDiffOptions > 0 && numDiffOptions < othersOptions.length) {
                this.options[allOptionIndex].active = false;
            } else {
                this.options[allOptionIndex].active = clickedOption.active;
            }
        }

        const selectedObjects = this.options.filter(option => option.active);

        this.selectedOptions = selectedObjects.map(option => option.selection);

        this.selectLabel = this.selectedOptions.includes('all') ? 'All' : this.selectedOptions.length > 1 ? `${this.selectedOptions[0]} (+${this.selectedOptions.length - 1})` : this.selectedOptions[0];
        this.parent.setActiveOption(selectedObjects);
    }
}
