import { autoinject } from 'aurelia-framework';
import { SessionService } from 'services/session-service';
import { AuthenticationExtension } from 'resources/extensions/sso_extension';

@autoinject()
export class AuthorizeStep extends AuthenticationExtension {
    constructor(sessionService: SessionService) {
        super(null, null, sessionService);
    }

    async run(navigationInstruction, next) {
        const requiresAuth = navigationInstruction.getAllInstructions().some(i => i.config.settings.auth);
        const isLoggedIn = await this.sessionService.isTokenValid();

        if (requiresAuth && !isLoggedIn && !navigationInstruction.queryString.includes('email') && !navigationInstruction.queryString.includes('token')) {
            this.handleAuthRedirection();
            return next.cancel();
        }

        return next();
    }
}
