import './privacy-policy.scss';
import { autoinject } from 'aurelia-framework';
import { PageContentAreaService } from 'services/page-content-area-service';
import { WebsiteService } from 'services/website-service';

@autoinject()
export class PrivacyPolicy {
    constructor(private pageContentAreaService: PageContentAreaService, private websiteService: WebsiteService) { }

    async activate() {
        const page = await this.websiteService.getPage('Privacy Policy');
        await this.pageContentAreaService.getByPageId(page?.id);
    }
}
