import './ds-sticky-post.scss';
import { bindable } from 'aurelia-framework';
import { autoinject } from 'aurelia-dependency-injection';
import { EventAggregator } from 'aurelia-event-aggregator';
import { getAWSBucketEndpoint } from 'environment';

@autoinject()
export class DsStickyPost {
    constructor(private eventAggregator: EventAggregator) {
        this.baseAwsEndpoint = getAWSBucketEndpoint('blogs');
        this.width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    }

    @bindable blogs;
    @bindable title;
    @bindable blogRoute;
    baseAwsEndpoint: string;
    blog;
    parent;
    sizeChanged;
    width;

    bind(bindingContext) {
        this.parent = bindingContext;
    }

    attached() {
        this.width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        this.handleEventSubscriptions();
    }

    detached() {
        this.sizeChanged?.dispose();
    }

    handleEventSubscriptions() {
        this.sizeChanged = this.eventAggregator.subscribe('size-changed', async payload => {
            this.width = payload.width;
        });
    }

    async tagClickHandler(tag) {
        await this.parent.tagClickHandler({
            name: tag,
            slug: tag.toLowerCase().replace(' ', '-'),
            activate: true
        });
    }
}
