import './ds-template-paragraph.scss';
import { bindable } from 'aurelia-framework';

export class DsTemplateParagraph {
    @bindable noLine;
    @bindable smallerParagraph;
    @bindable smallerTitle;
    @bindable cardTitle;
    @bindable titleKey;
    @bindable paragraphKey;
    @bindable centered;
    @bindable wcuCardMb;
    @bindable divisor;
}
