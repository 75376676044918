import { baseUrl, chicksGroupBaseUrl, clientId } from 'environment';
import { inject } from 'aurelia-framework';
import { QueryParamsValueConverter } from 'resources/value-converters/query-params';
import { ToastService } from 'services/toast-service';
import { SessionService } from 'services/session-service';

@inject(
    QueryParamsValueConverter,
    ToastService,
    SessionService
)
export class AuthenticationExtension {
    protected queryParamsValueConverter: QueryParamsValueConverter;
    protected toastService: ToastService;
    protected sessionService: SessionService;

    constructor(
        queryParamsValueConverter: QueryParamsValueConverter,
        toastService: ToastService,
        sessionService: SessionService
    ) {
        this.sessionService = sessionService;
        this.toastService = toastService;
        this.queryParamsValueConverter = queryParamsValueConverter;
    }

    private _generateNonce(length: number) {
        let nonce = '';

        const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

        for (let i = 0; i < length; i++) {
            nonce += possible.charAt(Math.floor(window.crypto.getRandomValues(new Uint32Array(1))[0] / (0xffffffff + 1) * possible.length));
        }

        return nonce;
    }

    private _generateCodeVerifier() {
        const array = new Uint32Array(56);
        window.crypto.getRandomValues(array);
        return this._base64UrlEncode(array);
    }

    private async _generateCodeChallenge(verifier: string) {
        const encoder = new TextEncoder();
        const data = encoder.encode(verifier);
        const digest = await window.crypto.subtle.digest('SHA-256', data);
        return this._base64UrlEncode(new Uint8Array(digest));
    }

    private _base64UrlEncode(buffer: Iterable<number>) {
        return btoa(String.fromCharCode.apply(null, new Uint8Array(buffer))).replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
    }

    private async _doLocalLogin(route: string) {
        const email = prompt('email');
        const password = prompt('password');

        this.sessionService.login({ email, password, skipChecks: true, token: '' }).then(loginResult => {
            this.sessionService.validateAuthorizationCode(loginResult.authorizationCode, clientId()).then(codeResponse => {
                if (!codeResponse?.token) {
                    return;
                }

                this.sessionService.saveToken(codeResponse.token);
                location.reload();
            }).catch(err => {
                console.error(err);
                this.handleAuthRedirection(route);
            });
        }).catch(err => {
            console.error(err);
            this.handleAuthRedirection(route);
        });
    }

    async handleSSO() {
        const query = this.queryParamsValueConverter.toView(window.location.href.replace('#', '?'));
        let handledToken = false;

        try {
            const token = query['token'] as string;
            const nonce = query['nonce'] as string;

            if (!token || !nonce) {
                return;
            }

            const storedNonce = window.localStorage.getItem('nonce');
            const storedVerifier = window.localStorage.getItem('codeVerifier');
            handledToken = true;

            if (storedNonce !== nonce) {
                void this.toastService.showToast('Error', 'Failed to validate sign in request. Please try again.', 'error');
                return;
            }

            const response = await this.sessionService.validateAuthorizationCode(token, clientId(), storedVerifier);

            if (!response?.token) {
                return;
            }

            this.sessionService.saveToken(response.token);
        } catch (e) {
            console.log(e);
        } finally {
            if (handledToken) {
                window.location.href = window.location.href.split('#')[0];
            }
        }
    }

    async handleAuthRedirection(route: string = null, referralLink: string = null, authenticationRoute: string = null) {
        let redirectUrl = window.location.href;

        if (route || route === '') {
            const { protocol, host } = window.location;
            redirectUrl = `${protocol}//${host}/${route}`;
        }

        if (baseUrl().includes('localhost')) {
            const skipChecks = confirm('skip sso?');

            if (skipChecks) {
                await this._doLocalLogin(route);
                return;
            }
        }

        const nonce = this._generateNonce(30);
        const codeVerifier = this._generateCodeVerifier();
        const codeChallenge = await this._generateCodeChallenge(codeVerifier);

        localStorage.setItem('nonce', nonce);
        localStorage.setItem('codeVerifier', codeVerifier);
        let chicksGroupUrl = chicksGroupBaseUrl();

        const params = new URLSearchParams();
        params.append('redirect', redirectUrl);
        params.append('nonce', nonce);
        params.append('codeChallenge', codeChallenge);
        params.append('clientId', clientId());

        if (referralLink) {
            params.append('referralLink', referralLink);
            chicksGroupUrl = `${chicksGroupUrl}/sign-up`;
        } else if (authenticationRoute) {
            chicksGroupUrl = `${chicksGroupUrl}/${authenticationRoute}`;
        }

        const queryString = params.toString();
        window.location.href = `${chicksGroupUrl}?${queryString}`;
    }
}
