import './blogs.scss';
import { autoinject, observable } from 'aurelia-framework';
import { BlogService } from 'services/blog-service';
import { ClearationTimeoutValueConverter } from 'resources/value-converters/clearation-timeout';
import { EventAggregator } from 'aurelia-event-aggregator';
import { WebsiteService } from 'services/website-service';
import { Router } from 'aurelia-router';
import { BlogTagService } from 'services/blog-tag-service';

@autoinject()
export class Blogs {
    blogs;
    blogsCount;
    stickyPost;
    postsCards;
    searchBlogs;
    timeouts;
    changeSearchWatch;
    filteredBlogs;
    count = 1;
    @observable page = 1;
    perPage;
    sizeChanged;
    width;
    isAttached = false;
    startingIndex: number;
    endingIndex;
    options;
    optionSelected: number;
    arrowTriggered;
    arrowRotated: boolean;
    lastEventTriggered: string;
    selectOptions = ['Popular', 'Oldest', 'Newest', 'Alphabetical'];
    tagsOptions = [
        {
            name: 'All',
            active: true,
            slug: 'all'
        },
        {
            name: 'Game Updates',
            active: false,
            slug: 'game-updates'
        },
        {
            name: 'Account Builds',
            active: false,
            slug: 'account-builds'
        },
        {
            name: 'Training Manuals',
            active: false,
            slug: 'training-manuals'
        },
        {
            name: 'Tips & Tricks',
            active: false,
            slug: 'tips-&-tricks'
        },
        {
            name: 'Other',
            active: false,
            slug: 'other'
        },
        {
            name: 'Crypto',
            active: false,
            slug: 'crypto'
        },
        {
            name: 'Trading',
            active: false,
            slug: 'trading'
        },
        {
            name: 'Investments',
            active: false,
            slug: 'investments'
        },
        {
            name: 'Wall Street',
            active: false,
            slug: 'wall-street'
        },
        {
            name: 'NFT',
            active: false,
            slug: 'nft'
        },
        {
            name: 'CSR',
            active: false,
            slug: 'csr'
        }
    ];

    allTagsOptions;
    scrollView;
    pageLoading = true;
    blogsResults = true;
    blogRoute;
    @observable category;
    tagSelected;
    lastBlogs;
    sortBy = 'popular';
    breadcrumbs = [];
    homePageRoute;
    routerSubscription;
    blogsSelector;

    constructor(
        private blogService: BlogService,
        private clearationTimeoutValueConverter : ClearationTimeoutValueConverter,
        private eventAggregator : EventAggregator,
        private websiteService: WebsiteService,
        private router: Router,
        private blogTagService: BlogTagService
    ) {
        this.clearationTimeoutValueConverter = clearationTimeoutValueConverter;
    }

    async activate(params) {
        this.allTagsOptions = await this.blogTagService.getAll();
        this.optionSelected = 0;
        this.options = 'Popular';
        if (params.page) this.page = parseInt(params.page);
        if (params.category) this.category = params.category;
        if (this.category) {
            this.tagSelected = this.findTagOption(this.category);
            this.tagClickHandler(this.tagSelected);
        }
    }

    async attached() {
        const pages = await this.websiteService.getPagesByWebsiteShortcode();
        this.lastBlogs = await this.blogService.getPublishedBlogPosts(3, 1);
        this.blogRoute = pages.find(x => x.name === 'Blog')?.routeName ?? 'blog';
        this.homePageRoute = pages.find(x => x.name === 'Home')?.routeName ?? '';
        this.scrollView = document.querySelector('#main-page-host .simplebar-content-wrapper');
        this.width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        if (this.category && this.category !== 'all') {
            this.breadcrumbs = [
                { title: 'Home', href: this.homePageRoute },
                { title: 'Blogs', href: this.blogRoute },
                { title: this.tagSelected.name }
            ];
        } else {
            this.breadcrumbs = [
                { title: 'Home', href: this.homePageRoute },
                { title: 'Blogs' }
            ];
        }
        this.stickyPost = await this.blogService.getStickyBlogPost();
        if (this.stickyPost) this.stickyPost = [this.stickyPost];
        this.handleEventSubscriptions();
        this.isAttached = true;
        await this.pageByWidth();
        this.pageLoading = false;
        this.overrideMdc();
    }

    overrideMdc() {
        const selectArrow = this.blogsSelector?.querySelector('.mdc-select__dropdown-icon');
        const newArrowIcon = '<img class="global-arrow-icon" src="/icons/arrow-down-2.svg" alt="arrow icon" loading="lazy">';
        if (selectArrow) selectArrow.innerHTML = newArrowIcon;
    }

    detached() {
        this.sizeChanged?.dispose();
        this.routerSubscription?.dispose();
    }

    async searchBlog() {
        if (!this.searchBlogs) {
            return;
        }

        this.timeouts = [this.changeSearchWatch];
        this.clearationTimeoutValueConverter.toView(this.timeouts);
        await this.pageChanged();

        if (this.blogsCount === 0) {
            this.filteredBlogs = this.lastBlogs;
            this.count = this.page = 1;
            if (this.scrollView) {
                this.scrollView.scrollTo({ top: 0, behavior: 'smooth' });
            }
            this.blogsResults = false;
            return;
        }
        this.blogsResults = true;
    }

    keyUpHandler(ev) {
        if (!this.searchBlogs) {
            this.blogsResults = true;
        }
        this.timeouts = [this.changeSearchWatch];
        this.clearationTimeoutValueConverter.toView(this.timeouts);
        if (ev.key === 'Enter') {
            this.searchBlog();
            return;
        }
        this.changeSearchWatch = setTimeout(async () => {
            if (this.searchBlogs) {
                this.searchBlog();
            } else {
                await this.clearSearch();
            }
        }, 1000);
        return true;
    }

    async clearSearch() {
        this.searchBlogs = '';
        await this.pageChanged();
    }

    handleEventSubscriptions() {
        this.sizeChanged = this.eventAggregator.subscribe('size-changed', async payload => {
            this.width = payload.width;
            await this.pageByWidth();
        });

        this.routerSubscription = this.eventAggregator.subscribe('router:navigation:processing', payload => {
            const regExp = new RegExp(`(\\W|^)${this.blogRoute}(\\W|$)`, 'i');
            if (payload.instruction.params?.blog || payload.instruction.params?.alias || !payload.instruction.fragment.match(regExp)) return;
            this.category = payload.instruction.params?.category ?? 'all';
            this.page = payload.instruction.params?.page ? parseInt(payload.instruction.params?.page) : 1;
            if (this.category && this.category !== 'all') {
                this.breadcrumbs = [
                    { title: 'Home', href: this.homePageRoute },
                    { title: 'Blogs', href: this.blogRoute },
                    { title: this.tagSelected.name }
                ];
                return;
            }
            this.breadcrumbs = [
                { title: 'Home', href: this.homePageRoute },
                { title: 'Blogs' }
            ];
        });
    }

    async pageByWidth() {
        if (this.width >= 579 && this.width < 992) {
            this.perPage = 12;
        } else if (this.width < 579) {
            this.perPage = 5;
        } else {
            this.perPage = 9;
        }
        await this.pageChanged();
    }

    async pageChanged(newValue? : number) {
        if (!this.isAttached) return;
        this.blogs = await this.blogService.filterBlogs(
            {
                tag: this.tagSelected && this.tagSelected?.name?.toLowerCase() !== 'all' ? this.tagSelected.name : null,
                content: this.searchBlogs,
                page: this.page,
                pageSize: this.perPage,
                published: true,
                order: this.sortBy
            }
        );

        this.filteredBlogs = this.blogs.blogPosts;
        this.blogsCount = this.blogs.totalCount;
        this.count = Math.ceil(this.blogsCount / this.perPage);
        if (this.page > this.count && this.count > 0) {
            this.page = this.count;
        }
        if (this.scrollView && newValue) {
            this.scrollView.scrollTo({ top: 0, behavior: 'smooth' });
        }
        if (!this.pageLoading) {
            this.urlConstructor(this.category, this.page);
        }
    }

    async categoryChanged(newValue) {
        const newTag = this.findTagOption(newValue);
        await this.tagClickHandler(newTag);
    }

    async changeSelectHandler(ev) {
        this.optionSelected = ev.detail.index;
        this.handleFocusOut();

        switch (ev.detail.value) {
            case 'Newest':
                this.sortBy = 'newest';
                break;

            case 'Oldest':
                this.sortBy = 'oldest';
                break;

            case 'Alphabetical':
                this.sortBy = 'title';
                break;

            case 'Popular':
                this.sortBy = 'popular';
                break;

            default:
                break;

        }
        await this.pageChanged();
    }

    handleFocusOut() {
        if (!this.arrowTriggered) return;
        this.arrowTriggered.style.transform = 'rotate(0deg)';
        this.arrowRotated = false;
        this.lastEventTriggered = 'focusOut';
        setTimeout(() => {
            this.lastEventTriggered = '';
        }, 200);
    }

    async tagClickHandler(tag) {
        if (!tag) return;
        this.tagSelected = tag;
        this.tagsOptions.forEach(tagItem => {
            tagItem.active = tagItem.name.toLowerCase() === tag.name.toLowerCase();
        });
        if (this.tagsOptions.every(x => x.active === false)) this.tagsOptions.find(x => x.name === 'Other').active = true;
        this.category = this.tagSelected.slug;
        await this.pageChanged();
    }

    urlConstructor(category, page) {
        if (category && page > 1 && category !== 'all') {
            this.router.navigateToRoute('blog-category-page', { category: category, page: page });
            return;
        }

        if (category && category !== 'all') {
            this.router.navigateToRoute('blog-category', { category: category });
            return;
        }

        if (page > 1) {
            this.router.navigateToRoute('blog-page', { page: page });
            return;
        }

        this.router.navigateToRoute('blog');
    }

    showTagLabel(label) {
        return !['Crypto', 'Trading', 'Investments', 'Wall Street', 'NFT', 'CSR'].includes(label);
    }

    findTagOption = (value) => this.allTagsOptions.find(tag => tag.slug === value);
}
