import { autoinject } from 'aurelia-framework';
import { ApiService } from './api-service';

@autoinject()
export class BlogAuthorService {
    path = 'BlogAuthor';

    constructor(private api: ApiService) {}

    async getByAlias(alias) {
        return await this.api.doGet(`${this.path}/ByAlias/${alias}`);
    }
}
