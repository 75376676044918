import { autoinject } from 'aurelia-framework';
import { ApiService } from './api-service';
import { Helper } from 'resources/extensions/helper';

@autoinject()
export class UserDocumentService {
    path = 'UserDocument';
    userDocuments;

    constructor(private api: ApiService, private helper: Helper) {}

    getStatus(data, type) {
        if (data.find(x => x.userDocumentVerificationCategoryId === type && !x.userDeleted)?.verified === null) {
            return true;
        } else {
            return false;
        }
    }

    async getUserDocumentsById(id, type) {
        if (!this.userDocuments) {
            this.userDocuments = await this.helper.fetchData(this.api, this.path + '/GetUserDocumentsById/' + id, 'UserDocumentService');
        }
        return this.getStatus(this.userDocuments, type);
    }

    async deleteByUserIdAndCategoryId(userId, categoryId) {
        await this.api.doDelete(this.path + '/DeleteForUserByUserIdAndCategoryId/' + userId + '/' + categoryId);
    }
}
