import { autoinject } from 'aurelia-dependency-injection';
import { ApiService } from './api-service';

@autoinject()
export class ReferralService {
    path = 'ReferralLink';

    constructor(private api: ApiService) {}

    async submitReferralLink(url, linkTypeId) {
        return await this.api.doPost(`${this.path}/submit?referralLinkUrl=${url}&linkTypeId=${linkTypeId}`);
    }
}
