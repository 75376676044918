import './home-card.scss';
import { autoinject, bindable } from 'aurelia-framework';

@autoinject()
export class HomeCard {
    @bindable image;
    @bindable buttonLink;
    @bindable key;
    @bindable alt;
}
