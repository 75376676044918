import { autoinject } from 'aurelia-framework';
import { ApiService } from './api-service';

@autoinject()
export class PriceModificationService {
    path = 'PriceModification';
    priceModifications;

    constructor(private api: ApiService) {}

    async getPriceModifications() {
        if (!this.priceModifications) {
            await this.getAll();
        }
        return this.priceModifications;
    }

    async getAll() {
        this.priceModifications = await this.api.doGet(this.path);
    }
}
