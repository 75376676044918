// Imports
import ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ from "../../../../node_modules/.pnpm/html-loader@3.1.2_webpack@5.94.0_webpack-cli@4.10.0_/node_modules/html-loader/dist/runtime/getUrl.js";
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/icons/checkmark_unfilled.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_1___ = new URL("/static/icons/exclamation-red.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_2___ = new URL("/static/icons/exclamation-yellow.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_3___ = new URL("/static/icons/exclamation-white.svg", import.meta.url);
// Module
var ___HTML_LOADER_REPLACEMENT_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var ___HTML_LOADER_REPLACEMENT_1___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_1___);
var ___HTML_LOADER_REPLACEMENT_2___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_2___);
var ___HTML_LOADER_REPLACEMENT_3___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_3___);
var code = "<template>\n    <div id=\"ds-banner\" if.bind=\"showBanner\" class=\"${state === 'warning' ? 'warning-background' : state === 'error' ? 'error-background' : 'default-background'}\">\n        <div class=\"banner-box text-regular text-white\">\n            <img src=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\" if.bind=\"state === 'success'\" class=\"align-text-top\" alt=\"success\" loading=\"lazy\">\n            <img src=\"" + ___HTML_LOADER_REPLACEMENT_1___ + "\" if.bind=\"state === 'error'\" class=\"align-text-top\" alt=\"error\" loading=\"lazy\">\n            <img src=\"" + ___HTML_LOADER_REPLACEMENT_2___ + "\" if.bind=\"state === 'warning'\" class=\"align-text-top\" alt=\"warning\" loading=\"lazy\">\n            <img src=\"" + ___HTML_LOADER_REPLACEMENT_3___ + "\" if.bind=\"state === 'info'\" class=\"align-text-top\" alt=\"warning\" loading=\"lazy\">\n            <span>\n                ${text}\n                <span if.bind=\"clickFunctionName && clickFunctionName !== ''\" class=\"text-decoration-underline cursor-pointer text-white\" click.delegate=\"clickFunction()\">click here</span>\n                <a if.bind=\"url && url !== ''\"  href=\"${url}\" class=\"text-decoration-underline cursor-pointer text-white\" target=\"_blank\" rel=\"nofollow noopener noreferrer\">click here</a>\n                <span class=\"dot-container\">.</span>\n            </span>\n            <div class=\"text-large cursor-pointer\" click.delegate=\"closeBanner()\">\n                ✕\n            </div>\n        </div>\n    </div>\n</template>\n";
// Exports
export default code;