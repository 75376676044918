import './currency.scss';
import { bindable, autoinject, observable, PLATFORM } from 'aurelia-framework';
import { ProductService } from 'services/product-service';
import { Router } from 'aurelia-router';
import { SessionService } from 'services/session-service';
import { EventAggregator } from 'aurelia-event-aggregator';
import { PageContentAreaService } from 'services/page-content-area-service';
import { ProductCategoryService } from 'services/product-category-service';
import { PriceModificationChecker } from 'resources/value-converters/price-modification-checker';
import { ThousandSeparatorValueConverter } from 'resources/value-converters/thousand-separator';
import { InputNumericValueChecker } from 'resources/value-converters/input-numeric-value-checker';
import { computedFrom } from 'aurelia-framework';
import { ToastService } from 'services/toast-service';
import { WebsiteService } from 'services/website-service';
import { Helper } from 'resources/extensions/helper';
import { CalculateConvertedPriceValueConverter } from 'resources/value-converters/calculate-converted-price';
import { OrderService } from 'services/order-service';

@autoinject()
export class Currency {
    constructor(
        private pageContentAreaService: PageContentAreaService,
        private productCategoryService: ProductCategoryService,
        private router: Router,
        private sessionService: SessionService,
        private eventAggregator: EventAggregator,
        private service: ProductService,
        private notification: ToastService,
        private priceModificationChecker: PriceModificationChecker,
        private thousandSeparatorValueConverter: ThousandSeparatorValueConverter,
        private inputNumericValueChecker: InputNumericValueChecker,
        private websiteService: WebsiteService,
        private helper: Helper,
        private calculateConvertedPriceValueConverter: CalculateConvertedPriceValueConverter,
        private orderService: OrderService) { }

    @bindable quantity;
    @observable product;
    @bindable preferredCurrency;
    @observable character;
    selectedProduct = {};
    addedToCart = false;
    loading = true;
    selectedProductId;
    selectedGame;
    filters;
    tempQuantity;
    longName;
    longPrice;
    availableProducts;
    quantityText;
    appliedFilters = [];
    priceModification;
    navCategory;
    games;
    width;
    routeChangeSubscriber;
    sizeChangedSubscriber;
    quantityElement;
    route;
    routeSlug;
    notificationSent;
    longNameValues;
    currencyPageRoute;
    pages;
    schema;
    routeConfig;
    user;
    routeChangeProcessingSubscriber;
    pageConfig;
    pageContentArea;
    triggeredGameChanged;
    titleKeyContent;
    isDefault;

    async activate(_, routeConfig) {
        this.routeConfig = routeConfig;
        [this.navCategory, this.pages] = await Promise.all([
            this.productCategoryService.getNavCategory('currency', 'DS'),
            this.websiteService.getPagesByWebsiteShortcode()
        ]);
        this.pageConfig = this.pages.find(x => x.name === 'Currency');
        this.games = this.navCategory?.gameForNav;
        this.routeSlug = window.location.pathname.split('/').pop();
        this.selectedGame = this.games?.find((game) => game.slug?.includes(this.routeSlug));

        if (this.selectedGame) {
            this.routeConfig.navModel.setTitle('');
            this.pageContentArea = await this.pageContentAreaService.getByPageIdAndGame(this.pageConfig?.id, `CURRENCY_${this.selectedGame.shortName}`);
        } else {
            this.pageContentArea = await this.pageContentAreaService.getByPageId(this.pageConfig?.id);
        }
    }

    async attached() {
        this.width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        this.currencyPageRoute = this.pages.find(x => x.name.toLowerCase() === 'currency')?.routeName ?? 'currency';
        if (this.selectedGame && this.selectedGame?.slug[0] !== this.routeSlug) {
            const redirectUrl = `/${this.currencyPageRoute}/${this.selectedGame.slug[0]}`;
            this.helper.addPrerenderMetaTagForRedirect(redirectUrl);
            this.router.navigate(redirectUrl);
        }
        await this.filterByRoute();
        this.handleEventSubscriptions();
        this.loading = false;
        this.helper.resizeByNavbar(this, '#currency-app');
        this.user = await this.sessionService.getProfile();
    }

    detached() {
        this.helper.removePrerenderMetaTagForRedirect();
        this.helper.removeGtagEvent();
        this.helper.disposeAllSubscribers(this);
    }

    handleEventSubscriptions() {
        this.routeChangeProcessingSubscriber = this.eventAggregator.subscribe('router:navigation:processing', async(payload) => {
            this.triggeredGameChanged = true;
            if (this.checkForSameGameAndHasPagination(payload, true)) return;
            const newSelectedGame = this.games?.find((game) => game.slug?.includes(payload.instruction?.params?.childRoute));
            if (newSelectedGame) {
                this.eventAggregator.publish('loading-content', { loadingContent: true });
                this.pageContentArea = await this.pageContentAreaService.getByPageIdAndGame(this.pageConfig?.id, `CURRENCY_${newSelectedGame.shortName}`);
                this.eventAggregator.publish('loading-content', { loadingContent: false });
            } else {
                this.pageContentArea = await this.pageContentAreaService.getByPageId(this.pageConfig.id);
            }
        });

        this.routeChangeSubscriber = this.eventAggregator.subscribe('router:navigation:success', async(payload) => {
            if (this.checkForSameGameAndHasPagination(payload) || !this.triggeredGameChanged) return;
            this.addedToCart = false;
            await this.filterByRoute();
            this.getFiltersValues();
        });

        this.sizeChangedSubscriber = this.eventAggregator.subscribe('size-changed', payload => {
            this.width = payload.width;
        });
    }

    async filterByRoute() {
        this.loading = true;
        this.route = this.router?.currentInstruction?.fragment;
        this.product = null;
        this.selectedProductId = null;
        this.filters = [];
        this.selectedGame = this.games?.find((game) => game.slug[0] === this.route);
        if (this.selectedGame) {
            if (this.selectedGame.slug[0] === this.routeSlug) this.helper.removePrerenderMetaTagForRedirect();
            this.routeConfig.navModel.setTitle('');
            this.selectedProductId = this.selectedGame.id;
            this.availableProducts = await this.service.getProductsWithFilter('inStockCurrency', this.selectedProductId);
            this.filters = await this.service.getProductFilters(1, this.selectedProductId);
            this.priceModification = await this.priceModificationChecker.toView(this.selectedGame.id, 1);
            if (this.priceModification) this.product.priceModification = this.priceModification;
            // create a copy of the filter childrens
            if (this.filters) {
                this.filters.forEach(e => {
                    if (e.field.listOfValue) {
                        e.field.listOfValue.copiedChildren = e.field.listOfValue.children;
                        setTimeout(() => {
                            // set default value to root filters
                            if (!e.field.parentId) {
                                e.value = e.field.listOfValue.children[0];
                            }
                        }, 200);
                    }
                });
            }
            await this.updateFilters();
            await this.handleCurrencyFilter();

            if (!this.selectedGame) {
                this.selectedGame = this.games[0];
            }

            this.character = this.sessionService.getGameCharacter(this.product?.game?.shortName);
        } else {
            this.routeConfig.navModel.setTitle(this.pages.find(x => x.name === 'Currency')?.title);
        }
        this.loading = false;
    }

    @computedFrom('selectedGame')
    get currencyType() {
        if (this.selectedGame) {
            switch (this.selectedGame.shortName) {
                case 'POE':
                    return 'Currency';
                case 'FFXIV':
                    return 'Gil';
                case 'NW':
                    return 'Coins';
                case 'EFT':
                    return 'Roubles';
                default:
                    return 'Gold';
            }
        } else {
            return 'Gold';
        }
    }

    @computedFrom('selectedGame')
    get shortName() {
        if (this.selectedGame) {
            return '_' + this.selectedGame.shortName;
        }
        return '_DEFAULT';
    }

    quantityChanged() {
        if (this.quantity > this.product?.maximum) {
            this.quantity = this.product.maximum;
        } else if (this.quantity > this.product?.quantity) {
            this.quantity = this.product.quantity;
        }
        this.tempQuantity = this.quantity?.toString().replaceAll(',', '');
    }

    characterChanged() {
        if (this.character?.length) {
            this.sessionService.saveGameCharacter(this.selectedGame.shortName, this.character);
        }
    }

    blurNumberQuantity() {
        const element = document.getElementById('ds-number-arrows');
        element.style.visibility = null;
        element.style.opacity = null;
        this.tempQuantity = this.quantity;
        this.quantity > 999 ? this.quantityText = true : this.quantityText = false;
        if (this.quantity) {
            this.quantity = this.thousandSeparatorValueConverter.toView(this.quantity);
        }
    }

    focusTextQuantity() {
        this.quantityText = false;
        const element = document.getElementById('ds-number-arrows') as HTMLElement;
        element.style.visibility = 'visible';
        element.style.opacity = String(1);
        this.tempQuantity ? this.quantity = this.tempQuantity : this.quantity;
    }

    focusOutCheckValid() {
        this.tempQuantity = this.quantity.replace(/,/g, '');
        if (this.tempQuantity > this.product.maximum) {
            this.quantity = this.product.maximum;
        }
    }

    async addToCart() {
        if (this.character.indexOf(' ') === 0) {
            this.notification.showToast('Add a name', 'Please add a name to the character field.', 'error');
            this.character = '';
            return;
        }
        const keepShoppingButton = document.getElementById('keep-shopping');
        const checkoutButton = document.getElementById('checkout');
        const checkoutTextButton = document.getElementById('checkout-text');
        keepShoppingButton.classList.remove('fade-in');
        checkoutButton.classList.remove('fade-in');
        checkoutTextButton.classList.remove('type-writer');
        const cartProduct = JSON.parse(JSON.stringify(this.product));
        cartProduct.quantity = this.tempQuantity;
        cartProduct.selectedQuantity = this.tempQuantity;
        cartProduct.character = this.character;
        cartProduct.productId = this.product.id;
        this.sessionService.storeCartItem(cartProduct);
        this.notification.showToast('Product Added!', `Added ${this.product.name} to your cart.`, 'success');
        this.addedToCart = true;
        keepShoppingButton.classList.add('fade-in');
        checkoutButton.classList.add('fade-in');
        checkoutTextButton.classList.add('overflow-hidden');
        checkoutTextButton.classList.add('type-writer');
        setTimeout(() => {
            checkoutTextButton.classList.remove('overflow-hidden');
        }, 500);
    }

    async gameChanged(game) {
        this.appliedFilters = [];
        this.helper.removePrerenderMetaTagForRedirect();
        this.router.navigate(`/${this.currencyPageRoute}/${game.slug[0]}`);
        this.eventAggregator.publish('loading-content', { loadingContent: true });
        this.pageContentArea = await this.pageContentAreaService.getByPageIdAndGame(this.pageConfig?.id, `CURRENCY_${game.shortName}`);
        this.eventAggregator.publish('loading-content', { loadingContent: false });
    }

    async productChanged() {
        this.product?.defaultAmount > 999 ? this.quantityText = true : this.quantityText = false;
        this.quantity = this.product?.defaultAmount;
        this.tempQuantity = this.quantity;
        if (this.quantity && this.selectedGame) {
            this.quantity = this.thousandSeparatorValueConverter.toView(this.product?.defaultAmount);
        }
        await this.handleProductRatingSchemaAndGtagEvents();
    }

    async handleProductRatingSchemaAndGtagEvents() {
        if (this.selectedGame && this.product) {
            this.schema = await this.calculateConvertedPriceValueConverter.toView(this.product);
            this.orderService.handleProductRatingSchema(this.selectedGame, this.navCategory, this.product, this.schema.currency, this.schema.price, true);
            this.helper.handleGtagEvent('view_item', this.product, this.schema.currency, this.schema.price);
        }
    }

    handleFilterChange = async(event, filter, value) => {
        this.notificationSent = false;
        filter.value = value;
        await this.updateFilterChildren(filter, value?.value);
        await this.updateFilters();
        await this.handleCurrencyFilter();

        this.longNameCheck();
        this.changeTooltipText();
    };

    async updateFilterChildren(parentFilter, value) {
        const selectedLOV = parentFilter.field.listOfValue.children.find(e => e.value === value);
        const child = this.filters.find(e => e.field.parentId === parentFilter.field.id);

        if (child) {
            child.field.listOfValue.children = child.field.listOfValue.copiedChildren.filter(e => e.filteredByListOfValueId === selectedLOV?.id);

            await new Promise<void>((resolve) => {
                setTimeout(() => {
                    child.value = child.field.listOfValue.children[0];

                    this.updateFilterChildren(child, child.value?.value);
                    resolve();
                }, 100);
            });
        }
        await new Promise<void>((resolve) => { setTimeout(() => { resolve(); }, 100); });
    }

    async updateFilters() {
        this.filters?.forEach(f => {
            const foundIndex = this.appliedFilters.findIndex(x => x.parentId === f.field.id);
            if (foundIndex >= 0) {
                this.appliedFilters.splice(foundIndex, 1);
            }
            this.appliedFilters.push({ parentId: f.field?.id, value: f.value });
        });
    }

    async handleCurrencyFilter() {
        if (this.appliedFilters && this.availableProducts) {
            let filteredProducts = [...this.availableProducts];
            for (const f of this.appliedFilters) {
                if (f.value) {
                    filteredProducts = filteredProducts.filter(x => x.productFields.some(y => y.value === f.value.value && y.fieldId === f.parentId));
                }
            }
            this.product = filteredProducts.length >= 1 ? filteredProducts[0] : undefined;
        } else {
            this.product = this.availableProducts.length >= 1 ? this.availableProducts[0] : undefined;
        }
        if (!this.product && !this.notificationSent) {
            this.notificationSent = true;
            this.notification.showToast('Product not available', 'We are not currently selling this product. Please let us know in the live chat that you are interested in this product.', 'info');
        }
    }

    longNameCheck() {
        if (this.filters?.length > 0) {
            this.longNameValues = '';
            for (const filter of this.filters) {
                const display = filter.field.listOfValue.children.find(e => e === filter.value)?.display;
                if (display) {
                    this.longNameValues += display + ' ';
                }
            }
            if (this.longNameValues.length > 22) {
                this.longName = true;
            } else {
                this.longName = false;
            }
            return this.longNameValues;
        }
    }

    getFiltersValues() {
        return this.longNameCheck();
    }

    changeTooltipText() {
        const element = document.querySelectorAll('.mdc-tooltip');
        const array = Array.from(element);
        array.shift();
        array.forEach((el) => {
            const tooltip = el.querySelector('div.mdc-tooltip__surface');
            setTimeout(() => {
                tooltip.innerHTML = this.getFiltersValues();
            }, 500);
        });
    }

    changeGameTooltipText() {
        const element = document.querySelectorAll('.mdc-tooltip');
        const array = Array.from(element);
        const tooltip = array[0].querySelector('div.mdc-tooltip__surface');
        setTimeout(() => {
            tooltip.innerHTML = this.selectedGame?.name;
        }, 500);
    }

    checkForSameGameAndHasPagination = (router, checkGame = false) => {
        const newSelectedGame = this.games?.find((game) => game.slug?.includes(router.instruction?.params?.childRoute));
        this.isDefault = !newSelectedGame;
        return Boolean(router.instruction.params.page || (checkGame && this.selectedGame === newSelectedGame));
    };

    async configureRouter(config, router) {
        this.navCategory = await this.productCategoryService.getNavCategory('currency', 'DS');
        const gamesForRoute = this.navCategory?.gameForNav;
        config.options.pushState = true;
        this.router = router;
        config.map([
            {
                route: ['', 'buy-gold'],
                name: 'buy-gold',
                moduleId: PLATFORM.moduleName('pages/currency/buy-gold/buy-gold'),
                title: 'Buy Game Currency - Cheap Game Currency',
            },
            ...gamesForRoute.map(game => {
                return {
                    route: game.slug,
                    name: game.slug[0],
                    moduleId: PLATFORM.moduleName('pages/currency/currency-game-content/currency-game-content'),
                    title: game.title,
                    settings: {
                        metaDescription: game.metaDescription,
                        keyName: `CURRENCY_${game.shortName}`
                    }
                };
            })
        ]);
        config.mapUnknownRoutes(() => {
            this.router.navigateToRoute('404');
        });
    }
}
