import './ds-template-paragraph-image.scss';
import { bindable } from 'aurelia-framework';

export class DsTemplateParagraphImage {
    @bindable inverted;
    @bindable titleKey;
    @bindable paragraphKey;
    @bindable imageKey;
    @bindable divisor;
}
