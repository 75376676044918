import './ds-line.scss';
import { bindable } from 'aurelia-framework';

export class DsLine {
    @bindable width;
    @bindable height;
    @bindable alignment;
    @bindable class;
    @bindable color;

    getStyles(width, height = 4) {
        return `width: ${width ? this.getPxOrValue(width) : '60px'};
        height: ${height ? this.getPxOrValue(height) : '4px'}
        `;
    }

    getPxOrValue = (value) => !isNaN(value) ? value + 'px' : value;

    getAlignmentClass() {
        if (this.alignment === 'start') {
            return '';
        }
        if (this.alignment === 'center') {
            return 'm-auto';
        }
        if (this.alignment === 'end') {
            return 'float-right';
        }
    }

    getColorClass() {
        if (this.color === 'purple') {
            return 'purple-line';
        } else if (this.color === 'green') {
            return 'green-line';
        } else if (this.color === 'light-blue') {
            return 'blue-line';
        }
    }
}
