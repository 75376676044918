import './ds-customer-portal-navigation.scss';
import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { SessionService } from 'services/session-service';
import { EventAggregator } from 'aurelia-event-aggregator';
import { SiteSettingService } from 'services/site-setting-service';
import { WebsiteService } from 'services/website-service';

@autoinject()
export class DsCustomerPortalNavigation {
    siteSettingsKeys;
    user;
    routeChangeSubscription;
    userSubscription;
    phoneSubscriber;
    routeSubscription;
    verificationSubscription;
    verificationProgressTotal;
    verificationProgressCurrent;
    currentRoute;
    customerPortalRoute;

    options = [
        {
            option: 'profile',
            icon: 'account_circle',
            isActive: false,
            id: 'profile-link'
        },
        {
            option: 'security',
            icon: 'lock',
            isActive: false,
            id: 'security-link'
        },
        {
            option: 'verification',
            icon: 'verified_user',
            isActive: false,
            id: 'security-link'
        },
        {
            option: 'support',
            icon: 'help',
            isActive: false,
            id: 'security-link'
        },
        {
            option: 'orders',
            icon: 'receipt',
            isActive: false,
            id: 'security-link'
        },
    ];

    constructor(
        private router: Router,
        private sessionService: SessionService,
        private eventAggregator: EventAggregator,
        private siteSettingService: SiteSettingService,
        private websiteService: WebsiteService) { }

    async attached() {
        this.user = await this.sessionService.getProfile();
        const pages = await this.websiteService.getPagesByWebsiteShortcode();
        this.customerPortalRoute = pages.find(x => x.name === 'Customer Portal')?.routeName ?? 'customer-portal';
        if (!this.user) {
            const homePageRoute = pages.find(x => x.name === 'Home')?.routeName ?? '';
            this.router.navigate(this.router.currentInstruction.queryParams.email && this.router.currentInstruction.queryParams.token ? `${homePageRoute}/?email=${this.router.currentInstruction.queryParams.email}&token=${this.router.currentInstruction.queryParams.token}` : homePageRoute);
            return;
        }
        this.checkActive();
        [this.user.emailInReview, this.user.idVerificationInReview, this.user.addressVerificationInReview, this.user.selfieVerificationInReview] = await Promise.all([
            this.sessionService.getEmailInReview(),
            this.sessionService.getIdVerificationInReview(this.user.id),
            this.sessionService.getAddressVerificationInReview(this.user.id),
            this.sessionService.getSelfieVerificationInReview(this.user.id)
        ]);
        const { addressVerified, emailConfirmed, idVerified, phoneNumberConfirmed, selfieVerified } = this.user;
        this.verificationProgressTotal = [ addressVerified, emailConfirmed, idVerified, phoneNumberConfirmed, selfieVerified ];
        this.verificationProgressCurrent = this.verificationProgressTotal.filter((el) => el);
        this.handleEventSubscriptions();
    }

    detached() {
        this.routeChangeSubscription?.dispose();
        this.userSubscription?.dispose();
        this.verificationSubscription?.dispose();
        this.phoneSubscriber?.dispose();
        this.routeChangeSubscription?.dispose();
    }

    handleEventSubscriptions() {
        this.routeSubscription = this.eventAggregator.subscribe('route-updated', payload => {
            setTimeout(() => {
                this.markActive(this.options.find(item => item.option === payload.option));
                this.checkActive();
            }, 250);
        });

        this.userSubscription = this.eventAggregator.subscribe('user-updated', payload => {
            this.user = payload.user;
        });

        this.phoneSubscriber = this.eventAggregator.subscribe('phone-updated', payload => {
            if (payload.successful) {
                this.user.phoneNumberConfirmed = true;
            }
        });

        this.routeChangeSubscription = this.eventAggregator.subscribe('router:navigation:success', () => {
            this.checkActive();
        });

        this.verificationSubscription = this.eventAggregator.subscribe('verification-updated', payload => {
            if (payload.verification.idVerificationInReview !== undefined) {
                this.user.idVerificationInReview = payload.verification.idVerificationInReview;
                this.sessionService.deleteByUserIdAndCategoryId(this.user.id, 1);
            }

            if (payload.verification.addressVerificationInReview !== undefined) {
                this.user.addressVerificationInReview = payload.verification.addressVerificationInReview;
                this.sessionService.deleteByUserIdAndCategoryId(this.user.id, 2);
            }

            if (payload.verification.selfieVerificationInReview !== undefined) {
                this.user.selfieVerificationInReview = payload.verification.selfieVerificationInReview;
                this.sessionService.deleteByUserIdAndCategoryId(this.user.id, 3);
            }
        });
    }

    checkActive() {
        for (const option of this.options) {
            option.isActive = false;
            if (option.option.includes(this.router?.currentInstruction?.config?.name) || (option.option === 'orders' && this.router?.currentInstruction?.config?.name === 'order-details')) {
                option.isActive = true;
            }
        }
    }

    markActive(option) {
        for (const defaultOption of this.options) {
            defaultOption.isActive = defaultOption.option === option.option;
        }
    }

    handleActiveOption(event) {
        const icon = event.target.querySelector('.nav-icon');
        if (icon) {
            icon.classList.remove('material-icons-outlined');
            icon.classList.add('material-icons');
        }
    }

    handleRemovingActiveOption(event) {
        const icon = event.target.querySelector('.nav-icon');
        if (icon) {
            icon.classList.remove('material-icons');
            icon.classList.add('material-icons-outlined');
        }
    }
}
