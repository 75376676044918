import { autoinject } from 'aurelia-framework';
import { ApiService } from './api-service';

@autoinject()
export class DeliveryMethodService {
    constructor(private api: ApiService) { }

    deliveryMethods;
    tradeDeliveryMethod;

    async getAllDeliveryMethods() {
        if (this.deliveryMethods) return this.deliveryMethods;
        this.deliveryMethods = await this.api.doGet('DeliveryMethod');
        return this.deliveryMethods;
    }

    async getTradeDeliveryMethod() {
        if (this.tradeDeliveryMethod) return this.tradeDeliveryMethod;
        this.tradeDeliveryMethod = await this.api.doGet('DeliveryMethod/TradeDeliveryMethod');
        return this.tradeDeliveryMethod;
    }
}
