import { autoinject } from 'aurelia-dependency-injection';
import { kountUrl, checkoutRiskBaseUrl } from 'environment';
import { Helper } from 'resources/extensions/helper';

@autoinject()
export class ScriptService {
    constructor(private helper: Helper) { }

    injectPaysafeScript() {
        this.helper.injectScript('paysafe-script', 'https://hosted.paysafe.com/js/v1/latest/paysafe.min.js');
    }

    injectPaysafe3DSScript() {
        this.helper.injectScript('paysafe-3ds-script', 'https://hosted.paysafe.com/threedsecure/js/latest/paysafe.threedsecure.min.js');
    }

    injectCheckoutScript() {
        this.helper.injectScript('checkout-script', 'https://cdn.checkout.com/js/framesv2.min.js');
    }

    injectCheckoutRiskScript() {
        this.helper.injectScript('checkout-risk-script', checkoutRiskBaseUrl(), true);
    }

    injectIntercomScript() {
        this.helper.injectScript('intercom-script', 'https://widget.intercom.io/widget/dqgl5no3', true);
    }

    injectGoogleScript() {
        this.helper.injectScript('google-script', 'https://maps.googleapis.com/maps/api/js?key=AIzaSyBwRB5w56D8uTYXbNythSvbzJykWFqhgQg&loading=async&callback=Function.prototype&libraries=places');
    }

    injectGooglePayScript() {
        this.helper.injectScript('google-pay-script', 'https://pay.google.com/gp/p/js/pay.js', true);
    }

    injectKountScript() {
        this.helper.injectScript('kount-script', `${kountUrl()}collect/sdk?m=700000`);
    }

    injectGoogleSignInScript() {
        this.helper.injectScript('google-sign-in-script', 'https://accounts.google.com/gsi/client');
    }

    injectApplePayScript() {
        this.helper.injectScript('apple-pay-script', 'https://applepay.cdn-apple.com/jsapi/v1.1.0/apple-pay-sdk.js', true, true);
    }

    injectAppleSignInScript() {
        this.helper.injectScript('apple-sign-in-script', 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js');
    }

    initiateKountFraudScript() {
        if (window.ka) {
            const client = new window.ka.ClientSDK();
            client.setupCallback(
                {
                    'collect-end':
                        (params) => {
                            window.fraudSessionId = params?.MercSessId;
                        },
                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                    'collect-begin': () => {}
                });
            client.autoLoadEvents();
        } else {
            setTimeout(() => {
                this.initiateKountFraudScript();
            }, 500);
        }
    }
}
