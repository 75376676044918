import './ds-dynamic-icon.scss';
import { bindable, autoinject } from 'aurelia-framework';

@autoinject
export class DsDynamicIcon {
    constructor(private element : Element) {}

    @bindable icon;
    @bindable alt;
    @bindable size = 24;
    @bindable color = '#543bef';
    @bindable outlined = false;
    @bindable iconClass;

    attached() {
        this.setSize();
    }

    setSize() {
        this.setProperty('--size', this.size + 'px');
        this.setProperty('--color', this.color);
    }

    setProperty = (property, value) => (this.element as HTMLElement)?.style.setProperty(property, value);

    colorChanged() {
        this.setProperty('--color', this.color);
    }
}

