export class FileListToArrayValueConverter {
    toView(fileList) {
        const files = [];
        if (!fileList) {
            return files;
        }
        for (const file of fileList) {
            files.push(file);
        }
        return files;
    }
}
