import './google-autocomplete-places-input.scss';
import { Billing } from 'services/models/purchase-flow/billing';
import { autoinject, bindable } from 'aurelia-framework';
import { validateTrigger, ValidationController, ValidationRules } from 'aurelia-validation';
import { ValidationRenderer } from 'resources/validation-renderer';
import { getUSStates, getCanadaProvinces } from 'resources/constants';
import { EventAggregator } from 'aurelia-event-aggregator';
import { countries } from 'environment';
import { postcodeValidator, postcodeValidatorExistsForCountry } from 'postcode-validator';
import { SimplebarOverride } from 'resources/simplebar_override';

@autoinject()
export class GoogleAutocompletePlacesInput {
    validator;
    postcodeValidator;
    postcodeValidatorExistsForCountry;
    parent;
    countries;
    billingArrow: HTMLElement;
    countrySelector;
    stateSelector;
    billingAddressAutocomplete;
    manualBillingAddress;
    googleMapsPlace;
    constructor(private validationController : ValidationController, private eventAggregator: EventAggregator) {
        this.validator = validationController;
        this.validator.addRenderer(new ValidationRenderer());
        this.validator.validateTrigger = validateTrigger.manual;
        this.postcodeValidator = postcodeValidator;
        this.postcodeValidatorExistsForCountry = postcodeValidatorExistsForCountry;
    }

    async bind(bindingContext) {
        this.parent = bindingContext;
        if (!this.checkIfBilling()) {
            this.showPlacesInput = true;
        } else if (this.checkIfBilling() && !this.emptySavedCards) {
            this.validator.reset();
            this.zipUpdatedOnKeyPress();
        }
    }

    @bindable showFields;
    @bindable selectedPaymentMethod;
    @bindable showGreenCheckMark;
    @bindable showErrorCheckMark;
    @bindable emptySavedCards;
    @bindable readyingForm;
    @bindable customerFullNameStyleChange;
    @bindable billing: Billing = {
        street: '',
        country: '',
        city: '',
        state: '',
        zip: ''
    };

    usStates = getUSStates();
    caProvinces = getCanadaProvinces();
    billingAddressInput: HTMLInputElement;
    autocompleteBillingAddress;
    showPlacesInput = false;

    created() {
        this.countries = countries();
        this.eventAggregator.publish('recalculate-cart', null);
    }

    async updateBillingAddress() {
        try {
            this.reinstanceScrollbars();
            this.showFields = !this.showFields;
            if (this.showFields) {
                this.loadGooglePlacesInput();
                this.billingArrow.style.transform = 'rotate(180deg)';
            } else {
                this.validator.reset();
                this.billingArrow.style.transform = 'rotate(0deg)';
                this.checkBillingAddressAutocompleteIfEmpty();
            }
        } catch (e) {
            console.log(new Error(e));
        }
    }

    attached() {
        if (this.showPlacesInput) {
            this.loadGooglePlacesInput();
        }

        this.reinstanceScrollbars();
        this.overrideMdc();
    }

    reinstanceScrollbars() {
        const scrollbars = document.querySelectorAll('[id*="scrollbar"]');
        scrollbars.forEach((scrollbar: HTMLElement) => {
            const instance = SimplebarOverride.instances.get(scrollbar);
            if (instance) {
                instance.unMount();
            }
            new SimplebarOverride(scrollbar);
        });
        const selectors = document.querySelectorAll('.google-selector:not(.select-input)');
        selectors.forEach((el) => {
            const dropdown = <HTMLElement>el.querySelector('span.mdc-select__dropdown-icon');
            dropdown.style.display = null;
        });
    }

    overrideMdc() {
        this.billing = this.billing ? this.billing : {
            street: '',
            country: '',
            city: '',
            state: '',
            zip: ''
        };
        const selectArrow = this.countrySelector.querySelector('.mdc-select .mdc-select__dropdown-icon');
        let selectStateArrow;
        const newArrowIcon = '<span class="material-icons text-gray expand-icon"> arrow_drop_down </span>';
        selectArrow.innerHTML = newArrowIcon;
        this.billing.country === 'CA' || this.billing.country === 'US' ? setTimeout(() => {
            selectStateArrow = this.stateSelector.querySelector('.mdc-select .mdc-select__dropdown-icon');
            if (!selectArrow) return;
            selectStateArrow.innerHTML = newArrowIcon;
        }, 100) : '';
    }

    emptySavedCardsChanged() {
        if (this.emptySavedCards) {
            this.billing = {
                street: '',
                country: '',
                city: '',
                state: '',
                zip: ''
            };
            this.showGreenCheckMark = this.showErrorCheckMark = false;
            this.showFields = false;
            this.billingArrow.style.transform = 'rotate(0deg)';
        }
    }

    async billingChanged() {
        if (!this.showFields && !this.checkIfBilling()) {
            this.showPlacesInput = true;
            this.loadGooglePlacesInput();
        } else if (this.checkIfBilling() && !this.emptySavedCards) {
            this.zipUpdatedOnKeyPress();
        }
    }

    checkIfBilling() {
        for (const attribute in this.billing) {
            if ((this.billing[attribute] === null || typeof this.billing[attribute] === 'undefined') && attribute !== 'state') {
                return false;
            }
        }
        return true;
    }

    async loadGooglePlacesInput() {
        try {
            this.billingAddressAutocomplete = new google.maps.places.Autocomplete(this.billingAddressInput.querySelector('input'), {});
            setTimeout(() => {
                const googlePlaces = document.querySelectorAll('.pac-container');
                const googleAutocompleteDiv = document.getElementById('google-autocomplete-places');
                googlePlaces.forEach((el) => {
                    googleAutocompleteDiv.replaceChildren(el);
                });
            }, 2000);
            google.maps.event.addListener(this.billingAddressAutocomplete, 'place_changed', () => {
                const addressData = this.billingAddressAutocomplete.getPlace();
                this.billing.city = addressData.address_components.find(x => x.types.find(y => y === 'locality'))?.long_name ?? addressData.address_components.find(x => x.types.find(y => y === 'administrative_area_level_1'))?.long_name;
                this.billing.state = addressData.address_components.find(x => x.types.find(y => y === 'administrative_area_level_1'))?.short_name;
                this.billing.country = addressData.address_components.find(x => x.types.find(y => y === 'country'))?.short_name;
                this.billing.zip = addressData.address_components.find(x => x.types.find(y => y === 'postal_code'))?.short_name;
                this.billing.street = addressData.name;
                this.showFields = true;
                this.zipUpdatedOnKeyPress();
                if (this.customerFullNameStyleChange) {
                    this.customerFullNameStyleChange();
                }
            });

            if (this.showFields) {
                this.zipUpdatedOnKeyPress();
            }
        } catch (e) {
            console.log(e);
        }
    }

    countrySelect(event) {
        this.billing.country = event.detail.value;
        this.billing.country === 'CA' || this.billing.country === 'US' ? this.overrideMdc() : '';
        if (this.showGreenCheckMark || this.showErrorCheckMark) {
            this.zipUpdatedOnKeyPress();
            const selectors = document.querySelectorAll('.google-selector');
            selectors.forEach((el) => {
                const dropdown = <HTMLElement>el.querySelector('span.mdc-select__dropdown-icon');
                dropdown.style.display = 'none';
            });
        }
        this.eventAggregator.publish('recalculate-cart', null);
        setTimeout(() => {
            this.reinstanceScrollbars();
        }, 1000);
    }

    provinceSelect(event) {
        this.billing.state = event.detail.value;
        this.eventAggregator.publish('recalculate-cart', null);
    }

    stateSelect(event) {
        this.billing.state = event.detail.value;
    }

    async handleCountryAutoComplete(event) {
        this.manualBillingAddress = new google.maps.places.AutocompleteService();
        this.manualBillingAddress.getPlacePredictions({
            input: event?.target?.value,
            fields: ['address_components'] },
        (placePrediction) => {
            if (placePrediction) {
                this.googleMapsPlace = new google.maps.places.PlacesService(document.createElement('div'));
                this.googleMapsPlace.getDetails({ placeId: placePrediction[0].place_id }, (placeDetails) => {
                    this.billing.country = placeDetails.address_components.find(x => x.types.find(y => y === 'country'))?.short_name ?? null;
                });
            }
        });
    }

    checkBillingAddressAutocompleteIfEmpty() {
        if (this.billingAddressInput.querySelector('input').value === '') {
            this.showGreenCheckMark = false;
            this.showErrorCheckMark = false;
        }
    }

    async zipUpdatedOnKeyPress(event?) {
        try {
            const addressAutofill = <HTMLInputElement>document.querySelector('#billing-street input:-webkit-autofill');
            if (event && !event.keyCode) {
                await this.handleCountryAutoComplete(event);
            }
            if (addressAutofill) {
                setTimeout(async() => {
                    await this.handleBillingValidation();
                }, 100);
            } else {
                await this.handleBillingValidation();
            }
        } catch (e) {
            console.log(new Error(e));
        }
    }

    async handleBillingValidation() {
        if (this.billing) {
            if (this.billing.zip !== undefined && this.billing.zip !== null) {
                ValidationRules
                    .ensure('city').required()
                    .ensure('street').required()
                    .ensure('zip').required()
                    .satisfies((zip) => {
                        if (this.billing.country && !this.postcodeValidatorExistsForCountry(this.billing.country) || zip && this.billing.country && this.postcodeValidator(zip, this.billing.country) === true) {
                            return true;
                        } else {
                            return false;
                        }
                    }).withMessage(`Zip code must match the country${this.billing.country ? ' ' + this.billing.country + '.' : '.'}`)
                    .on(this.billing);
                const rules = await this.validator.validate();
                this.showGreenCheckMark = rules.valid;
                this.showErrorCheckMark = !rules.valid;
                if (!this.autocompleteBillingAddress) {
                    this.autocompleteBillingAddress = this.billing.street;
                }
                const selectors = document.querySelectorAll('.google-selector');
                selectors.forEach((el) => {
                    const dropdown = <HTMLElement>el.querySelector('span.mdc-select__dropdown-icon');
                    dropdown.style.display = 'none';
                });
                if (this.customerFullNameStyleChange) {
                    this.customerFullNameStyleChange(this.showGreenCheckMark);
                }
            }
        }
    }
}
