import { autoinject } from 'aurelia-framework';
import { ApiService } from './api-service';
import { websiteShortCode } from 'environment';

@autoinject()
export class ProductService {
    path = 'Product';

    productGameCurrencies;

    constructor(private api: ApiService) {}

    async getProductById(id) {
        return await this.api.doGet(`${this.path}/${id}`, { websiteShortCode: websiteShortCode() });
    }

    async getProductsByIds(ids) {
        return await this.api.doGet(`${this.path}/Ids`, { ids: ids });
    }

    async getProductsWithFilter(filter, gameId) {
        return await this.api.doGet(this.path, { filter, gameId });
    }

    async getStockProducts(filter, gameId) {
        return await this.api.doGet(this.path, { websiteShortCode: websiteShortCode(), filter, gameId });
    }

    async getProductFilters(categoryId, gameId, isDescription = false) {
        return await this.api.doGet(`ProductCategory/${categoryId}/game/${gameId}/isDescription/${isDescription}/fields`);
    }

    async getAllUniqueSkillsForGame(gameShortName, gameModeId) {
        return await this.api.doGet(`${this.path}/AllUniqueSkillsForGame?gameShortName=${gameShortName}&gameModeId=${gameModeId}`);
    }

    async getProductGameForPaymentMethodCurrencies(gameIds) {
        if (this.productGameCurrencies) {
            return this.productGameCurrencies;
        }
        this.productGameCurrencies = await this.api.doPost(`${this.path}/GetProductGameForPaymentMethodCurrencies`, gameIds);
        return this.productGameCurrencies;
    }
}
