import './ds-accordion-menu.scss';
import { SessionService } from 'services/session-service';
import { bindable } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Router } from 'aurelia-router';
import { autoinject } from 'aurelia-framework';
import { AureliaNavigationOptions } from 'services/models/options';
import { WebsiteService } from 'services/website-service';
import { PaymentMethodWebsite } from 'services/models/purchase-flow/paymentMethodWebsite';
import { Currency } from 'services/models/purchase-flow/exchange';
import { ToastService } from 'services/toast-service';

@autoinject()
export class DsAccordionMenu {
    @bindable clickHandler;
    @bindable navigationItems;
    menuElement;
    drawer;
    accordionMenu;
    preferredCurrency;
    currencyOptions: Currency[];
    notification;
    forceCurrencySubscriber;
    sizeChanged;
    width: number;
    currencySubmenu;
    availableCurrencies;
    paymentMethodSelected: string;
    paymentMethodSelectedObject: PaymentMethodWebsite;
    pages;

    constructor(private sessionService: SessionService,
        private eventAggregator: EventAggregator,
        private router: Router,
        private websiteService: WebsiteService,
        private toastService: ToastService
    ) {}

    async attached() {
        this.overrideMdc();
        try {
            this.currencyOptions = await this.sessionService.getCurrencyOptions();
            this.pages = await this.websiteService.getPagesByWebsiteShortcode();
            this.width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
            const currentCurrency = await this.sessionService.getCurrency();
            if (this.currencyOptions) {
                this.preferredCurrency = this.currencyOptions.find(currency => currency.value === currentCurrency);
            }
            if (!this.preferredCurrency) {
                this.sessionService.saveCurrency('USD');
                this.preferredCurrency = this.currencyOptions[0];
            }
        } catch (e) {
            console.log(e);
        }
        this.setCurrency(this.preferredCurrency, null, false);
        this.handleEventSubscriptions();
    }

    detached() {
        this.forceCurrencySubscriber?.dispose();
        this.sizeChanged?.dispose();
    }

    handleEventSubscriptions() {
        this.forceCurrencySubscriber = this.eventAggregator.subscribe('force-currency', payload => {
            if (payload.currentPaymentMethodSelected !== null) {
                this.paymentMethodSelected = payload.currentPaymentMethodSelected.reference;
                this.paymentMethodSelectedObject = payload.currentPaymentMethodSelected;
                const currencyOption = this.sessionService.getDesiredCurrency(payload.currency);
                this.setCurrency(currencyOption, payload.oldCurrency, false);
            } else {
                this.paymentMethodSelected = payload.currentPaymentMethodSelected.reference ?? null;
            }
        });

        this.sizeChanged = this.eventAggregator.subscribe('size-changed', async(payload) => {
            if (this.width === payload.width) return;
            this.width = payload.width;
        });
    }

    async setCurrency(currency: Currency, oldCurrency: Currency | string, fromCurrencyDropdown: boolean) {
        this.handleDrawerNavigationClick();
        this.currencySubmenu.open = false;
        this.openCurrency();
        let correctCurrency = '';
        oldCurrency && typeof oldCurrency === 'object' ? oldCurrency = oldCurrency.value : oldCurrency;
        if (this.paymentMethodSelectedObject && (this.router.currentInstruction.config.route.includes('cart') || this.router.currentInstruction.fragment === `/${this.pages.find(x => x.name === 'Customer Portal')?.routeName ?? 'customer-portal'}/balance`) && this.paymentMethodSelectedObject.supportedCurrencies?.length) {
            const currencies = this.paymentMethodSelectedObject.supportedCurrencies.map(c => this.currencyOptions.find(ac => ac.id === c.currencyId)?.value);
            if (!currencies.includes(oldCurrency as string)) {
                currency = this.sessionService.getDesiredCurrency(currencies[0]);
                correctCurrency = currencies.join(', ');
            }
            if (correctCurrency !== '' && this.width < 1200) {
                await this.toastService.showToast('Info', `${this.paymentMethodSelectedObject.paymentMethod.name} can only be paid in ${correctCurrency}. We've changed the checkout currency to reflect your payment method selection.`, 'info');
            }
        }
        oldCurrency = this.sessionService.getDesiredCurrency(oldCurrency);
        this.preferredCurrency = (fromCurrencyDropdown && correctCurrency === '') || !currency ? oldCurrency : currency;
        await this.sessionService.saveCurrency(this.preferredCurrency?.value);
        if (oldCurrency && correctCurrency === '') {
            await this.sessionService.savePreviousCurrency(oldCurrency as string);
        }
        this.availableCurrencies = [...this.currencyOptions];
        const selectedOptionIndex = this.currencyOptions.findIndex(selectedCurrency => selectedCurrency.value === this.preferredCurrency.value);
        if (selectedOptionIndex >= 0) {
            this.availableCurrencies.splice(selectedOptionIndex, 1);
        }
    }

    overrideMdc() {
        const selectArrows = this.accordionMenu.querySelectorAll('.mdc-expandable__header:not(.expandable-nested) .mdc-expandable__dropdown-icon');
        if (selectArrows) {
            selectArrows.forEach((el) => {
                el.innerHTML = '<img class="faq-arrow-icon" src="/faq/arrow-white.svg" alt="arrow icon" loading="lazy">';
            });
        }
    }

    openCurrency() {
        const drawerContainer = document.querySelector('.drawer');
        this.currencySubmenu.open ? drawerContainer.classList.add('h-100') : drawerContainer.classList.remove('h-100');
    }

    navigateLink(link) {
        this.handleDrawerNavigationClick();
        this.route(link, null);
    }

    handleDrawerNavigationClick() {
        const drawerContainer = document.querySelector('.drawer');
        drawerContainer.classList.remove('mdc-drawer--open');
    }

    route(route, param) {
        const options: AureliaNavigationOptions = { id: param };
        if (param) {
            this.router.navigate(route + '/' + param, options);
            return;
        }
        this.router.navigate(route);
    }

}
