// Imports
import ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ from "../../../../node_modules/.pnpm/html-loader@3.1.2_webpack@5.94.0_webpack-cli@4.10.0_/node_modules/html-loader/dist/runtime/getUrl.js";
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/currency/sword-small.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_1___ = new URL("/static/currency/armor-small.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_2___ = new URL("/static/currency/forge-small.svg", import.meta.url);
// Module
var ___HTML_LOADER_REPLACEMENT_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var ___HTML_LOADER_REPLACEMENT_1___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_1___);
var ___HTML_LOADER_REPLACEMENT_2___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_2___);
var code = "<template>\n    <div id=\"ds-item-category-banner\" class=\"shadow\">\n        <div>\n            <div class=\"image-container\">\n                <img src=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\" alt=\"Sword\" loading=\"lazy\">\n            </div>\n            \n            <ds-template-paragraph \n                no-line=true \n                smaller-title=true \n                title-key.bind=\"leftTitle\" \n                paragraph-key.bind=\"leftText\"\n            ></ds-template-paragraph>\n        </div>\n        <div>\n            <div class=\"image-container\">\n                <img class=\"armor-margin\" src=\"" + ___HTML_LOADER_REPLACEMENT_1___ + "\" alt=\"Armor\" loading=\"lazy\">\n            </div>\n            \n            <ds-template-paragraph \n                no-line=true \n                smaller-title=true \n                title-key.bind=\"centerTitle\" \n                paragraph-key.bind=\"centerText\"\n            ></ds-template-paragraph>\n        </div>\n        <div>\n            <div class=\"image-container\">\n                <img src=\"" + ___HTML_LOADER_REPLACEMENT_2___ + "\" alt=\"Forge\" loading=\"lazy\">\n            </div>\n            \n            <ds-template-paragraph \n                no-line=true \n                smaller-title=true \n                title-key.bind=\"rightTitle\" \n                paragraph-key.bind=\"rightText\"\n            ></ds-template-paragraph>\n        </div>\n    </div>\n</template>\n";
// Exports
export default code;