import './ds-game-selector.scss';
import { bindable, autoinject } from 'aurelia-framework';
import { ProductCategoryService } from 'services/product-category-service';
import { Router } from 'aurelia-router';
import { getAWSBucketEndpoint } from 'environment';

@autoinject()
export class DsGameSelector {
    baseAwsEndpoint = getAWSBucketEndpoint('games');
    @bindable category;
    @bindable handleChangeFunctionString;
    @bindable selectedGame = '';
    @bindable manualGames;
    @bindable disabled;
    disableLine;
    parent;
    games;
    navCategory;
    gameSelector;
    iconPath;
    gameTextField;

    constructor(private productCategoryService: ProductCategoryService, private router: Router) { }

    async bind(bindingContext) {
        this.parent = bindingContext;
        if (this.disabled === 'true') {
            this.disableLine = 'mdc-select--disabled';
        }
        if (this.manualGames) {
            this.games = this.manualGames;
        } else {
            this.navCategory = await this.productCategoryService.getNavCategory(this.category, 'DS');
            this.games = this.navCategory?.gameForNav;
            this.games = this.games.filter(game => !game.parentGameId || this.games.find(parent => parseInt(parent.id) === game.parentGameId));
        }
    }

    async attached() {
        this.overrideMdc();
    }

    overrideMdc() {
        const selectArrow = this.gameSelector.querySelector('.mdc-select .mdc-select__dropdown-icon');
        const newArrowIcon = '<img class="arrow-icon" src="/faq/arrow-currency.svg" alt="arrow" loading="lazy">';
        selectArrow.innerHTML = newArrowIcon;
    }

    handleTextFieldFocusOut() {
        const gameSelectorElement = this.gameTextField;
        if (gameSelectorElement) {
            gameSelectorElement.blur();
            gameSelectorElement.classList.remove('mdc-text-field--focused');
        }
    }

    getLabel(game) {
        if (game) {
            this.iconPath = game.gameNavigationIcon ? `${this.baseAwsEndpoint}${game.gameNavigationIcon}` : '/icons/image-not-supported.svg';
            return '';
        } else {
            if (this.router.currentInstruction.config.name === 'buy-gold') {
                this.iconPath = '/icons/currency-default.svg';
            } else if (this.router.currentInstruction.config.name === 'buy-items') {
                this.iconPath = '/icons/item-default.svg';
            } else if (this.router.currentInstruction.config.name === 'buy-accounts') {
                this.iconPath = '/icons/account-default.svg';
            } else if (this.router.currentInstruction.config.name === 'buy-services') {
                this.iconPath = '/icons/services-default.svg';
            }
            return 'Select a Game';
        }
    }

    handleSelectionChanged(event) {
        if (this.handleChangeFunctionString) {
            this.parent[this.handleChangeFunctionString](event.detail.value);
        }
    }
}
