import './ds-tags.scss';
import { bindable } from 'aurelia-framework';

export class DsTags {

    @bindable blogsResults;
    @bindable tagsOptions;
    parent;

    bind(bindingContext) {
        this.parent = bindingContext;
    }

    showTagLabel(label) {
        return !['Crypto', 'Trading', 'Investments', 'Wall Street', 'NFT', 'CSR'].includes(label);
    }
}
