import './ds-quad-why-choose-cards.scss';
import { autoinject, bindable } from 'aurelia-framework';

@autoinject()
export class DsQuadWhyChooseCards {
    @bindable title;
    @bindable image;
    @bindable text;
    @bindable key;
    @bindable alt;
    @bindable information;
    @bindable class;
}
