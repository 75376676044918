import './ds-deep-dropdown.scss';
import { bindable } from 'aurelia-framework';
import { apiEndpoint } from 'environment';
import { SimplebarOverride } from 'resources/simplebar_override';

export class DsDeepDropdown {
    @bindable navigationItems;
    @bindable clickHandler;
    @bindable hideSubmenuOnMouseLeave = false;
    @bindable userClass = '';
    @bindable buttonClass = '';
    @bindable class;
    @bindable viewingAsAdmin;
    @bindable user;
    dropdownElement;
    environment = apiEndpoint();

    attached() {
        const scrollbars = document.querySelectorAll('[id*="scrollbar"]');
        scrollbars.forEach((scrollbar:HTMLElement) => {
            const instance = SimplebarOverride.instances.get(scrollbar);
            if (instance) {
                instance.unMount();
            }
            const newInstance = new SimplebarOverride(scrollbar);
            const offset = newInstance.getScrollElement();
            offset.addEventListener('scroll', (e) => {
                const target = e.target as Element;
                const category = scrollbar.id.split('-')[1];
                const mainCategory = scrollbar.querySelectorAll(`[id*="${category}-menu"]`);
                const nestedDropdowns = document.querySelectorAll(`[id*="${category}-submenu"`);
                mainCategory.forEach((mainCat:HTMLElement) => {
                    nestedDropdowns.forEach((dropdown:HTMLElement) => {
                        if (mainCat.id.slice(0, -5) === dropdown.id.slice(0, -8)) {
                            dropdown.style.top = `${mainCat.offsetTop - target.scrollTop}px`;
                        }
                    });
                });
            });
        });
    }

    navigate(slug) {
        this.clickHandler(slug);
    }

    openSubCategoryItems(menuItem) {
        this.handleCategoriesAndArrows();
        const menuId = menuItem.id.slice(0, -5);
        const submenu = document.getElementById(`${menuId}-submenu`);
        submenu.style.top = `${window.getComputedStyle(submenu).top !== '-12px' ? menuItem.offsetTop - Number(window.getComputedStyle(submenu).top) : menuItem.offsetTop}px`;
        submenu.style.display = 'block';
    }

    parentCategoryChange(menuItem) {
        const mainItem = menuItem.id.slice(0, -8);
        const category = document.getElementById(`${mainItem}-menu`).children[0] as HTMLElement;
        category.style.backgroundPosition = '0';
        const arrow = category.children[0].children[0].children[2] as HTMLElement;
        arrow.style.color = 'rgba(57, 226, 157, 1)';
    }

    handleCategoriesAndArrows() {
        const submenus = document.querySelectorAll('.submenu.dropdown-menu.text-regular') as NodeListOf<HTMLElement>;
        for (const menu of Array.from(submenus)) {
            menu.style.display = 'none';
        }
        const categories = document.querySelectorAll('.categorylabel') as NodeListOf<HTMLElement>;
        const arrows = document.querySelectorAll('.submenu-arrow') as NodeListOf<HTMLElement>;

        Array.from(categories).forEach((category, index) => {
            category.style.backgroundPosition = null;
            category.style.border = null;
            arrows[index].style.color = null;
        });
    }

    viewAsAdmin() {
        const element = document.getElementById('view-switch') as HTMLInputElement;
        element.checked = !element.checked;
        this.viewingAsAdmin = element.checked;
    }

    collapseAllInCategory(e:Element) {
        const selectors = e.querySelectorAll('.dropdown-submenu');
        const dropdownSelectors = e.querySelectorAll('.dropdown-item-submenu');
        selectors.forEach((el:HTMLElement) => {
            el.style.display = 'none';
        });
        dropdownSelectors.forEach((el:HTMLElement) => {
            el.style.backgroundPosition = null;
        });
    }
}
