import './faq.scss';
import { PageContentAreaService } from 'services/page-content-area-service';
import { autoinject, TaskQueue } from 'aurelia-framework';
import { FaqQuestion, FaqSection } from 'services/models/faq';
import { FaqSectionService } from 'services/faq-section-service';
import { ToastService } from 'services/toast-service';
import { bindable } from 'aurelia-framework';
import { ClearationTimeoutValueConverter } from 'resources/value-converters/clearation-timeout';
import { WebsiteService } from 'services/website-service';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { PageByWebsiteResponse } from 'services/models/page/pageByWebsiteResponse';
import { Router } from 'aurelia-router';

interface ExtendedFaqQuestion extends FaqQuestion {
    isVerificationQuestion?: boolean;
}

interface ExtendedFaqSection extends FaqSection {
    questions: ExtendedFaqQuestion[];
}

@autoinject()
export class Faq {
    @bindable isVerificationQuestion = false;
    private sections: ExtendedFaqSection[];
    private search = '';
    private activeSection: ExtendedFaqSection;
    faqDialog;
    changeSearchWatch: NodeJS.Timeout;
    toastSearchSent: boolean;
    timeouts: NodeJS.Timeout[];
    pages: PageByWebsiteResponse[];
    relevantQuestions = [];
    constRepeatFor = 3;
    sizeSubscriber: Subscription;
    faqContainer;
    openedQuestion;

    constructor(
        private pageContentAreaService: PageContentAreaService,
        private faqSectionService: FaqSectionService,
        private toastService: ToastService,
        private clearationTimeoutValueConverter: ClearationTimeoutValueConverter,
        private websiteService: WebsiteService,
        private taskQueue: TaskQueue,
        private eventAggregator: EventAggregator,
        private router: Router) {
        this.clearationTimeoutValueConverter = clearationTimeoutValueConverter;
    }

    async activate() {
        [this.sections, this.pages] = await Promise.all([
            this.faqSectionService.getByWebsite(),
            this.websiteService.getPagesByWebsiteShortcode()
        ]);

        await this.pageContentAreaService.getByPageId(this.pages.find(x => x.name === 'Faq')?.id);

        if (this.sections.length > 0) {
            this.sections.sort((a, b) => {
                return Number(b.position !== null) - Number(a.position !== null) || a.position - b.position;
            });
            this.activeSection = this.sections[0];
        }
    }

    attached() {
        const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        this.setRepeatFor(width);
        this.handleEventSubscriptions();
    }

    handleEventSubscriptions() {
        this.sizeSubscriber = this.eventAggregator.subscribe('size-changed', async payload => {
            this.setRepeatFor(payload.width);
        });
    }

    detached() {
        this.sizeSubscriber?.dispose();
    }

    setRepeatFor(width: number) {
        if (width > 576 && width <= 992) {
            this.constRepeatFor = 2;
        } else if (width <= 576) {
            this.constRepeatFor = 1;
        } else {
            this.constRepeatFor = 3;
        }
    }

    getNthQuestions(questions: ExtendedFaqQuestion[], n: number) {
        const response = [];
        if (questions) {
            for (let i = n; i < questions.length; i += this.constRepeatFor) {
                const normalizedQuestion = questions[i].question.toLowerCase();
                const normalizedTitle = this.activeSection.title.toLowerCase();
                questions[i].isVerificationQuestion = false;
                if (normalizedTitle.includes('verification') && normalizedQuestion.includes('verification')) {
                    questions[i].isVerificationQuestion = true;
                }
                response.push(questions[i]);
            }
        }
        return response;
    }

    async changeActiveSection(position: number, card) {
        if (!card) return;
        if (card.au.controller.viewModel.active) {
            this.activeSection = null;
            card.au.controller.viewModel.deactivate();
            return;
        }
        this.activeSection = this.sections.find(s => s.position === position);
        this.activeSection.questions.sort((a, b) => {
            return Number(b.position !== null) - Number(a.position !== null) || a.position - b.position;
        });
        document.querySelectorAll('ds-faq-category-card').forEach((el) => {
            if (el !== card) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                el.au.controller.viewModel.deactivate();
            }
        });
        card.au.controller.viewModel.activate();
        setTimeout(() => {
            const questionElement = document.querySelector(`#ds-faq-question-${this.activeSection.questions[0]?.id}`);
            this.expandQuestion(questionElement?.parentElement);
            const questions = document.getElementById('question-container');
            questions.scrollIntoView({ block: 'center', behavior: 'smooth' });
        }, 20);
    }

    expandQuestion(e) {
        document.querySelectorAll('ds-faq-question').forEach((el) => {
            if (el !== e) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                el.au.controller.viewModel.close();
            }
        });
        e?.au?.controller?.viewModel?.toggle();
    }

    async searchFaq() {
        if (!this.search) {
            this.clearSearch();
            return;
        }

        this.timeouts = [this.changeSearchWatch];
        this.clearationTimeoutValueConverter.toView(this.timeouts);

        this.relevantQuestions = [];
        const regExp = new RegExp(`(\\W|^)(${this.search.replaceAll(' ', '|')}|${this.search.replaceAll(' ', '\\s{0,3}')})(\\W|$)`, 'gi');

        for (const section of this.sections) {
            for (const question of section.questions) {
                const matchAllQuestion = Array.from(question.question?.matchAll(regExp));
                const matchAllAnswer = Array.from(question.answer?.matchAll(regExp));
                const matchExactQuestion = question.question?.toLowerCase().match(this.search.toLowerCase());
                const matchExactAnswer = question.answer?.toLowerCase().match(this.search.toLowerCase());

                if (matchAllQuestion.length > 0 || matchAllAnswer.length > 0 || matchExactQuestion || matchExactAnswer) {
                    this.relevantQuestions.push(
                        { ...question,
                            matchAllQuestion: matchAllQuestion,
                            matchAllAnswer: matchAllAnswer,
                            matchExactQuestion: matchExactQuestion,
                            matchExactAnswer: matchExactAnswer
                        });
                }
            }
        }

        if (this.relevantQuestions.length === 0) {
            if (this.toastSearchSent === false) {
                await this.toastService.showToast('Info', 'Couldn\'t find anything, ask livechat.', 'info');
                this.toastSearchSent = true;
            }
            return;
        }

        this.relevantQuestions
            .sort(({ matchAllAnswer : a }, { matchAllAnswer : b }) => Number(b.length !== null) - Number(a.length !== null) || a.length - b.length)
            .sort(({ matchAllQuestion : a }, { matchAllQuestion : b }) => Number(b.length !== null) - Number(a.length !== null) || a.length - b.length)
            .sort(({ matchExactAnswer : a }, { matchExactAnswer : b }) => Number(b !== null) - Number(a !== null) || a - b)
            .sort(({ matchExactQuestion : a }, { matchExactQuestion : b }) => Number(b !== null) - Number(a !== null) || a - b);

        this.taskQueue.queueMicroTask(() => {
            const containerEl = document.getElementById('search-container');
            if (containerEl) {
                const questionElement = containerEl.querySelector(`#ds-faq-question-${this.relevantQuestions[0]?.id}`);
                this.expandQuestion(questionElement?.parentElement);
                questionElement?.classList.add('highlight-me');
                setTimeout(() => { questionElement?.classList.remove('highlight-me'); }, 3000);
            }
        });
    }

    keyUpHandler(ev) {
        this.toastSearchSent = false;
        this.timeouts = [this.changeSearchWatch];
        this.clearationTimeoutValueConverter.toView(this.timeouts);
        if (ev.key === 'Enter') {
            this.searchFaq();
            return;
        } else {
            this.changeSearchWatch = setTimeout(() => {
                if (this.search) {
                    this.searchFaq();
                } else {
                    this.relevantQuestions = [];
                }
            }, 1000);
        }
        return true;
    }

    clearSearch() {
        this.search = '';
        this.relevantQuestions = [];
        const containerEl = document.getElementById('search-container');
        const questionElement = containerEl?.querySelector(`#ds-faq-question-${this.relevantQuestions[0]?.id}`);
        this.expandQuestion(questionElement?.parentElement);
        this.activeSection = this.sections[0];
        document.querySelectorAll('ds-faq-category-card').forEach((el) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            el.au.controller.viewModel.deactivate();
        });
    }

    showTable() {
        this.faqDialog.dialog.open();
    }

    async showIntercomMessage() {
        if (window.Intercom) {
            window.Intercom('showNewMessage', 'Hello, I need help');
        }
    }

    handleBack() {
        this.clearSearch();
    }

    retrieve() {
        const questions = this.faqContainer.querySelectorAll('.ds-faq-question');
        questions.forEach(x => {
            const question = x.querySelector('.answer-open');
            question ? this.openedQuestion = question : '';
        });
        const activeCategory = this.faqContainer.querySelector('.ds-category-card.active');
        if (this.openedQuestion) {
            this.openedQuestion.classList.replace('answer-open', 'answer-close');
            document.querySelectorAll('ds-faq-question').forEach((el) => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                el.au.controller.viewModel.close();
            });
            this.openedQuestion = null;
        }
        else if (activeCategory) {
            this.activeSection = null;
            activeCategory.classList.remove('active');
        }
        else this.router.navigateToRoute('home');
    }
}
