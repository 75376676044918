import './ds-avatar-dialog.scss';
import { EventAggregator } from 'aurelia-event-aggregator';
import { bindable, autoinject } from 'aurelia-framework';
import { apiEndpoint } from 'environment';
import { ImageService } from 'services/image-service';
import { ToastService } from 'services/toast-service';
import { CustomerService } from 'services/customer-service';

@autoinject()
export class DsAvatarDialog {
    parent;
    bind(bindingContext) {
        this.parent = bindingContext;
    }

    constructor(private imageService: ImageService, private eventAggregator: EventAggregator, private toastService: ToastService, private customerService: CustomerService) { }
    @bindable dialogStatus: string;
    @bindable filesList: object[];
    @bindable dialog;
    @bindable user;
    environment = apiEndpoint();
    selectedFiles: FileList;
    selectedAvatar;
    dropzone;
    avatarPreview;
    loading: boolean;

    attached() {
        this.dialogStatus = 'menu';
        this.filesList = [];
    }

    open() {
        this.dialog.open = !this.dialog.open;
    }

    close() {
        this.handleClosing();
        this.dialog.close();
    }

    handleClosing() {
        this.dialogStatus = 'menu';
        this.clearStatus();
        this.eventAggregator.publish('avatar-dialog-open', { avatarDialogOpen : false });
    }

    switchToSeeAvatars() {
        this.dialogStatus = 'seeAvatars';
    }

    switchToUpload() {
        this.dialogStatus = 'uploadImage';
        this.dropzone = document.getElementById('drop-zone');
    }

    selectAvatar(chick) {
        this.clearAvatars();
        this.selectedAvatar = chick.name;
        this.avatarPreview = chick;
        this.staticChicks[chick.id - 1].state = 'selected';
    }

    clearAvatars() {
        for (const chick of this.staticChicks) {
            chick.state = '';
        }
    }

    clearStatus() {
        this.selectedFiles = undefined;
        this.selectedAvatar = null;
        this.avatarPreview = null;
        this.filesList = [];
        const input = <HTMLInputElement> document.getElementById('image-input');
        input.value = '';
    }

    handleBack() {
        if (this.dialogStatus === 'menu') {
            this.dialog.close();
        } else if (this.selectedFiles || this.filesList.length !== 0) {
            this.clearStatus();
            this.handleClosing();
        } else if (this.avatarPreview) {
            this.avatarPreview = null;
            this.selectedAvatar = null;
            this.dialogStatus = 'seeAvatars';
        } else {
            this.handleClosing();
        }
    }

    triggerImgInput() {
        document.getElementById('profile-picture-upload').click();
    }

    dropHandler(e) {
        e.preventDefault();
        e.stopPropagation();
        if (e.dataTransfer.items) {
            for (const key in e.dataTransfer.items) {
                if (e.dataTransfer.items[key].kind === 'file') {
                    this.filesList.push(e.dataTransfer.items[key].getAsFile());
                }
            }
        } else {
            for (const key in e.dataTransfer.files) {
                this.filesList.push(e.dataTransfer.items[key].getAsFile());
            }
        }

        this.dropzone.classList.remove('drop-zone-hover');

        if (e.dataTransfer.items) {
            e.dataTransfer.items.clear();
        } else {
            e.dataTransfer.clearData();
        }
    }

    dragEnterHandler(e) {
        e.preventDefault();
        e.stopPropagation();
        if (e.path.filter(item => (item['id'] === 'drop-zone'))) {
            this.dropzone.classList.add('drop-zone-hover');
        }
    }

    dragLeaveHandler(e) {
        e.preventDefault();
        e.stopPropagation();
        if (e.path.filter(item => (item['id'] === 'drop-zone'))) {
            this.dropzone.classList.remove('drop-zone-hover');
        }
    }

    avatarUploadChange() {
        if (Array.from(this.selectedFiles).find(x => !x.type.includes('image'))) {
            this.toastService.showToast('Invalid file type', 'Please select an image file.', 'info');
            this.selectedFiles = null;
        }
    }

    async handleAvatarUpdate(file: FileList) {
        if (file) {
            this.loading = true;

            if (Array.from(file).find(x => !x.type?.includes('image'))) {
                await this.toastService.showToast('Invalid file type', 'Please select an image file.', 'info');
                return;
            }

            const result = this.imageService.buildFormData(file);
            if (result) {
                this.user.avatarImagePath = await this.imageService.postAvatar(result);
                this.eventAggregator.publish('user-updated', { user: this.user });
                await this.toastService.showToast('Updated profile', 'Your avatar has been updated successfully.', 'success');
                this.dialog.close();
                this.dialogStatus = 'menu';
                this.loading = false;
            }
        } else {
            await this.toastService.showToast('No file selected', 'Please select a file before trying to submit.', 'info');
        }
    }

    async manualAvatarUpdate() {
        try {
            this.user.avatarImagePath = this.selectedAvatar;
            const response = await this.customerService.updateInformation(this.user);
            if (response) {
                this.user = response;
                this.eventAggregator.publish('user-updated', { user: this.user });
                await this.toastService.showToast('Updated profile', 'Your avatar has been updated successfully.', 'success');
                this.dialog.close();
                this.dialogStatus = 'menu';
            } else {
                await this.toastService.showToast('Failed to update profile', 'Please check that all field are valid.', 'error');
            }
        } catch (e) {
            console.log(e);
        }
    }

    staticChicks = [
        {
            id: 1,
            src: this.environment + 'Image/chicks-avatars/chick_1',
            name: 'chick_1',
            state: ''
        },
        {
            id: 2,
            src: this.environment + 'Image/chicks-avatars/chick_2',
            name: 'chick_2',
            state: ''
        },
        {
            id: 3,
            src: this.environment + 'Image/chicks-avatars/chick_3',
            name: 'chick_3',
            state: ''
        },
        {
            id: 4,
            src: this.environment + 'Image/chicks-avatars/chick_4',
            name: 'chick_4',
            state: ''
        },
        {
            id: 5,
            src: this.environment + 'Image/chicks-avatars/chick_5',
            name: 'chick_5',
            state: ''
        },
        {
            id: 6,
            src: this.environment + 'Image/chicks-avatars/chick_6',
            name: 'chick_6',
            state: ''
        },
        {
            id: 7,
            src: this.environment + 'Image/chicks-avatars/chick_7',
            name: 'chick_7',
            state: ''
        },
        {
            id: 8,
            src: this.environment + 'Image/chicks-avatars/chick_8',
            name: 'chick_8',
            state: ''
        },
        {
            id: 9,
            src: this.environment + 'Image/chicks-avatars/chick_9',
            name: 'chick_9',
            state: ''
        },
        {
            id: 10,
            src: this.environment + 'Image/chicks-avatars/chick_10',
            name: 'chick_10',
            state: ''
        },
        {
            id: 11,
            src: this.environment + 'Image/chicks-avatars/chick_11',
            name: 'chick_11',
            state: ''
        },
        {
            id: 12,
            src: this.environment + 'Image/chicks-avatars/chick_12',
            name: 'chick_12',
            state: ''
        }
    ];
}
