import './bug-bounty.scss';
import { Router } from 'aurelia-router';
import { PageContentAreaService } from 'services/page-content-area-service';
import { autoinject } from 'aurelia-framework';
import { WebsiteService } from 'services/website-service';

@autoinject()
export class BugBounty {

    constructor(
        private router: Router,
        private pageContentAreaService: PageContentAreaService,
        private websiteService: WebsiteService) {
        this.router = router;
        this.websiteService = websiteService;
        this.pageContentAreaService = pageContentAreaService;
    }

    async activate() {
        const pages = await this.websiteService.getPagesByWebsiteShortcode();
        const pageId = pages.find(x => x.name === 'Bug Bounty')?.id;
        await this.pageContentAreaService.getByPageId(pageId);
    }
}

