import './home.scss';
import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { PageContentAreaService } from 'services/page-content-area-service';
import { ProductCategoryService } from 'services/product-category-service';
import { WebsiteService } from 'services/website-service';
import { CustomerService } from 'services/customer-service';
import { SessionService } from 'services/session-service';
import { QueryParamsValueConverter } from 'resources/value-converters/query-params';
import { ToastService } from 'services/toast-service';

@autoinject()
export class Home {
    constructor(
        private pageContentAreaService: PageContentAreaService,
        private productCategoryService: ProductCategoryService,
        private router: Router,
        private customerService: CustomerService,
        private sessionService: SessionService,
        private queryParamsValueConverter: QueryParamsValueConverter,
        private toastService: ToastService,
        private websiteService: WebsiteService
    ) { }

    productCategories;
    rs3;
    osrs;
    pages;
    urlParams;
    user;

    async activate() {
        [this.productCategories, this.pages] = await Promise.all([
            this.productCategoryService.getAllForNav('DS'),
            this.websiteService.getPagesByWebsiteShortcode()
        ]);
        await this.pageContentAreaService.getByPageId(this.pages.find(x => x.name === 'Home')?.id);
        this.osrs = this.productCategories[0].gameForNav.filter(game => game.shortName === 'OSRS')[0];
        this.rs3 = this.productCategories[0].gameForNav.filter(game => game.shortName === 'RS3')[0];
    }

    async attached() {
        this.urlParams = this.queryParamsValueConverter.toView(window.location.href);
        this.user = await this.sessionService.refreshProfile();
        this.handleEmailConfirmation();
    }

    async handleEmailConfirmation() {
        if (!this.user?.emailConfirmed && this.urlParams?.email && this.router.currentInstruction.queryParams.token) {
            try {
                const firstName = await this.sessionService.getUserFirstName(this.urlParams.email);
                const successful = await this.customerService.confirmEmail(this.urlParams.email, this.router.currentInstruction.queryParams.token);
                if (successful) {
                    this.toastService.showToast('Success!', `Thank you ${firstName}, your email has been verified successfully.`, 'success');
                } else {
                    this.toastService.showToast('Error', 'Email token invalid or expired. Please ensure you open the latest email verification link or try again.', 'error');
                }
            } catch (e) {
                console.log(e);
            }
        }
    }

    getLink(game) {
        return game?.slug[0] ? `${this.pages.find(x => x.name === 'Currency')?.routeName ?? 'currency'}/${game.slug[0]}` : null;
    }
}
