import './ds-account-type.scss';
import { bindable } from 'aurelia-framework';

export class DsAccountType {
    @bindable account;
    tooltipText;

    getAccountTypeClass() {
        switch (this.account.accountAcquiredType) {
            case 2:
                this.tooltipText = 'This account was created by Divica Sales';
                return 'in-house';
            default:
                this.tooltipText = 'This account was purchased from a reputable customer';
                return 'resale';
        }
    }
}
