import { autoinject } from 'aurelia-framework';
import { ApiService } from './api-service';

@autoinject()
export class GameModeService {
    path = 'GameMode';
    gameModes;

    constructor(private api: ApiService) {
        this.api = api;
    }

    async getAll() {
        if (this.gameModes) return this.gameModes;
        this.gameModes = await this.api.doGet(this.path);
        return this.gameModes;
    }

    async getGameModes() {
        await this.getAll();
    }

    async getGameModeByGameId(id, gameId) {
        const found = this.gameModes?.filter(x => x.gameId === parseInt(id));
        if (found) {
            return found;
        }
        return await this.api.doGet(this.path + '?gameId=' + gameId);
    }

    async getByGame(gameId) {
        return await this.api.doGet(this.path + '?gameId=' + gameId);
    }

    async getById(id) {
        return await this.api.doGet(this.path + '/' + id);
    }
}
