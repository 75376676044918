import './ds-icon.scss';
import { bindable } from 'aurelia-framework';
import { getAWSBucketEndpoint } from 'environment';

export class DsIcon {
    constructor() {
        this.baseAwsEndpoint = getAWSBucketEndpoint('games');
    }

    baseAwsEndpoint;

    @bindable icon;
    @bindable alt;
    @bindable type;
    @bindable color;
}
