import './ds-quantity-input.scss';
import { autoinject, bindable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { EventAggregator } from 'aurelia-event-aggregator';
import { InputNumericValueChecker } from 'resources/value-converters/input-numeric-value-checker';
import { ThousandSeparatorValueConverter } from 'resources/value-converters/thousand-separator';

@autoinject()
export class DsQuantityInput {
    @bindable product;
    @bindable value;
    @bindable itemQuantityChanged;
    @bindable tempQuantity;
    width;
    sizeChanged;
    quantity;
    quantityDesktop;
    arrowsDesktop;

    constructor(
        private router: Router,
        private eventAggregator: EventAggregator,
        private inputNumericValueChecker: InputNumericValueChecker,
        private thousandSeparatorValueConverter: ThousandSeparatorValueConverter) {
        this.router = router;
        this.eventAggregator = eventAggregator;
        this.inputNumericValueChecker = inputNumericValueChecker;
        this.thousandSeparatorValueConverter = thousandSeparatorValueConverter;
    }

    attached() {
        this.width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        if (this.width > 992) {
            this.blurNumberQuantity();
        }

        this.increaseInputSize();
        if (this.product?.product?.game?.shortName.includes('PVM') || this.product?.product?.game?.shortName.includes('MG')) {
            this.product.minimum = 0;
            this.product.maximum = 9999;
        } else if (this.product.skillName) {
            this.product.minimum = 1;
            this.product.maximum = 120;
        }

        this.handleEventSubscriptions();

        this.quantityDesktop.addEventListener('input', () => {
            this.value = this.value.replace(/[^0-9]/g, '');
            this.checkQuantityValue();
        });
    }

    detached() {
        this.sizeChanged?.dispose();
    }

    handleEventSubscriptions() {
        this.sizeChanged = this.eventAggregator.subscribe('size-changed', payload => {
            this.width = payload.width;
            if (this.width > 992) {
                this.blurNumberQuantity();
            } else {
                this.value = parseInt(this.value?.toString().replaceAll(',', ''));
            }
            this.increaseInputSize();
        });
    }

    plusButton() {
        if (this.quantity) {
            if (this.value < this.product.maximum) {
                this.value++;
            }
            if (this.itemQuantityChanged && this.value <= this.product.maximum) {
                this.itemQuantityChanged(this.value);
            }
        }
        this.increaseInputSize();
    }

    minusButton() {
        if (this.quantity) {
            if (this.value > this.product.minimum) {
                this.value--;
            }
            if (this.itemQuantityChanged && this.value >= this.product.minimum) {
                this.itemQuantityChanged(this.value);
            }
        }
        this.increaseInputSize();
    }

    checkIfEmptyInput() {
        if (this.width < 992) {
            if (this.quantity) {
                if (!this.quantity.value.length || !this.value?.toString().length) {
                    if (this.tempQuantity) {
                        this.tempQuantity = this.product.minimum;
                        this.value = this.tempQuantity;
                    } else {
                        this.value = this.product.minimum;
                    }
                } else if (this.value < this.product.minimum) {
                    if (this.tempQuantity) {
                        this.tempQuantity = this.product.minimum;
                        this.value = this.tempQuantity;
                    } else {
                        this.value = this.product.minimum;
                    }
                }
                if (this.itemQuantityChanged && this.router.currentInstruction.config.route === 'cart') {
                    this.itemQuantityChanged(this.value);
                }
            }
        } else {
            if (this.quantityDesktop) {
                if (!this.quantityDesktop.value.length || (!this.value?.toString().length && !this.tempQuantity?.toString().length)) {
                    if (this.tempQuantity) {
                        this.tempQuantity = this.product.minimum;
                        this.value = this.tempQuantity;
                    } else {
                        this.value = this.product.minimum;
                    }
                } else if (this.value < this.product.minimum) {
                    if (this.tempQuantity) {
                        this.tempQuantity = this.product.minimum;
                        this.value = this.tempQuantity;
                    } else {
                        this.value = this.product.minimum;
                    }
                }
                this.blurNumberQuantity();
            }
        }
    }

    valueChanged() {
        if (this.width > 992) {
            this.tempQuantity = this.value?.toString().replaceAll(',', '');
        }
        this.increaseInputSize();
    }

    checkQuantityValue() {
        if (this.value > this.product.maximum) {
            if (this.tempQuantity) {
                this.tempQuantity = this.product.maximum;
                this.value = this.product.maximum;
            } else {
                this.value = this.product.maximum;
            }
        }
    }

    blurNumberQuantity() {
        const element = this.arrowsDesktop.querySelector('#ds-number-arrows');
        element.style.visibility = null;
        element.style.opacity = null;
        this.tempQuantity = this.value;
        if (this.value) {
            this.value = this.thousandSeparatorValueConverter.toView(this.value);
        }
    }

    focusTextQuantity() {
        const element = this.arrowsDesktop.querySelector('#ds-number-arrows');
        element.style.visibility = 'visible';
        element.style.opacity = 1;
        this.tempQuantity ? this.value = this.tempQuantity : this.value;
    }

    increaseInputSize() {
        if (this.width < 992) {
            if (this.quantity) {
                if (this.quantity.value.length || this.value?.toString().length) {
                    if (this.value?.toString().length <= 3) {
                        this.quantity.style.width = '34px';
                    } else {
                        this.quantity.style.width = `${34 + ((this.value?.toString().length - 3) * 7)}px`;
                    }
                } else {
                    this.quantity.style.width = '34px';
                }
            }
        } else {
            if (this.quantityDesktop) {
                if (this.quantityDesktop.value.length || this.value?.toString().length) {
                    this.arrowsDesktop.querySelector('#ds-number-arrows').style.right = '7px';
                    if (this.value.toString().length <= 3) {
                        this.quantityDesktop.style.width = '60px';
                    } else {
                        this.quantityDesktop.style.width = `${60 + ((this.value?.toString().replaceAll(',', '').length - 3) * 10)}px`;
                    }
                } else {
                    this.quantityDesktop.style.width = '60px';
                }
            }
        }
    }
}
