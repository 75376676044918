import { apiPdfEndpoint, websiteShortCode } from 'environment';
import { ApiInterceptor } from 'services/api-interceptor';
import { HttpClient } from 'aurelia-fetch-client';
import { autoinject } from 'aurelia-framework';
import { ApiService } from './api-service';

@autoinject()
export class InvoiceService {
    path = 'Invoice';

    private api : ApiService;

    constructor(interceptor: ApiInterceptor) {
        const httpClient = new HttpClient();
        httpClient.configure(config => {
            config
                .withBaseUrl(apiPdfEndpoint())
                .withInterceptor(interceptor)
                .withDefaults({
                    headers: {
                        'Accept': 'application/json',
                        'websiteShortCode': websiteShortCode()
                    }
                });
        });

        this.api = new ApiService(httpClient);
    }

    async generatePdfReceipt(id): Promise<Blob> {
        return await this.api._fileDownload(`${this.path}/${id}/GeneratePdfReceipt`);
    }
}
