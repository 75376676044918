import { inject } from 'aurelia-framework';
import { Helper } from 'resources/extensions/helper';

@inject(Helper)
export class PreActivateStep {
    /**
     * @param {Helper} helper
     */
    constructor(private helper: Helper) {
        this.staticRoutesWithChildRoutes = {
            'currency': this.helper.range(2, true),
            'items-game': this.helper.range(2, true),
            'accounts-game': this.helper.range(2, true),
            'services': this.helper.range(2, true),
            'skins-game': this.helper.range(2, true),
            'swap': this.helper.range(2, true),
            'unique-names': this.helper.range(2, true),
            'sell': this.helper.range(3, true),
            'customer-portal': this.helper.range(5, true),
            'sign-in': this.helper.range(2, true),
            'blog': this.helper.range(3, true),
            'blog-post': this.helper.range(2, true),
            'blog-category': this.helper.range(3, true, 2),
            'author': this.helper.range(3, true, 2)
        };

        this.customerPortalChildRoutes = {
            '': this.helper.range(1, true),
            'profile': this.helper.range(2, true),
            'security': this.helper.range(2, true),
            'orders': this.helper.range(2, true),
            'order-details': this.helper.range(3, true),
            'verification': this.helper.range(2, true),
            'balance': this.helper.range(2, true),
            'support': this.helper.range(3, true)
        };
    }

    staticRoutesWithChildRoutes;
    customerPortalChildRoutes;
    routeName;
    childRoute;

    run(navigationInstruction, next) {
        this.childRoute = navigationInstruction.params.childRoute;
        this.routeName = navigationInstruction.config.name;

        if (navigationInstruction.params.childRoute) {
            const foundRoute = navigationInstruction.router.routes.find(x => x.name === this.routeName);
            const foundRouteCountSlashes = foundRoute?.route?.split('/');
            const currentUrlSlashes = navigationInstruction.fragment.slice(1).split('/');

            if (this.childRoute.includes('order-details')) {
                this.childRoute = this.childRoute.split('/')[0];
            }

            if (foundRoute?.name === 'customer-portal' && !this.customerPortalChildRoutes[this.childRoute]?.includes(currentUrlSlashes?.length)) {
                navigationInstruction.router.navigateToRoute('404');
            } else if ((currentUrlSlashes?.length !== foundRouteCountSlashes?.length && !this.staticRoutesWithChildRoutes[this.routeName])
                || (currentUrlSlashes?.length !== foundRouteCountSlashes?.length && this.staticRoutesWithChildRoutes[this.routeName] && !this.staticRoutesWithChildRoutes[this.routeName].includes(currentUrlSlashes?.length))) {
                navigationInstruction.router.navigateToRoute('404');
            }
        }
        return next();
    }
}
