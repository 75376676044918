import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { EventAggregator } from 'aurelia-event-aggregator';

@autoinject()
export class CurrencyGameContent {
    constructor(private router: Router, private eventAggregator: EventAggregator) {}

    key;
    whyChooseUsKeys;
    viewingAsAdmin;
    routeChangeSuccessSubscription;
    adminViewSubscription;
    pageContentAreaSubscription;
    loadingContentSubscription;
    loading;

    bind() {
        this.buildContent();
    }

    activate(params, routeConfig, navigationInstruction) {
        this.key = navigationInstruction.config.settings.keyName;
    }

    attached() {
        this.handleEventSubscriptions();
    }

    detached() {
        this.loadingContentSubscription?.dispose();
        this.routeChangeSuccessSubscription?.dispose();
        this.adminViewSubscription?.dispose();
        this.pageContentAreaSubscription?.dispose();
    }

    handleEventSubscriptions() {
        this.loadingContentSubscription = this.eventAggregator.subscribe('loading-content', payload => {
            this.loading = payload.loadingContent;
        });

        this.routeChangeSuccessSubscription = this.eventAggregator.subscribe('router:navigation:success', () => {
            this.key = this.router.currentInstruction.config.settings.keyName;
            this.buildContent();
        });

        this.adminViewSubscription = this.eventAggregator.subscribe('admin-view-updated', payload => {
            this.viewingAsAdmin = payload.bool;
        });
    }

    buildContent() {
        this.whyChooseUsKeys = [
            {
                boxColor: 'blue',
                title: `${this.key}_WCU_FIRST_TITLE`,
                text: `${this.key}_WCU_FIRST_PARAGRAPH`,
                iconPath: '/icons/quick.svg'
            },
            {
                boxColor: 'yellow',
                title: `${this.key}_WCU_SECOND_TITLE`,
                text: `${this.key}_WCU_SECOND_PARAGRAPH`,
                iconPath: '/icons/coins.svg'
            },
            {
                boxColor: 'green',
                title: `${this.key}_WCU_THIRD_TITLE`,
                text: `${this.key}_WCU_THIRD_PARAGRAPH`,
                iconPath: '/icons/heart.svg'
            },
            {
                boxColor: 'blue',
                title: `${this.key}_WCU_FOURTH_TITLE`,
                text: `${this.key}_WCU_FOURTH_PARAGRAPH`,
                iconPath: '/icons/vault.svg'
            }];
    }
}
