import './ds-number-arrows.scss';
import { autoinject, bindable } from 'aurelia-framework';
import { ThousandSeparatorValueConverter } from 'resources/value-converters/thousand-separator';

@autoinject()
export class DsNumberArrows {
    @bindable tempQuantity;
    @bindable quantity;
    @bindable sliderQuantity;
    @bindable product;
    @bindable suffix;
    @bindable type;
    @bindable parentEvent;
    @bindable disabled;
    @bindable minimum;
    @bindable maximum;
    changed;
    parent;

    constructor(private thousandSeparatorValueConverter: ThousandSeparatorValueConverter) {}

    bind(bindingContext) {
        this.parent = bindingContext;
    }

    increaseValue() {
        this.changed = false;
        if (this.tempQuantity && this.product) {
            if (this.tempQuantity < (this.maximum ?? this.product.maximum)) {
                this.changed = true;
                this.tempQuantity++;
            }
        } else if (this.tempQuantity && !this.product) {
            this.changed = true;
            this.tempQuantity++;
        } else {
            if (this.quantity < (this.maximum ?? this.product.maximum)) {
                this.changed = true;
                this.quantity++;
            }
        }

        if (this.changed) {
            if (this.tempQuantity) {
                this.quantity = this.thousandSeparatorValueConverter.toView(this.tempQuantity);
            } else {
                this.quantity = this.thousandSeparatorValueConverter.toView(this.quantity);
            }

            if (this.sliderQuantity) {
                this.sliderQuantity = this.tempQuantity;
            }
            if (this.parentEvent) {
                if (this.type && this.parent.itemQuantityChanged) {
                    this.parent[this.parentEvent](this.type, this.tempQuantity);
                } else if (this.parent.itemQuantityChanged) {
                    this.parent[this.parentEvent](this.quantity);
                }
            }
        }
    }

    decreaseValue() {
        this.changed = false;
        if (this.tempQuantity && this.product) {
            if (this.tempQuantity > (this.minimum ?? this.product.minimum)) {
                this.changed = true;
                this.tempQuantity--;
            }
        } else if (this.tempQuantity && !this.product) {
            this.changed = true;
            this.tempQuantity--;
        } else {
            if (this.quantity > (this.minimum ?? this.product.minimum)) {
                this.changed = true;
                this.quantity--;
            }
        }

        if (this.changed) {
            if (this.tempQuantity >= 0) {
                this.quantity = this.thousandSeparatorValueConverter.toView(this.tempQuantity);
            } else {
                this.quantity = this.thousandSeparatorValueConverter.toView(this.quantity);
            }

            if (this.sliderQuantity) {
                this.sliderQuantity = this.tempQuantity;
            }
            if (this.parentEvent) {
                if (this.type && this.parent.itemQuantityChanged) {
                    this.parent[this.parentEvent](this.type, this.tempQuantity);
                } else if (this.parent.itemQuantityChanged) {
                    this.parent[this.parentEvent](this.quantity);
                }
            }
        }
    }
}
