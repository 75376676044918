import './ds-faq-category-card.scss';
import { bindable } from 'aurelia-framework';
import { getAWSBucketEndpoint } from 'environment';

export class DsFaqCategoryCard {
    @bindable title: string;
    @bindable iconName: string;
    @bindable class: string;
    @bindable categoryId: number;
    @bindable position: number;
    @bindable active: boolean;

    private categoryCard: HTMLElement;
    private isStaticAsset = true;
    private baseAwsUrl: string;

    constructor() {
        this.baseAwsUrl = getAWSBucketEndpoint('icons');
    }

    attached() {
        if (this.position === 1) {
            this.activate();
        }

        this.isStaticAsset = !/^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(this.iconName);
    }

    activate() {
        this.active = true;
    }

    deactivate() {
        this.active = false;
    }

    disable() {
        this.categoryCard.classList.add('d-none');
    }

    enable() {
        this.categoryCard.classList.remove('d-none');
    }
}
