import './sell.scss';
import { autoinject, bindable, observable, computedFrom, PLATFORM } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { EventAggregator } from 'aurelia-event-aggregator';
import { ProductService } from 'services/product-service';
import { ProductCategoryService } from 'services/product-category-service';
import { PageContentAreaService } from 'services/page-content-area-service';
import { SessionService } from 'services/session-service';
import { PaymentMethodWebsiteService } from 'services/payment-method-website-service';
import { ThousandSeparatorValueConverter } from 'resources/value-converters/thousand-separator';
import { InputNumericValueChecker } from 'resources/value-converters/input-numeric-value-checker';
import { ToastService } from 'services/toast-service';
import { WebsiteService } from 'services/website-service';
import { Helper } from 'resources/extensions/helper';
import { getAWSBucketEndpoint } from 'environment';
import { GameService } from 'services/game-service';

@autoinject()
export class Sell {
    constructor(
        private router: Router,
        private eventAggregator: EventAggregator,
        private productService: ProductService,
        private productCategoryService: ProductCategoryService,
        private pageContentAreaService: PageContentAreaService,
        private sessionService: SessionService,
        private paymentMethodWebsiteService: PaymentMethodWebsiteService,
        private thousandSeparatorValueConverter: ThousandSeparatorValueConverter,
        private inputNumericValueChecker: InputNumericValueChecker,
        private toastService: ToastService,
        private websiteService: WebsiteService,
        private helper: Helper,
        private store,
        private baseAwsEndpoint: string,
        private gameService: GameService
    ) {
        baseAwsEndpoint = getAWSBucketEndpoint('products');
        this.productCategoryService = productCategoryService;
    }

    @bindable quantity;
    @observable currentGameProduct;
    @observable sliderQuantity = 1;
    @bindable selectedPaymentMethod;
    @bindable itemSearch;
    @bindable sliderMinimum = 1;
    @bindable sliderMaximum = 1000000;
    @bindable tempExpectedPrice = undefined;
    @bindable @observable preferredCurrency;
    @bindable @observable expectedPrice;
    @bindable additionalInformation;
    @bindable offsiteReputation;
    @bindable game;
    @bindable country;
    filters;
    gameSelected: boolean;
    selectedProducts = [];
    tempQuantity: number;
    appliedFilters = [];
    games;
    routeChangeSubscriber;
    category;
    filtersValues;
    accountsGameSelected;
    user;
    routeConfig;
    unfilteredCategories;
    paymentMethods;
    pageConfig;
    firstTimeNavigating;
    gameSearch: string;
    pageContentArea;
    titleKeyContent;
    routeSlug;
    sellPageRoute;
    categories;
    productCategories;
    noResultGameSearch;
    gameCategory;
    routeNavigating;
    navCategory;
    selectedGame;
    firstTimeTriggered;
    firstTimeTriggerFilters;
    pageLoading;
    pageSecondTitleContent;
    gameForNav;
    gameSecondTitleContent;
    gameTitles;
    adminViewSubscriber;
    viewingAsAdmin;
    loading;
    goldProducts;
    currencyNavCategory;
    allFiltersValid;
    itemsGameSelected;
    notificationSent;
    pages;
    itemsLoading;
    currentProducts;
    products;
    issue;

    async activate(_, routeConfig, navigationInstruction) {
        this.routeConfig = routeConfig;
        [
            this.unfilteredCategories,
            this.paymentMethods,
            this.pageConfig,
            this.preferredCurrency,
            this.pages,
            this.user,
        ] = await Promise.all([
            this.productCategoryService.getAll(),
            this.paymentMethodWebsiteService.getByWebsite(true),
            this.websiteService.getPage('Sell'),
            this.sessionService.getCurrency(),
            this.websiteService.getPagesByWebsiteShortcode(),
            this.sessionService.getProfile()
        ]);

        this.paymentMethods = this.paymentMethods.filter(x => x.payoutable);

        this.pageContentArea = await this.pageContentAreaService.getByPageId(this.pageConfig?.id);

        this.titleKeyContent = this.pageContentArea.find(
            (x) => x.key === `SELL${this.pageTitle}_PAGE_TITLE`
        );

        this.sellPageRoute = this.pageConfig?.routeName ?? 'sell';

        this.routeSlug = window.location.pathname.split('/').pop();

        this.paymentMethods = this.paymentMethods.map((p) => ({
            ...p,
            elementId: p.paymentMethod.name.replace(/[-, /]/g, '')
        }));

        this.categories = this.unfilteredCategories.filter((category) => category.id <= 3);
        const childRoute =
            navigationInstruction.fragment !== `/${this.sellPageRoute}`
                ? navigationInstruction.fragment.replace(
                    `/${this.sellPageRoute}/`,
                    ''
                )
                : 'currency';
        this.category = this.categories.find((category) => childRoute.includes(category.name.toLowerCase()));
        this.productCategories = this.unfilteredCategories.filter((el) => el.id !== 5);
        if (navigationInstruction.params.childRoute?.includes('/')) {
            this.firstTimeNavigating = true;
        }
    }

    async attached() {
        this.handleEventSubscriptions();
        this.helper.resizeByNavbar(this, '#sell');
    }

    detached() {
        this.helper.removePrerenderMetaTagForRedirect();
        this.helper.disposeAllSubscribers(this);
    }

    handleEventSubscriptions() {
        this.routeChangeSubscriber = this.eventAggregator.subscribe('router:navigation:success', async(event) => {
            this.firstTimeTriggered = true;
            this.firstTimeTriggerFilters = true;
            this.pageLoading = true;
            this.routeNavigating = true;
            const categoryFromRoute = event?.instruction.fragment.split('/');
            if (categoryFromRoute.length === 2) {
                this.routeConfig.navModel.setTitle(this.pageConfig.title);
                categoryFromRoute.push('currency');
            }
            categoryFromRoute.shift();
            this.category = this.categories.find((category) =>
                category.name
                    .toLowerCase()
                    .includes(categoryFromRoute[1])
            );
            this.gameCategory = this.productCategories.find(
                (productCategory) =>
                    productCategory?.name === this.category?.name
            );
            this.navCategory =
                    await this.productCategoryService.getNavCategory(
                        'sellextra',
                        'DS'
                    );
            this.games = this.navCategory?.gameForNav.filter(
                (g) =>
                    !g?.name.includes('Currency') &&
                    !g?.name.includes('Items') &&
                    !g?.name.includes('Accounts') &&
                    g.productCategoryName.includes(
                        this.gameCategory?.name.toUpperCase()
                    )
            );
            this.titleKeyContent = this.pageContentArea.find(x => x.key === `SELL${this.pageTitle}_PAGE_TITLE`);
            if (categoryFromRoute[2]) {
                this.selectedGame = this.games.find(g => g.slug.includes(categoryFromRoute.join('/').replace(`${this.sellPageRoute}/`, '')));
                if (!this.selectedGame) {
                    return this.router.navigateToRoute('404');
                }

                this.routeConfig.navModel.setTitle('');
                if (this.category.name !== 'Accounts') {
                    await this.setGame(this.selectedGame);
                    if (this.game && this.game?.slug[0] !== event?.instruction?.params.childRoute) {
                        const redirectUrl = `/${this.sellPageRoute}/${this.game.slug[0]}`;
                        this.helper.addPrerenderMetaTagForRedirect(redirectUrl);
                        return this.router.navigate(redirectUrl);
                    }
                } else {
                    this.gameSelected = false;
                    this.getAccKingsUrl(this.selectedGame);
                }
                if (this.category.name === 'Items') {
                    this.gameSelected = false;
                    this.getChicksGoldUrl(this.selectedGame);
                }
                this.pageSecondTitleContent = await this.pageContentAreaService.getByKey(`SELL${this.pageTitle}_PAGE_SECOND_TITLE`);
                this.gameSecondTitleContent = this.pageSecondTitleContent && this.pageSecondTitleContent?.markup ? `SELL${this.pageTitle}_PAGE_SECOND_TITLE` : `SELL${this.pageTitle}_PAGE_TITLE`;
                this.gameTitles = [`SELL${this.pageTitle}_PAGE_SECOND_TITLE`, `SELL${this.pageTitle}_PAGE_TITLE`];
            } else {
                this.selectedGame = null;
                this.setCategory(this.category);
                this.clearSearch();
            }
            this.pageLoading = false;
            setTimeout(() => this.firstTimeTriggered = false, 1000);
            this.removeActiveTab();
            this.setActiveTab();
        });

        this.adminViewSubscriber = this.eventAggregator.subscribe('admin-view-updated', payload => {
            this.viewingAsAdmin = payload.bool;
        });
    }

    @computedFrom('game', 'category', 'gameSelected', 'accountsGameSelected', 'itemsGameSelected')
    get pageTitle() {
        let title = '';
        if (this.category) {
            title += '_' + this.category.name.toUpperCase().replace(' ', '_');
        }
        if (this.game && (this.gameSelected || this.accountsGameSelected || this.itemsGameSelected)) {
            title += '_' + this.game.shortName;
        }
        this.titleKeyContent = this.pageContentArea?.find((x) => x.key === `SELL${title}_PAGE_TITLE`);
        return title;
    }

    @computedFrom('category')
    get categoryType() {
        if (this.category) {
            switch (this.category.name) {
                case 'Currency':
                    switch (this.game?.shortName) {
                        case 'POE':
                            return 'Currency';
                        case 'FFXIV':
                            return 'Gil';
                        case 'NW':
                            return 'Coins';
                        case 'EFT':
                            return 'Roubles';
                        default:
                            return 'Gold';
                    }
                case 'Items':
                    return 'Items';
                case 'Accounts':
                    return 'Accounts';
            }
        }
    }

    @computedFrom('game')
    get title() {
        if (this.game) {
            switch (this.game?.shortName) {
                case 'OSRS':
                case 'POE':
                case 'FFXIV':
                    return this.game?.shortName;
                case 'RS3':
                    return 'Runescape';
                case 'D2R':
                    return 'Diablo II Resurrected';
                case 'WOW':
                    return 'WOW';
                default:
                    return this.game?.name;
            }
        }
    }

    quantityChanged() {
        if (this.quantity > this.currentGameProduct?.maximum) {
            this.quantity = this.currentGameProduct.maximum;
        }
    }

    sliderQuantityChanged() {
        this.quantity = this.sliderQuantity;
        if (this.quantity) {
            this.tempQuantity = this.quantity;
            this.quantity = this.thousandSeparatorValueConverter.toView(this.quantity);
        }
    }

    blurNumberQuantity() {
        const element = document.getElementById('ds-number-arrows');
        element.style.visibility = null;
        element.style.opacity = null;
        this.sliderQuantity = this.helper.convertNumberWithoutComma(this.quantity);
        this.tempQuantity = this.quantity;
        if (this.quantity) {
            this.quantity = this.thousandSeparatorValueConverter.toView(this.quantity);
        }
    }

    focusTextQuantity() {
        const element = document.getElementById('ds-number-arrows');
        element.style.visibility = 'visible';
        element.style.opacity = '1';
        this.tempQuantity ? this.quantity = this.tempQuantity : this.quantity;
    }

    currentGameProductChanged() {
        this.setSliderLimits();
    }

    setSliderLimits() {
        /// set the new limits in different ways depending on current and new limits.
        if (this.currentGameProduct) {
            if (this.currentGameProduct.minimum > this.sliderMaximum) {
                this.sliderMaximum = this.currentGameProduct.maximum;
                this.quantity = this.currentGameProduct.defaultAmount;
                setTimeout(() => {
                    this.sliderQuantity = this.currentGameProduct.defaultAmount;
                    this.sliderMinimum = this.currentGameProduct.minimum;
                }, 100);
            } else if (this.currentGameProduct.maximum < this.sliderMinimum) {
                this.sliderMinimum = this.currentGameProduct.minimum;
                this.quantity = this.currentGameProduct.defaultAmount;
                setTimeout(() => {
                    this.sliderQuantity = this.currentGameProduct.defaultAmount;
                    this.sliderMaximum = this.currentGameProduct.maximum;
                }, 100);
            } else {
                this.quantity = this.currentGameProduct.defaultAmount;

                setTimeout(() => {
                    this.sliderMinimum = this.currentGameProduct?.minimum ?? 0;
                    this.sliderQuantity = this.currentGameProduct?.defaultAmount ?? 50;
                    this.sliderMaximum = this.currentGameProduct?.maximum ?? 1000;
                }, 100);
            }
        }
    }

    async setGame(game) {
        this.appliedFilters = [];
        this.loading = true;
        this.notificationSent = false;
        if (this.category?.name === 'Accounts' && 'Items') {
            return;
        }
        this.gameSelected = true;
        this.clearSearch();
        this.game = game;
        if (this.category.id === 1 && this.game) {
            this.filters = [];
            this.currentGameProduct = null;
            this.goldProducts = await this.productService.getProductsWithFilter('inStockCurrency', this.game.id);
            this.currencyNavCategory = await this.productCategoryService.getNavCategory('currency', 'DS');
            this.filters = await this.productService.getProductFilters(this.currencyNavCategory.id, this.game.id);
            this.allFiltersValid = this.filters.every(x => x.field.listOfValue);
            // create a copy of the filter childrens
            this.filters.forEach(e => {
                e.field.listOfValue.copiedChildren = e.field.listOfValue.children;
                setTimeout(() => {
                    // set default value to root filters
                    if (!e.field.parentId) {
                        e.value = e.field.listOfValue.children[0];
                    }
                }, 100);
            });

            await this.updateFilters();
            await this.handleCurrencyFilter();
        }
        this.loading = false;
    }

    gameChanged() {
        this.helper.removePrerenderMetaTagForRedirect();
    }

    handleFilterChange = async(event, filter, value) => {
        if (!value) return;
        this.notificationSent = false;
        filter.value = value;
        await this.updateFilterChildren(filter, value?.value);
        await this.updateFilters();
        await this.handleCurrencyFilter();
    };

    async updateFilterChildren(parentFilter, value) {
        const selectedLOV = parentFilter.field.listOfValue.children.find(e => e.value === value);
        const child = this.filters.find(e => e.field.parentId === parentFilter.field.id);
        if (this.firstTimeTriggered) await new Promise(resolve => { setTimeout(resolve, 100); });
        if (child) {
            child.field.listOfValue.children = child.field.listOfValue.copiedChildren.filter(e => e.filteredByListOfValueId === selectedLOV?.id);
            await new Promise(_resolve => {
                setTimeout(() => {
                    child.value = child.field.listOfValue.children[0];
                    this.updateFilterChildren(child, child.value?.value);
                }, 100);
            });
        }
        await new Promise(resolve => { setTimeout(resolve, 100); });
    }

    async updateFilters() {
        this.filters.forEach(f => {
            const foundIndex = this.appliedFilters.findIndex(x => x.parentId === f.field.id);
            if (foundIndex >= 0) {
                this.appliedFilters.splice(foundIndex, 1);
            }
            this.appliedFilters.push({ parentId: f.field?.id, value: f.value });
        });
    }

    async handleCurrencyFilter() {
        if (this.appliedFilters && this.goldProducts) {
            let filteredProducts = [...this.goldProducts];
            for (const filter of this.appliedFilters) {
                if (filter.value) {
                    filteredProducts = filteredProducts.filter(x => x.productFields.some(y => y.value === filter.value.value && y.fieldId === filter.parentId));
                }
            }
            this.currentGameProduct = filteredProducts.length >= 1 ? filteredProducts[0] : undefined;
        } else {
            this.currentGameProduct = this.goldProducts.length >= 1 ? this.goldProducts[0] : undefined;
        }
        if (!this.currentGameProduct && !this.notificationSent && !this.loading) {
            this.notificationSent = true;
            await this.toastService.showToast('We are not currently buying this product. Please let us know in the live chat that you are interested in this product.', 'Enter another currency', 'Info');
        }
    }

    async clearSearch() {
        this.gameSearch = '';
    }

    async handleGameSearch() {
        for (const game of this.games) {
            game.show = [game.name, game.shortName].some(x => x.toLowerCase()?.includes(this.gameSearch.toLowerCase()));
        }
        this.noResultGameSearch = !this.games.some(x => x.show);
    }

    getIcon(category) {
        const iconPaths = {
            'Currency': '/icons/diamond.svg',
            'Items': '/icons/sword-no-border.svg',
            'Accounts': '/icons/icon_account.svg'
        };
        return iconPaths[category.name];
    }

    async setCategory(category) {
        if (this.gameSelected) {
            this.handleGameDeselection(null);
        }

        if (this.accountsGameSelected) {
            this.handleAccountGameDeselection();
        }

        if (this.itemsGameSelected) {
            this.handleItemsGameDeselection();
        }

        this.category = category;
        this.gameCategory = this.productCategories.find((productCategory) => productCategory?.name === this.category?.name);
        this.navCategory = await this.productCategoryService.getNavCategory('sellextra', 'DS');
        this.games = this.navCategory?.gameForNav.filter(g => !g.name.includes('Currency') && !g.name.includes('Items') && !g.name.includes('Accounts') && g.slug[0].includes(this.gameCategory?.name.toLowerCase()));
        this.router.navigate(this.gameCategory?.name.toLowerCase());
        this.removeActiveTab();
        this.setActiveTab();
    }

    setActiveTab() {
        const timer = setInterval(() => {
            const tabs = document.querySelectorAll('mdc-tab');
            if (tabs.length > 0) {
                tabs.forEach((el) => {
                    if ((el as HTMLElement).innerText === this.category?.name) {
                        (el as HTMLElement).classList.add('highlight');
                    }
                });
                clearInterval(timer);
            }
        }, 100);
    }

    removeActiveTab() {
        const tabs = document.querySelectorAll('mdc-tab');

        tabs.forEach((el) => {
            el.classList.remove('highlight');
        });
    }

    handleAccountGameDeselection() {
        this.accountsGameSelected = false;
        this.games.forEach(gameAccount => {
            gameAccount.selected = false;
        });
    }

    handleItemsGameDeselection() {
        this.itemsGameSelected = false;
        this.games.forEach(gameItem => {
            gameItem.selected = false;
        });
    }

    async openLiveChat() {
        if (this.category.name === 'Items') {
            let baseString = `Hello, I would like to sell ${this.game.name} Items. I have `;
            const stringArr = [];

            for (const [index, product] of this.selectedProducts.entries()) {
                if (!product.selectedQuantity) {
                    await this.toastService.showToast(`Please select the quantity for ${product.name}.`, 'Error', 'Error');
                    return;
                }
                const stringToSend = `${product.selectedQuantity} ${product.name}${index === this.selectedProducts.length - 2 ? ' ' : ', '}`;
                stringArr.push(stringToSend);
            }

            if (stringArr.length > 1) {
                stringArr[stringArr.length - 1] = `and ${stringArr[stringArr.length - 1]}`;
            }

            stringArr[stringArr.length - 1] = stringArr[stringArr.length - 1].replace(',', '');

            for (const string of stringArr) {
                baseString += string;
            }

            if (window.Intercom) {
                window.Intercom('showNewMessage', `${baseString} and would like to be paid in ${this.selectedPaymentMethod.paymentMethod.name}.`);
            }
        } else {
            if (!this.selectedPaymentMethod) {
                await this.toastService.showToast('Please select a payment method.', 'Error', 'Error');
                return;
            }

            if (this.quantity < 1) {
                await this.toastService.showToast('Please select the quantity.', 'Error', 'Error');
                return;
            }

            if (window.Intercom) {
                window.Intercom('showNewMessage', `Hello, I would like to sell ${this.quantity}${this.game.currencyProductSymbol?.length >= 2 ? ' ' + this.game.currencyProductSymbol + 's' : this.game.currencyProductSymbol ? this.game.currencyProductSymbol : 'Unit'} Currency for ${this.filters.length > 0 && this.allFiltersValid ? this.game.name + ' | Server: ' + this.getFiltersValues().slice(0, -1) : this.game.name} and would like to be paid in ${this.selectedPaymentMethod.paymentMethod.name}.`);
            }
        }
    }

    async showIntercomMessage() {
        if (window.Intercom) {
            if (!this.issue) {
                window.Intercom('showNewMessage', 'Hello, I need help');
            } else {
                if (this.title !== 'Choose a Title') {
                    window.Intercom(
                        'showNewMessage',
                        `${this.title}: ${this.issue}`
                    );
                } else {
                    window.Intercom('showNewMessage', this.issue);
                }
            }
        }
    }

    getFiltersValues() {
        this.filtersValues = '';
        if (this.filters?.length > 0) {
            for (const filter of this.filters) {
                const display = filter.field.listOfValue.children.find(e => e === filter.value)?.display;
                if (display) {
                    this.filtersValues += display + ' ';
                }
            }
            return this.filtersValues;
        }
    }

    handleGameDeselection(routeChange) {
        if (!this.gameSelected) {
            this.router.navigate('/');
            return;
        }

        if (this.currentProducts) {
            this.currentProducts.forEach((product) => {
                if (product.selected) {
                    product.selected = false;
                }
            });
        }
        this.gameSelected = false;
        this.selectedProducts = [];
        if (routeChange) {
            this.router.navigate(
                `/${this.sellPageRoute}/${this.category.name.toLowerCase()}`
            );
        }
        this.helper.removePrerenderMetaTagForRedirect();
    }

    getAccKingsUrl(game) {
        this.games.forEach(gameAccount => {
            gameAccount.selected = false;
        });
        this.accountsGameSelected = true;
        this.game = game;
        game.selected = true;
        let url;
        if (this.game.sellAccountRedirectLink) {
            url = this.game.sellAccountRedirectLink.match(/https:\/\/acckings.com\/.*/g) ? this.game.sellAccountRedirectLink : 'https://acckings.com/';
        } else {
            url = 'https://acckings.com/';
        }
        const checkOutWindow = window.open(url);
        this.firstTimeNavigating = false;
        if (!checkOutWindow || checkOutWindow.closed || typeof checkOutWindow.closed === 'undefined') {
            this.eventAggregator.publish('banner-updated', { successful: 'warning', text: 'Looks like you have AdBlock enabled. To open the checkout window, please', url: url });
        }
    }

    routeAccKings(game) {
        if (!this.routeNavigating || this.firstTimeNavigating) {
            this.getAccKingsUrl(game);
        }
        this.firstTimeNavigating = false;
        this.routeNavigating = false;
    }

    getChicksGoldUrl(game) {
        this.games.forEach(gameItem => {
            gameItem.selected = false;
        });
        this.itemsGameSelected = true;
        this.game = game;
        game.selected = true;
        let url;
        if (this.game.sellItemRedirectLink) {
            url = this.game.sellItemRedirectLink.match(/https:\/\/chicksgold.com\/.*/g) ? this.game.sellItemRedirectLink : 'https://chicksgold.com/sell/items';
        } else {
            url = 'https://chicksgold.com/sell/items';
        }
        const checkOutWindow = window.open(url);
        this.firstTimeNavigating = false;
        if (!checkOutWindow || checkOutWindow.closed || typeof checkOutWindow.closed === 'undefined') {
            this.eventAggregator.publish('banner-updated', { successful: 'warning', text: 'Looks like you have AdBlock enabled. To open the checkout window, please', url: url });
        }
    }

    routeChicksGold(game) {
        if (!this.routeNavigating || this.firstTimeNavigating) {
            this.getChicksGoldUrl(game);
        }
        this.firstTimeNavigating = false;
        this.routeNavigating = false;
    }

    routeTo(game) {
        this.router.navigate(`${game.slug[0]}`);
    }

    async configureRouter(config, router) {
        config.options.pushState = true;
        this.router = router;
        this.navCategory = await this.productCategoryService.getNavCategory(
            'sellextra',
            'DS'
        );
        const gamesForRoute = this.navCategory?.gameForNav;
        config.map([
            {
                route: ['', 'currency'],
                name: 'currency',
                moduleId: PLATFORM.moduleName(
                    'pages/sell/sell-default/sell-default'
                ),
                settings: {
                    metaDescription:
                        'Sell Game Currency, Items and Accounts from the #1 Game Currency, Items and Accounts Buyer on the market. Over 100,000+ Game Currency, Items and Accounts Sales successfully completed!'
                }
            },
            {
                route: 'accounts',
                name: 'sell-accounts',
                moduleId: PLATFORM.moduleName(
                    'pages/sell/sell-default/sell-default'
                ),
                title: 'Sell Game Accounts',
                settings: {
                    metaDescription:
                        'Sell Game Accounts from the top Game Account Buyer on the market. Over 100,000+ Game Accounts Sales successfully completed!'
                }
            },
            {
                route: 'items',
                name: 'sell-items',
                moduleId: PLATFORM.moduleName(
                    'pages/sell/sell-default/sell-default'
                ),
                title: 'Sell Game Items',
                settings: {
                    metaDescription:
                        'Sell Game Items from the #1 Game Item Buyer on the market. Over 100,000+ Game Items Sales successfully completed!'
                }
            },
            ...gamesForRoute.map((game) => {
                return {
                    route: game.slug,
                    name: game.name,
                    moduleId: PLATFORM.moduleName(
                        'pages/sell/sell-game-content/sell-game-content'
                    ),
                    title: game.title,
                    settings: {
                        metaDescription: game.metaDescription,
                        keyName: `SELL_${game.shortName}_${game.slug[0]
                            .split('/')[0]
                            .toUpperCase()}`
                    }
                };
            })
        ]);
        config.mapUnknownRoutes(() => {
            this.router.navigateToRoute('404');
        });
    }

}
