// Imports
import ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ from "../../../../node_modules/.pnpm/html-loader@3.1.2_webpack@5.94.0_webpack-cli@4.10.0_/node_modules/html-loader/dist/runtime/getUrl.js";
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/icons/checkmark_unfilled.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_1___ = new URL("/static/icons/red_fail.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_2___ = new URL("/static/icons/file-icons/close.svg", import.meta.url);
// Module
var ___HTML_LOADER_REPLACEMENT_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var ___HTML_LOADER_REPLACEMENT_1___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_1___);
var ___HTML_LOADER_REPLACEMENT_2___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_2___);
var code = "<template>\n    <div id=\"ds-coupon-input\">\n        <form class=\"coupon-form\" submit.delegate=\"checkCouponValidation()\">\n            <mdc-text-field\n                outlined\n                autocomplete=\"off\"\n                value.two-way=\"coupon\"\n                id=\"coupon-input\"\n                type=\"text\"\n                name=\"Coupon\"\n                class=\"input-password text-dark-blue w-100 ${showMiniSpinnerCoupon ? 'pe-none' : ''}\"\n                class.bind=\"getElementStateClass(state)\"\n                click.delegate=\"scrollScreenToSummary()\"\n                placeholder.bind=\"text\"\n                focusin.delegate=\"couponUpdatedOnFocusIn()\"\n                focusout.delegate=\"checkCouponValidation()\"\n            ></mdc-text-field>\n            <mdc-circular-progress if.bind=\"showMiniSpinnerCoupon\" class=\"mini-spinner-icon\" size=\"25\" stroke-width=\"2\"></mdc-circular-progress>\n            <img src=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\" class=\"circle-icon\" alt=\"success\" if.bind=\"state === 'success'\" loading=\"lazy\">\n            <img src=\"" + ___HTML_LOADER_REPLACEMENT_1___ + "\" class=\"circle-icon\" alt=\"error\" if.bind=\"state === 'error'\" loading=\"lazy\">\n            <img src=\"" + ___HTML_LOADER_REPLACEMENT_2___ + "\" class=\"x-icon cursor-pointer\" mousedown.delegate=\"clearCoupon()\" alt=\"close icon\" if.bind=\"coupon && state === 'close' && !showMiniSpinnerCoupon\" loading=\"lazy\">\n        </form>\n    </div>\n</template>\n";
// Exports
export default code;