import { autoinject } from 'aurelia-framework';
import { ApiService } from './api-service';
import { websiteShortCode } from 'environment';

@autoinject()
export class TestimonialService {
    path = 'Testimonial';
    testimonials;

    constructor(private api: ApiService) { }

    async getFeaturedTestimonials() {
        if (this.testimonials) return this.testimonials;
        const data = {
            filter: 'featured',
            websiteShortCode: websiteShortCode()
        };
        this.testimonials = await this.api.doGet(this.path + '/ByWebsite', data);
        return this.testimonials;
    }
}
