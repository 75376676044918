import { Website } from '../website';
import { PaymentMethod } from './paymentMethod';

export class PaymentMethodWebsite {
    createdDate?: Date;
    updatedDate?: Date;
    websiteId: number;
    website: Website;
    paymentMethodId: number;
    paymentMethod: PaymentMethod;
    fee: number;
    receivingFee?: number;
    defaultValue?: number;
    proccessorPercentFee?: number;
    proccessoBaseFee?: number;
    smallOrderFee?: number;
    smallOrderMinimumAmount?: number;
    live: boolean;
    calculateTaxes?: boolean;
    minimum?: number;
    maximum?: number;
    spreadFee?: number;
    paymentStepNotes: string;
    orderCompletedStepNotes: string;
    position?: number;
    payoutable: boolean;
    additionalBalance: boolean;
    additionalInternalFee?: number;
    payWithBalance?: boolean;
    is3dsRequired: boolean;
    supportedCurrencies: PaymentMethodSupportedCurrencies[];
    restrictedCountries: PaymentMethodRestrictedCountries[];
    show?: boolean;
    game;
}

export class PaymentMethodSupportedCurrencies {
    currencyId: number;
    paymentMethodId: number;
    websiteId: number;
}

export class PaymentMethodRestrictedCountries {
    countryId: number;
    paymentMethodId: number;
    websiteId: number;
}

export class ThresholdCountry {
    countryId: number;
    thresholdId: number;
}

export class PaymentMethodThreshold {
    id: number;
    websiteId: number;
    paymentMethodId: number;
    countries: ThresholdCountry[];
    operationTypeList: ThresholdOperationType[];
    verificationTypeList: VerificationType[];
    frequency: number;
    threshold: number;
    currencyId: number;
}

export enum VerificationType {
    Phone,
    ID,
    Email,
    Address
}

export enum ThresholdOperationType {
    Buy = 'B',
    Sell = 'S'
}
