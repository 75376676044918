import { autoinject } from 'aurelia-framework';
import { SessionService } from 'services/session-service';
import { PriceModificationService } from 'services/price-modification-service';

@autoinject()
export class PriceModificationChecker {
    constructor(private sessionService: SessionService, private priceModificationService: PriceModificationService) {}

    async toView(gameId, productCategoryId) {
        if (this.sessionService.getPlatformLinkCookie('gclid') || this.sessionService.getPlatformLinkCookie('msclkid')) {
            const priceModifications = await this.priceModificationService.getPriceModifications();
            return priceModifications?.find(x => x.gameId === parseInt(gameId) && x.productCategoryId === productCategoryId);
        }
    }
}
