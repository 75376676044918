import { autoinject } from 'aurelia-framework';
import { ApiService } from './api-service';
import { Helper } from 'resources/extensions/helper';

@autoinject()
export class BlacklistService {
    path = 'Blacklist';
    userBlacklist;

    constructor(private api: ApiService, private helper: Helper) { }

    async getBlacklistByUserEmail(userEmail: string) {
        if (this.userBlacklist?.length > 0) {
            return this.userBlacklist;
        }
        this.userBlacklist = await this.helper.fetchData(this.api, `${this.path}/${userEmail}/GetByUserEmail`, 'BlacklistService');
        return this.userBlacklist;
    }
}
