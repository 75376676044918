import { autoinject } from 'aurelia-framework';
import { ApiService } from './api-service';

@autoinject()
export class GameService {
    path = 'Game';
    games;

    constructor(private api: ApiService) {}

    async getAll() {
        if (!this.games) {
            this.games = await this.api.doGet(this.path);
        }
        return this.games;
    }

    async getFiltered(filter) {
        return await this.api.doGet(`${this.path}/filterBy/${filter}`);
    }

    async getById(id) {
        if (!this.games) {
            this.games = await this.getAll();
        }
        return this.games.filter(x => x.id === id);
    }

    async getByShortName(shortName) {
        if (!this.games) {
            this.games = await this.getAll();
        }
        return this.games.filter(x => x.shortName === shortName);
    }
}
