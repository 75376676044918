import { autoinject } from 'aurelia-dependency-injection';
import { ApiService } from './api-service';

@autoinject()
export class HealthService {
    path = 'Health';

    constructor(private api: ApiService) {}

    async checkApiHealth() {
        return await this.api.doGet(this.path);
    }
}
