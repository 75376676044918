import { autoinject } from 'aurelia-framework';
import { ApiService } from './api-service';
import { Helper } from 'resources/extensions/helper';

@autoinject()
export class ChargebackService {
    path = 'Chargeback';
    userChargeback;

    constructor(private api: ApiService, private helper: Helper) { }

    async userHasChargeback(userId: number): Promise<boolean> {
        if (this.helper.isBoolean(this.userChargeback)) return this.userChargeback;
        if (!userId) return;
        this.userChargeback = await this.api.doGet(`${this.path}/UserHasChargeback/${userId}`);
        return this.userChargeback;
    }
}
