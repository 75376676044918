import './blogpost.scss';
import { autoinject, bindable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { BlogService } from 'services/blog-service';
import { getAWSBucketEndpoint, apiEndpoint, baseUrl } from 'environment';
import { WebsiteService } from 'services/website-service';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Helper } from 'resources/extensions/helper';

@autoinject()
export class Blogpost {
    constructor(private router: Router, private blogService: BlogService, private websiteService: WebsiteService, private eventAggregator: EventAggregator, private helper: Helper) {
        this.baseAwsEndpoint = getAWSBucketEndpoint('blogs');
    }

    baseAwsEndpoint: string;
    blogs;
    blog;
    blogTag;
    environment = apiEndpoint();
    relatedArticles;
    arrowTriggered;
    arrowRotated: boolean;
    lastEventTriggered: string;
    @bindable blogRoute;

    breadcrumbs = [];
    homePageRoute;
    readingTimeResult = 0;
    sizeChanged;
    width;

    async activate(params, routeConfig) {
        if (params.blog !== 'author') {
            const pages = await this.websiteService.getPagesByWebsiteShortcode();
            this.blogRoute = pages.find(x => x.name === 'Blog')?.routeName ?? 'blog';
            this.relatedArticles = await this.blogService.getPublishedBlogPosts(3, 1);
            this.blog = await this.blogService.getBlogBySlug(params.blog);
            if (!this.blog || routeConfig.route.includes('*childRoute')) {
                this.router.navigateToRoute('404');
            }
            const regex = /[<][p][>][<][b][r][>][<][/][p][>]/g;
            this.blog.content = this.blog.content?.replace(regex, '');
            routeConfig.navModel.setTitle(this.blog?.title);
            if (this.blog?.metaDescription) routeConfig.navModel.settings.metaDescription = this.blog.metaDescription;
        }
        this.readingTimeResult = this.readingTime();
    }

    async attached() {
        const pages = await this.websiteService.getPagesByWebsiteShortcode();
        this.width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        const categoryTag = this.blog.blogTag?.name ?? 'All';
        const categoryHref = categoryTag === 'All' ? this.blogRoute : `${this.blogRoute}/category/${this.blog.blogTag.name.toLowerCase().replaceAll(' ', '-')}`;
        this.homePageRoute = pages.find(x => x.name === 'Home')?.routeName ?? '';
        if (this.blog) {
            await this.blogService.updateBlogPostViewCounter(this.blog.id);
        }
        this.handleReviewSchema();
        this.breadcrumbs = [
            { title: 'Home', href: this.homePageRoute },
            { title: 'Blogs', href: this.blogRoute },
            { title: categoryTag, href: categoryHref },
            { title: this.blog.title, tooltip: this.blog.title.length > 80 }
        ];
        this.handleEventSubscriptions();
    }

    detached() {
        this.sizeChanged?.dispose();
    }

    handleEventSubscriptions() {
        this.sizeChanged = this.eventAggregator.subscribe('size-changed', async payload => {
            this.width = payload.width;
        });
    }

    changeSelectHandler() {
        this.handleFocusOut();
    }

    handleFocusOut() {
        if (!this.arrowTriggered) return;
        this.arrowTriggered.style.transform = 'rotate(0deg)';
        this.arrowRotated = false;
        this.lastEventTriggered = 'focusOut';
        setTimeout(() => {
            this.lastEventTriggered = '';
        }, 200);
    }

    handleClick(e) {
        this.arrowTriggered = e.target.querySelector('.arrow-icon');
        this.arrowTriggered.style.transform = this.arrowRotated ? 'rotate(0deg)' : 'rotate(180deg)';
        this.arrowRotated = !this.arrowRotated;
    }

    handleReviewSchema() {
        let reviewSchema = `
        {
            "@type": "BlogPosting",
            "@id": "${baseUrl()}#/schema/BlogPosting/${this.blog.id}",
            "headline": "${this.blog.title}",`;

        if (this.blog.previewImagePath) {
            reviewSchema += `"image": "${this.baseAwsEndpoint}${this.blog.previewImagePath}",`;
        }

        reviewSchema += `
            "datePublished": "${this.blog.createdDate}",
            "dateModified": "${this.blog.updatedDate}"`;

        if (this.blog.blogAuthor) {
            reviewSchema += `,
                "author": {
                    "@id": "${baseUrl()}#/schema/Person/${this.blog.blogAuthorId}"
                }`;
        }

        reviewSchema += '}';

        if (this.blog.blogAuthor) {
            reviewSchema += `,{
                "@type": "Person",
                "@id": "${baseUrl()}#/schema/Person/${this.blog.blogAuthorId}",
                "name": "${this.blog.blogAuthor.firstName + ' ' + this.blog.blogAuthor.lastName}",
                "url": "${baseUrl()}${this.blogRoute}/author/${this.blog.blogAuthor.alias}"
            }`;
        }

        this.helper.combineApplicationLdJsonSchemasIntoOne(reviewSchema);
    }

    async tagClickHandler(tag) {
        this.router.navigateToRoute('blog-category', { category: tag.slug });
    }

    readingTime() {
        const wordCount = this.blog.content?.split(' ').length;
        const readingTimeMinutes = Math.ceil(wordCount / 250);
        return readingTimeMinutes;
    }
}
