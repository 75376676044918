import './ds-support-issue-input.scss';
import { autoinject, bindable } from 'aurelia-framework';
import { ToastService } from 'services/toast-service';
import { Helper } from 'resources/extensions/helper';

@autoinject()
export class DsSupportIssueInput {
    @bindable issue;
    @bindable filesList = [];
    @bindable isSavingDraft = false;
    tempFiles = [];
    @bindable fileExtensions = ['txt', 'doc', 'dods', 'xls', 'xlsx', 'csv', 'ppt', 'pptx', 'pdf', 'odp', 'ods', 'odt', 'xml'];
    @bindable fileTypes = ['image', 'video'];
    maxNumberOfFiles = 10;
    dropZoneOutside: HTMLElement;
    fileInput: HTMLElement;
    showDropdown: boolean;
    isAllFilesLoaded = true;
    inputAccept = '';
    parent;

    constructor(private toastService: ToastService, private helper: Helper) { }

    bind(bindingContext) {
        this.parent = bindingContext;
    }

    async attached() {
        for (const type of this.fileTypes) {
            this.inputAccept += `${type}/*, `;
        }

        for (const extension of this.fileExtensions) {
            this.inputAccept += `.${extension}, `;
        }

        this.inputAccept = this.inputAccept.substring(0, this.inputAccept.length - 2);
        this.fileInput = document.getElementById('file-input');
        this.dropZoneOutside = document.getElementById('ds-support-issue-input');
    }

    dragEnterHandler(ev) {
        ev.preventDefault();
        ev.stopPropagation();

        if (ev.path.filter(item => (item['id'] === 'ds-support-issue-input'))) {
            this.dropZoneOutside.classList.add('hover-drop-zone');
        }
    }

    dragLeaveHandler(ev) {
        ev.preventDefault();
        ev.stopPropagation();

        if (ev.target['id'] === 'ds-support-issue-input' || ev.target['id'] === 'drop-zone') {
            this.dropZoneOutside.classList.remove('hover-drop-zone');
        }
    }

    async dropHandler(ev) {
        ev.preventDefault();
        ev.stopPropagation();

        if (ev.dataTransfer.items) {
            for (const key in ev.dataTransfer.items) {
                const item = ev.dataTransfer.items[key];

                if (item.kind === 'file') {
                    this.isAllFilesLoaded = false;
                    if (await this.checkFileType(item.getAsFile()) && await this.checkFileSize(item.getAsFile())) {
                        if (this.tempFiles.length >= this.maxNumberOfFiles) {
                            this.isAllFilesLoaded = true;
                            this.dropZoneOutside.classList.remove('hover-drop-zone');
                            this.toastService.showToast('Only ' + this.maxNumberOfFiles + ' files can be attached', 'Please try again', 'error');
                        } else {
                            this.tempFiles.push({ name: item.getAsFile().name, isLoaded: false });
                            this.createAttachedObject(item.getAsFile())
                                .then(result => {
                                    this.filesList.push(result);
                                    this.tempFiles[this.findLastIndex(this.tempFiles, result['filename'])]['isLoaded'] = true;

                                    if (this.tempFiles.filter(file => file['isLoaded'] === false).length === 0) {
                                        this.isAllFilesLoaded = true;
                                        this.dropZoneOutside.classList.remove('hover-drop-zone');
                                    }
                                });
                        }
                    } else {
                        this.isAllFilesLoaded = true;
                        this.dropZoneOutside.classList.remove('hover-drop-zone');
                    }
                }
            }
        } else {
            for (const key in ev.dataTransfer.files) {
                const item = ev.dataTransfer.items[key].getAsFile();

                if (await this.checkFileType(item.getAsFile()) && await this.checkFileSize(item.getAsFile())) {
                    if (this.tempFiles.length >= this.maxNumberOfFiles) {
                        this.dropZoneOutside.classList.remove('hover-drop-zone');
                        this.toastService.showToast('Only ' + this.maxNumberOfFiles + ' files can be attached', 'Please try again', 'error');
                    } else {
                        this.tempFiles.push({ name: item.getAsFile().name, isLoaded: false });
                        this.createAttachedObject(item.getAsFile())
                            .then(result => {
                                this.filesList.push(result);
                                this.tempFiles[this.findLastIndex(this.tempFiles, result['filename'])]['isLoaded'] = true;

                                if (this.tempFiles.filter(file => file['isLoaded'] === false).length === 0) {
                                    this.isAllFilesLoaded = true;
                                    this.dropZoneOutside.classList.remove('hover-drop-zone');
                                }
                            });
                    }
                }
            }
        }

        if (ev.dataTransfer.items) {
            ev.dataTransfer.items.clear();
        } else {
            ev.dataTransfer.clearData();
        }
    }

    async changeHandler(ev) {
        for (const file of ev.target['files']) {
            if (await this.checkFileType(file) && await this.checkFileSize(file)) {
                if (this.tempFiles.length >= this.maxNumberOfFiles) {
                    await this.toastService.showToast('Only ' + this.maxNumberOfFiles + ' files can be attached', 'Please try again', 'error');
                } else {
                    this.tempFiles.push({ name: file.name, isLoaded: false });
                    this.createAttachedObject(file)
                        .then(result => {
                            this.filesList.push(result);
                            this.tempFiles[this.findLastIndex(this.tempFiles, result['filename'])]['isLoaded'] = true;
                        });
                }
            }
        }
        this.fileInput['value'] = null;
    }

    keyUpHandler() {
        if (Object.keys(this.parent).includes('isSavingDraft')) {
            this.isSavingDraft = true;
            setTimeout(() => {
                this.isSavingDraft = false;
            }, 2000);
        }
    }

    async checkFileType(file) {
        if (this.fileTypes.filter(item => file['type'].startsWith(item + '/')).length > 0 ||
            this.fileExtensions.filter(item => file['name'].endsWith('.' + item)).length > 0) {
            return true;
        }

        await this.toastService.showToast('File type is not allowed', file['name'], 'error');
        return false;
    }

    async checkFileSize(file) {
        const maxSizePerFile = 20971520; //Equivalent to 20 MB
        const maxSizeAllFiles = 20971520; //Equivalent to 20 Mb

        if (file['size'] > maxSizePerFile) {
            await this.toastService.showToast('File size exceeds 20 MB', file['name'], 'error');
            return false;
        }

        if (this.filesList.map(item => item['size']).reduce((prev, curr) => prev + curr, 0) + file['size'] > maxSizeAllFiles) {
            await this.toastService.showToast('The total size of all files must not exceed 20 MB', 'Please try again', 'error');
            return false;
        }

        return true;
    }

    async createAttachedObject(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = () => {
                // The next line is to retrieve only the Base64 encoded string, first remove data:*/*;base64, from the result.
                const result = reader.result.toString().substring(reader.result.toString().indexOf('base64,') + 'base64,'.length);

                resolve({ content: result, filename: file.name, type: file.type, disposition: 'attachment', size: file.size, extension: file.name.substring(file.name.lastIndexOf('.') + 1) });
            };

            reader.onerror = reject;
            reader.readAsDataURL(file);
        });
    }


    findLastIndex(array, query) {
        const arrayTemp = [];
        array.forEach(element => arrayTemp.push(element.name));
        return arrayTemp.lastIndexOf(query);
    }

    deleteItem(index: number) {
        this.tempFiles.splice(index, 1);
        this.filesList.splice(index, 1);
    }

    showItem(index: number) {
        return index < 4 ? true : false;
    }

    toggleDropdown() {
        this.showDropdown = !this.showDropdown;
        return this.showDropdown;
    }

    handleDropdownFocusOut() {
        if (this.showDropdown) {
            this.showDropdown = false;
            return this.showDropdown;
        }
    }

    cleanFileList() {
        this.tempFiles = this.filesList = [];
    }
}
