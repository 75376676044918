import './ds-toast.scss';
import { bindable } from 'aurelia-framework';

export class DsToast {
    @bindable title: string;
    @bindable message: string;
    @bindable toastType: string;
    @bindable toastIcon: string;
    @bindable imgIcon: string;

    private container: HTMLElement;

    attached() {
        this.loadToastIcon();
        setTimeout(() => {
            this.closeToast();
        }, 2500);
    }

    closeToast() {
        this.container.classList.remove('fadeIn');
        this.container.classList.add('fadeOut');
        setTimeout(() => {
            this.container.remove();
        }, 500);
    }

    loadToastIcon() {
        if (this.imgIcon) {
            return `/icons/${this.imgIcon}.svg`;
        } else {
            switch (this.toastType) {
                case 'success':
                    this.toastIcon = 'check_circle_outline';
                    break;

                case 'warning':
                    this.toastIcon = 'error_outline';
                    break;

                case 'info':
                    this.toastIcon = 'info';
                    break;

                case 'error':
                    this.toastIcon = 'error_outline';
                    break;
            }
        }
    }
}
