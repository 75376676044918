import './ds-blog-preview.scss';
import { autoinject, bindable } from 'aurelia-framework';
import { getAWSBucketEndpoint } from 'environment';
import { WebsiteService } from 'services/website-service';

@autoinject()
export class DsBlogPreview {
    constructor(private websiteService: WebsiteService) {
        this.baseAwsEndpoint = getAWSBucketEndpoint('blogs');
    }

    @bindable blog;
    baseAwsEndpoint: string;
    blogsPageRoute;

    async attached() {
        const pages = await this.websiteService.getPagesByWebsiteShortcode();
        this.blogsPageRoute = pages.find(x => x.name === 'Blog')?.routeName ?? 'blogs';
    }

    blogContent(text) {
        let content = text;
        if (content) {
            content = content.replace(/<[^>]+>/g, ' ');
            return content;
        }
        return 'no content available';
    }
}
