import simplebar from 'simplebar';

const SimplebarOverride = simplebar;

export default class SimpleBarOverride extends simplebar {
    contentWrapperEl?: HTMLElement;
    axis?: any;
    elStyles?: any;
    mouseY?: any;
    mouseX?: any;
    options?: any;
    contentEl?: HTMLElement;
    static instances = new WeakMap();

    constructor(...args: ConstructorParameters<typeof simplebar>) {
        super(...args);
        SimpleBarOverride.instances.set(args[0], this);
    }

    onTrackClick(e, axis = 'y') {
        const currentAxis = this.axis[axis];
        if (!this.options.clickOnTrack || !currentAxis.scrollbar.el || !this.contentWrapperEl) {
            return;
        }

        e.preventDefault();

        const elWindow = this.getElementWindow(this.el);
        this.axis[axis].scrollbar.rect = currentAxis.scrollbar.el.getBoundingClientRect();
        const scrollbar = this.axis[axis].scrollbar;
        const scrollbarOffset = scrollbar.rect?.[this.axis[axis].offsetAttr] ?? 0;
        const hostSize = parseInt(this.elStyles?.[this.axis[axis].sizeAttr] ?? '0px', 10);
        let scrolled = this.contentWrapperEl[this.axis[axis].scrollOffsetAttr];
        const t = axis === 'y' ? this.mouseY - scrollbarOffset : this.mouseX - scrollbarOffset;
        const dir = t < 0 ? -1 : 1;
        let scrollSize = dir === -1 ? scrolled - hostSize : scrolled + hostSize;
        const speed = 40;
        const scrollTrack = this.axis[axis].track;
        if (scrollTrack.el && scrollbar.el && this.contentEl) {
            const scrollRect = scrollTrack.el.getBoundingClientRect();
            const clientAxis = 'client' + axis.toUpperCase();
            const scrollSizeAttribute = this.axis[axis].scrollSizeAttr;
            const scrollPoint = e[clientAxis] - scrollRect[this.axis[axis].offsetAttr] - (scrollbar.el[this.axis[axis].offsetSizeAttr] / 2);
            scrollSize = (scrollPoint / scrollTrack.el[this.axis[axis].offsetSizeAttr]) * this.contentEl[scrollSizeAttribute];
        }

        const scrollTo = () => {
            if (!this.contentWrapperEl) return;
            if (dir === -1) {
                if (scrolled > scrollSize) {
                    scrolled -= speed;
                    this.contentWrapperEl[this.axis[axis].scrollOffsetAttr] = scrolled;
                    elWindow.requestAnimationFrame(scrollTo);
                }
            } else {
                if (scrolled < scrollSize) {
                    scrolled += speed;
                    this.contentWrapperEl[this.axis[axis].scrollOffsetAttr] = scrolled;
                    elWindow.requestAnimationFrame(scrollTo);
                }
            }
        };

        scrollTo();
    }

    getElementWindow = (element) => {
        if (!element || !element.ownerDocument || !element.ownerDocument.defaultView) {
            return window;
        }
        return element.ownerDocument.defaultView;
    };
}

export { SimplebarOverride };
